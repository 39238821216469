import React from "react";
import Skinpage from "../../components/Skinpage";
import { useLocation } from "react-router-dom";
import InvoiceClients from "./InvoiceServices";
import InvoiceGeneral from "./InvoiceGeneral";

const InvoiceInsert = () => {
  const { state: services = [] } = useLocation();
  return (
    <Skinpage pageTitle={"GENERAR FACTURA - CLIENTES FISCALES"}>
      {services ? <InvoiceClients services={services} /> : <InvoiceGeneral />}

    </Skinpage>
  );
};

InvoiceInsert.propTypes = {};

export default InvoiceInsert;
