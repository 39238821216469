import React from "react";
import DgInput from "../../components/DgInput";
import { parseCat } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { enumPaymentMethods } from "../../helpers/enums";
import { invoiceEventSchema } from "../../helpers/formUtils";

function Concepts({ onChange, style, className, index, key }) {
  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);
  return (
    <div
      className={className}
      key={key}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        minWidth: 500,
        ...style,
      }}
    >
      {invoiceEventSchema({ catConcepts: catConcepts }).map((inputProps) => (
        <DgInput
          containerStyle={{ width: "30%", marginTop: 0 }}
          onChange={(e) => {
            onChange(e, index);
          }}
          {...inputProps}
        />
      ))}
    </div>
  );
}

export default Concepts;
