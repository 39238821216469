import React, { useEffect } from "react";
import DgModal from "../../../components/DgModal";
import { useFormWithValidations } from "../../../helpers/useFormWithValidations";
import DgInput from "../../../components/DgInput";
import { Request } from "../../../helpers/api";
import { downloadBlobFile, parseObjectDate } from "../../../helpers/utils";
import { useState } from "react";
import DgLoader from "../../../components/DgLoader";

function RelatedPaymentsModal({ open, toggle }) {
  const [loading, setLoading] = useState(false);
  const [
    handleChange,
    [register, handleSubmit, setValue, { errors }, watch, reset, getValues],
  ] = useFormWithValidations({
    initialDate: "",
    endDate: "",
  });

  const generateReport = async (dateRange) => {
    setLoading(true);
    const res = await Request(
      `/report/paymentRelation?${dateRange.initialDate}&${dateRange.endDate}`,
      null,
      "GET",
      true,
      false,
      true
    );
    setLoading(false);
    if (res) {
      downloadBlobFile(
        res,
        `Relación de pagos ${
          dateRange.initialDate ? `${dateRange.initialDate} - ` : " - "
        }${dateRange.endDate || "actualidad"}.xlsx`.trim()
      );
    }
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    resetForm();
  }, [open]);

  return (
    <DgModal
      title={"Reporte de relación de pagos"}
      withSend={{
        title: "Generar reporte",
        action: handleSubmit((submitData) => generateReport(submitData)),
      }}
      open={open}
      toggle={toggle}
    >
      <div className="w-full px-4">
        <DgInput
          {...register("initialDate")}
          label="Fecha inicial"
          onChange={handleChange}
          iconName="Calendar"
          type="date"
          error={errors["initialDate"]}
          errorMessage={errors["initialDate"]?.message}
        />
        <DgInput
          {...register("endDate")}
          error={errors["endDate"]}
          errorMessage={errors["endDate"]?.message}
          label="Fecha final"
          onChange={handleChange}
          iconName="Calendar"
          type="date"
        />
      </div>
      <DgLoader open={loading} />
    </DgModal>
  );
}

export default RelatedPaymentsModal;
