import React from "react";
import PropTypes from "prop-types";
import { noop } from "../helpers/utils";

const PaymentLabel = ({ text, id, parserColor = noop }) => {
  return (
    <span
      className="py-2 px-3 rounded-lg font-semibold text-xs"
      style={{ color: "#fff", backgroundColor: parserColor(id) }}
    >
      {text ? text.toUpperCase() : "-"}
    </span>
  );
};

PaymentLabel.propTypes = {
  text: PropTypes.string,
  id: PropTypes.number,
};

export default PaymentLabel;
