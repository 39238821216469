import React, { useCallback, useEffect, useState } from "react";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import "../../App.css";
import "../../App.jsx";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import DgTable from "../../components/DgTable";
import { getColumnsPaySheet } from "../../helpers/datatableHelpers";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../helpers/useForm";
import { Request } from "../../helpers/api";
import ModalIncidenceLog from "./modals/ModalIncidenceLog";
import ModalPaysheetStatus from "./modals/ModalPaysheetStatus";
import { useSelector } from "react-redux";
import { parseCat } from "../../helpers/utils";

const Paysheet = ({ doublesPermission }) => {
  const navigate = useNavigate();
  const [filters, setFilters] = useForm({
    clientType: "",
    amount: "",
  });
  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [modalIncidenceLog, setModalIncidenceLog] = useState(false);
  const [modalPaysheetStatus, setModalPaysheetStatus] = useState(false);
  const {
    catalogues: { CatZone },
  } = useSelector((state) => state);

  const fetchData = useCallback(async () => {
    const res = await Request("/payroll");
    if (res.ok) {
      if (!data.length && !filters.zone) {
        setData(res.data);
      }

      setAllData(res.data);
    }
  }, []);

  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields = field === "all" ? ["employeeName"] : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const funcs = {
    modalPaysheetStatus: (row) => {
      setSingleData(row);
      setModalPaysheetStatus(true);
    },
    openPersonDetail: (row) => {
      navigate("/detalle-empleado/" + row.idEmployee);
    },
    modalIncidenceLog: (row) => {
      setSingleData(row);
      setModalIncidenceLog(true);
    },
  };

  useEffect(() => {
    if (!data.length) {
      fetchData();
    }
  }, []);
  useEffect(() => {
    const filteredData = allData.filter(
      (service) => !filters.zone || filters.zone === service.zone
    );
    setData(filteredData);
  }, [filters]);
  return (
    <Skinpage pageTitle={"CÁLCULO DE NÓMINA"}>
      <div className="grid grid-cols-4 gap-3" style={{ alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar persona"
          iconName="Search"
        />
        <DgInput
          type="select"
          label="Filtrar por zona"
          options={[
            {
              label: "Todas",
              value: "",
            },
            ...parseCat(CatZone, "name", "idCatZone"),
          ]}
          onChange={setFilters}
          name="zone"
          value={filters.zone}
          iconName="CreditCard"
        />
        <div />
        {doublesPermission ? (
          <Button
            style={{ margin: "0.5rem" }}
            onClick={() => {
              navigate("/vista-dobletes");
            }}
          >
            Vista de dobletes
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-8">
        <DgTable
          data={data}
          columnsDef={getColumnsPaySheet(funcs)}
          className="tableHeaderPersonal"
        />
      </div>

      <ModalIncidenceLog
        isOpen={modalIncidenceLog}
        data={singleData}
        toggle={() => {
          setModalIncidenceLog(!modalIncidenceLog);
        }}
        fetchData={fetchData}
      />

      <ModalPaysheetStatus
        isOpen={modalPaysheetStatus}
        data={singleData}
        toggle={() => {
          setModalPaysheetStatus(!modalPaysheetStatus);
        }}
        fetchData={fetchData}
        withBank={true}
      />
    </Skinpage>
  );
};

export default Paysheet;
