import { useEffect, useState } from "react";

function useGetScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleScreenWidth = () => {
    const handleWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleWidth);
    return () => window.removeEventListener("resize", handleWidth);
  };
  useEffect(() => {
    handleScreenWidth();
  }, []);
  return [screenWidth, handleScreenWidth];
}

export default useGetScreenWidth;
