import React, { useCallback, useEffect, useState } from "react";
import { getColumnsServicesHistoric } from "../../helpers/datatableHelpers";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../helpers/useForm";
import { Request } from "../../helpers/api";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import DgTable from "../../components/DgTable";
import { getOperatingYearsCat, parseObjectDate } from "../../helpers/utils";
import { TODAY } from "../../helpers/consts";

const ServicesHistoric = () => {
  const { id: idEmployee = "" } = useParams();
  const [filters, setFilters] = useForm({
    isActive: "",
    year: TODAY.getFullYear(),
  });
  const navigate = useNavigate();
  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const funcs = {
    documents: (row) => () => {
      setSingleData(row);
    },
    openPersonDetail: (row) => {
      navigate("/detalle-empleado/" + row.idEmployee);
    },
  };

  const fetchData = useCallback(async (filters) => {
    const { isActive, year } = filters;
    const res = await Request(
      `/employee/serviceHistory?idEmployee=${idEmployee}&isActive=${isActive}&year=${year}`
    );
    if (res.ok) {
      const mappedData = res.data.map((service) => {
        const parsedDateService = {
          ...service,
          startDate: parseObjectDate(new Date(service.startDate)),
        };
        return parsedDateService;
      });
      setData(mappedData);
      setAllData(mappedData);
    }
  }, []);

  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields =
      field === "all"
        ? ["serviceName", "nameEmployee", "workingDayType", "shift"]
        : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  return (
    <Skinpage pageTitle={"HISTORIAL DE SERVICIOS"}>
      <div className="grid grid-cols-4 gap-3" style={{ alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar persona o servicio"
          iconName="Search"
        />
        <DgInput
          type="select"
          label="Filtrar por estatus"
          options={[
            {
              label: "Todos",
              value: "",
            },
            {
              label: "Activo",
              value: true,
            },
            {
              label: "Inactivo",
              value: false,
            },
          ]}
          onChange={setFilters}
          name="isActive"
          value={filters.isActive}
          iconName="Contact"
        />
        <DgInput
          type="select"
          label="Filtrar por año"
          options={[
            {
              label: "Todos",
              value: "",
            },
            ...getOperatingYearsCat(),
          ]}
          onChange={setFilters}
          name="year"
          value={filters.year}
          iconName="CalendarDays"
        />
        <div />
      </div>
      <DgTable
        data={data}
        columnsDef={getColumnsServicesHistoric(funcs)}
        className="mt-8 tableHeaderPersonal"
      />
    </Skinpage>
  );
};

export default ServicesHistoric;
