import * as icons from "lucide-react";

const InputIcon = ({ name, color, size, className, style }) => {
  const LucideIcon = icons[name];

  return LucideIcon ? (
    <LucideIcon
      color={color}
      size={size}
      className={`iconInput ml-2 mr-4 z-50 ${className}`}
      style={style}
    />
  ) : null;
};

export default InputIcon;
