import React from "react";
import PropTypes from "prop-types";

const Title = ({ children = "", className }) => {
  return (
    <div className={`text-sesBlue font-bold text-xl ${className}`}>
      {children.toUpperCase()}
    </div>
  );
};

Title.propTypes = {
  children: PropTypes.string,
};

export default Title;
