import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getFromSession, validateSession } from "./helpers/storageHandler";
import { useEffect, useState } from "react";
import { initialFetch } from "./redux/actions/auth";
import Login from "./pages/Login";
import NewClient from "./pages/cashing-crm/ClientInsert";
import ListClient from "./pages/cashing-crm/ClientList";
import DetailClient from "./pages/cashing-crm/ClientDetail";
import Invoicing from "./pages/invoicing/Invoicing";
import InvoiceInsert from "./pages/invoicing/InvoiceInsert";
import InvoiceAnticipate from "./pages/invoicing/InvoiceAnticipate";
import CashingReports from "./pages/cashing-crm/CashingReports";
import CashingAnalytics from "./pages/cashing-crm/CashingAnalytics";
import NewUser from "./pages/admin/UserInsert";
import UserList from "./pages/admin/UserList";
import NewPerson from "./pages/rh/PersonInsert";
import PersonList from "./pages/rh/PersonList";
import PersonDetail from "./pages/rh/PersonDetail";
import Paysheet from "./pages/rh/Paysheet";
import DoubletView from "./pages/rh/DoubletView";
import "./App.css";
import ServicesHistoric from "./pages/rh/ServicesHistoric";
import { useSelector } from "react-redux";
import { getPermissions } from "./helpers/utils";

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

const AppRouter = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const {
    auth: {
      data: { idUser: loggedInUserId },
    },
    permissions,
  } = useSelector((s) => s);

  const dispatch = useDispatch();
  const jwt = getFromSession("jwt");

  useEffect(() => {
    if (jwt) {
      dispatch(initialFetch(jwt));
    }
    setUserPermissions(getPermissions(permissions));
  }, [loggedInUserId]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={validateSession() ? "/inicio" : "/login"} />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/inicio" element={<NewClient />} />
        <Route exact path="/clientes" element={<ListClient />} />
        <Route exact path="/facturacion" element={<Invoicing />} />
        <Route exact path="/detalle-cliente/:id" element={<DetailClient />} />
        <Route exact path="/facturar" element={<InvoiceInsert />} />
        <Route
          exact
          path="/adelantar-factura"
          element={<InvoiceAnticipate />}
        />
        <Route exact path="/reportes-cobranza" element={<CashingReports />} />
        <Route
          exact
          path="/analiticos-cobranza"
          element={<CashingAnalytics />}
        />
        <Route exact path="/nueva-persona" element={<NewPerson />} />
        <Route
          exact
          path="/lista-personal"
          element={
            <PersonList
              registerDoubletPermission={userPermissions?.includes(11)}
              registerVacationsPermission={userPermissions?.includes(10)}
            />
          }
        />
        <Route exact path="/nuevo-usuario" element={<NewUser />} />
        <Route exact path="/lista-usuarios" element={<UserList />} />
        <Route exact path="/detalle-empleado/:id" element={<PersonDetail />} />
        {userPermissions?.includes(8) ? (
          <Route
            exact
            path="/calculo-nomina"
            element={
              <Paysheet doublesPermission={userPermissions?.includes(11)} />
            }
          />
        ) : (
          <></>
        )}
        <Route
          exact
          path="/vista-dobletes"
          element={
            <DoubletView doublesPermission={userPermissions?.includes(11)} />
          }
        />
        <Route
          exact
          path="/historico-servicios"
          element={<ServicesHistoric />}
        />
        <Route
          exact
          path="/historico-servicios/:id"
          element={<ServicesHistoric />}
        />
   
       
      </Routes>
    </Router>
  );
};

export default App;
