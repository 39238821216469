import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skinpage from "../../components/Skinpage";
import KpiCard from "../../components/KpiCard";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Request } from "../../helpers/api";
import { parseObjectDate } from "../../helpers/utils";
import { useForm } from "../../helpers/useForm";
import DgInput from "../../components/DgInput";

const CashingAnalytics = () => {
  const [data, setData] = useState({ kpis: {}, charts: {} });
  const [dataPie2, setDataPie2] = useState({});
  const [chartFilters, setChartFilters] = useForm({
    startDate: parseObjectDate(new Date(), 4),
    endDate: parseObjectDate(new Date(), 4),
  });
  const kpisKeysPayment = [
    {
      title: "Saldo vencido",
      key: "pastDuePayment",
    },
    {
      title: "Clientes vencidos",
      key: "clientsPastDuePayment",
    },
    {
      title: "Servicios vencidos",
      key: "servicesPastDuePayment",
    },
  ];
  const kpisKeysPaymentClients = [
    {
      title: "% Saldo vencido",
      key: "percentageClientsPastDuePayment",
    },
    {
      title: "% Clientes vencidos",
      key: "percentagePastDuePayment",
    },
    {
      title: "% Servicios vencidos",
      key: "percentageServicesPastDuePayment",
    },
  ];
  const kpisKeysPercentage = [
    {
      title: "% Clientes vencidos",
      key: "percentageOverdueCustomers",
    },
    {
      title: "% Fiscales",
      key: "percentageOverdueFiscalCustomers",
    },
    {
      title: "% No fiscales",
      key: "percentageOverdueNonFiscalCustomers",
    },
  ];
  const fetchData = useCallback(async () => {
    const { ok, data } = await Request("/analytics/payment");
    if (ok) {
      setData(data);
    }
  }, []);
  const fetchDatePie = useCallback(async (chartFilters) => {
    const { startDate, endDate } = chartFilters; //16/08/2022
    const timeDate = "T12:00:00.000Z";
    const { ok, data } = await Request(
      `/analytics/amountbyregion?startDate=${parseObjectDate(
        new Date(startDate + timeDate),
        1
      )}&endDate=${parseObjectDate(new Date(endDate + timeDate), 1)}`
    );
    if (ok) {
      setDataPie2(data);
    }
  }, []);
  useEffect(() => {
    fetchData();
    fetchDatePie(chartFilters);
  }, [fetchData, fetchDatePie]);
  useEffect(() => {
    fetchDatePie(chartFilters);
  }, [chartFilters]);
  return (
    <Skinpage pageTitle={"ANALÍTICOS DE COBRANZA"}>
      <div className="grid grid-cols-3 gap-5">
        <KpiCard
          data={data.kpis}
          keys={kpisKeysPayment}
          prefix="$"
          applyToAll={false}
        />
        <KpiCard data={data.kpis} keys={kpisKeysPaymentClients} posfix="%" />
        <KpiCard data={data.kpis} keys={kpisKeysPercentage} posfix="%" />
      </div>
      <br />
      <br />
      <div className="grid grid-cols-2 gap-5">
        {/*        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "pie",
            },
            title: {
              text: "Saldo por región",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                },
              },
            },
            series: [
              {
                name: "Regiones",
                colorByPoint: true,
                data: data.charts.balanceDueByRegionPie,
              },
            ],
          }}
        />*/}
        <div>
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "pie",
                },
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                      enabled: true,
                      format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                    },
                  },
                },
                title: {
                  text: "Saldo por Región",
                },
                series: [
                  {
                    name: "Región",
                    colorByPoint: true,
                    data: dataPie2,
                  },
                ],
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <DgInput
              type="date"
              name="startDate"
              value={chartFilters.startDate}
              onChange={setChartFilters}
              placeholder="Fecha inicial"
            />
            <DgInput
              type="date"
              name="endDate"
              value={chartFilters.endDate}
              onChange={setChartFilters}
              placeholder="Fecha final"
            />
          </div>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "pie",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                },
              },
            },
            title: {
              text: "Saldo por tipo de cliente",
            },
            series: [
              {
                name: "Tipo de cliente",
                colorByPoint: true,
                data: data.charts?.balanceDueByTypeClientPie,
              },
            ],
          }}
        />
      </div>
    </Skinpage>
  );
};

CashingAnalytics.propTypes = {};

export default CashingAnalytics;
