import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "../../../helpers/useForm";
import { noop } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import { useState } from "react";
import { invoiceCreditNoteSchema } from "../../../helpers/schemas";
import Button from "../../../components/Button";
import DgForm from "../../../components/DgForm";
import ModalDetails from "./ModalDetails";
import DgLoader from "../../../components/DgLoader";
import { handleInvoice } from "../handlers";
import PostInvoiceModal from "./PostInvoiceModal";

const ModalCreditNote = ({
  isOpen,
  toggle,
  data: paymentData,
  fetchData = noop,
  zIndex,
}) => {
  const [postCfdiModalOpen, setPostCfdiModalOpen] = useState(false);
  const [cfdiList, setCfdiList] = useState([]);
  const [data, onDataChange, resetData, setAllData] = useForm(
    invoiceCreditNoteSchema
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { idDetailService } = paymentData;
    setLoading(true);
    const { ok, objupdateafacturar } = await Request(
      "/invoice/creditnote",
      {
        idDetailService,
        ...data,
      },
      "POST"
    );
    setLoading(false);
    if (ok) {
      handleInvoice(objupdateafacturar, () => {
        resetData();
        fetchData();
        setCfdiList(objupdateafacturar);
        setPostCfdiModalOpen(true);
      });
    }
  };
  useEffect(() => {
    if (isOpen) {
      setAllData({ idService: paymentData.idService, comments: "" });
    }
  }, [paymentData.idService, isOpen]);

  return (
    <Modal
      backdrop={true}
      toggle={toggle}
      zIndex={zIndex}
      isOpen={isOpen}
      size="md"
    >
      <ModalHeader>Crear Nota de crédito</ModalHeader>
      <ModalBody>
        <div className="m-auto mx-2 mt-0">
          <DgForm
            data={data}
            onChange={onDataChange}
            onSubmit={onSubmit}
            groups={[
              {
                fields: [
                  {
                    type: "number",
                    label: "Monto de la nota",
                    min: "0.01",
                    step: "0.01",
                    required: true,
                    name: "amount",
                    value: data.amount,
                    iconName: "FormInput",
                  },
                  {
                    type: "text",
                    label: "Comentarios",
                    minLength: "1",
                    maxLength: "250",
                    required: false,
                    name: "description",
                    value: data.description,
                    iconName: "FormInput",
                  },
                ],
              },
            ]}
            classNameGrid="grid-cols-1"
          >
            <div className="grid grid-cols-2 gap-3">
              <Button type="submit">Aceptar</Button>
              <Button
                onClick={() => {
                  toggle();
                }}
                color="secondary"
              >
                Cancelar
              </Button>
            </div>
          </DgForm>
        </div>
      </ModalBody>
      <DgLoader open={loading} />
      <PostInvoiceModal
        title="Post nota de crédito"
        data={cfdiList}
        open={postCfdiModalOpen}
        toggle={() => setPostCfdiModalOpen((prevState) => !prevState)}
        withSend={{
          title: "Cerrar",
          action: () => {
            setPostCfdiModalOpen((prevState) => !prevState);
            toggle();
          },
        }}
      />
    </Modal>
  );
};

ModalCreditNote.propTypes = {};

export default ModalCreditNote;
