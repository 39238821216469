import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ChevronDown, Plus, Minus } from "lucide-react";
import {
  beneficiariesSchema,
  emergencyContactSchema,
  personSchema,
} from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import {
  personInputsAddress,
  personInputsDocuments,
  personInputsGeneral,
  personInputsSecondary,
} from "../../../helpers/formUtils";
import { useSelector } from "react-redux";
import DgForm from "../../../components/DgForm";
import DgInput from "../../../components/DgInput";
import { relationShipCat } from "../../../helpers/enums";
import { Tooltip } from "@mui/material";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import {
  formatPhone,
  getGeolocationData,
  parseObjectDate,
} from "../../../helpers/utils";
import Button from "../../../components/Button";
import useGetZipCode from "../../../helpers/useGetZipCode";
import { handlePhoneChange } from "../../../helpers/utils";

const PersonInfo = ({
  data: personData = {},
  setAllPersonData,
  isLoading,
  setIsLoading,
}) => {
  const [expanded, setExpanded] = useState(false);
  // const [suburbs, setSuburbs] = useState([]);
  const catalogues = useSelector((s) => s.catalogues);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...personSchema,
  });
  const [handleZipChange, suburbs, inputType, setInputType] = useGetZipCode(
    data.postalCode,
    data,
    setAllData
  );
  const { idEmployee } = personData;
  const handleSubmit = async (e) => {
    const res = await Request(
      `/employee/${idEmployee}`,
      {
        ...data,
        birthDate:
          data.birthDate && data.birthDate !== ""
            ? parseObjectDate(new Date(data.birthDate))
            : "",
        antidopingDate:
          data.antidopingDate === "0000-00-00" || !data.antidopingDate
            ? ""
            : parseObjectDate(new Date(data.antidopingDate)),
      },
      "PUT"
    );

    if (res.ok) {
      Swal.fire("Éxito", "Empleado actualizado con éxito.", "success");
      setIsLoading(!isLoading);
    }
    e.preventDefault();
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedChange = (ix, key) => (e) => {
    const { value, name } = e.target;
    const arr = data[key];
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: key, value: arr } });
  };

  const manageEContacts = (willDelete, ix) => () => {
    const newArr = [...data.emergencyContacts];
    if (willDelete) {
      newArr.splice(ix, 1);
    } else {
      newArr.push({ ...emergencyContactSchema });
    }
    setAllData({ ...data, emergencyContacts: newArr });
  };

  const manageBeneficiaries = (willDelete, ix) => () => {
    const newArr = [...data.employeeBeneficiaries];
    if (willDelete) {
      newArr.splice(ix, 1);
    } else {
      newArr.push({ ...beneficiariesSchema });
    }
    setAllData({ ...data, employeeBeneficiaries: newArr });
  };

  useEffect(() => {
    if (inputType === "postalCode") {
      handleZipChange();
    }
  }, [data.postalCode]);
  useEffect(() => {
    if (personData) {
      setAllData(personData);
    }
  }, [personData]);

  return (
    <div className="pr-4 -ml-2 mt-4">
      <form>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            DATOS GENERALES
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              groups={personInputsGeneral(
                data,
                { handlePhoneChange, onDataChange },
                catalogues
              )}
              onChange={onDataChange}
              data={data}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            DATOS SECUNDARIOS
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              groups={personInputsSecondary(data, {}, catalogues)}
              onChange={onDataChange}
              data={data}
              className="mb-8"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            DIRECCIÓN
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              groups={personInputsAddress(data, {}, catalogues)}
              onChange={(e) => {
                onDataChange(e);
                setInputType(e.target.name);
              }}
              data={data}
              className="mb-8"
              zipError={data.error}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            DATOS DE DOCUMENTOS OFICIALES Y BANCARIOS
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              groups={personInputsDocuments(data, {}, catalogues)}
              onChange={onDataChange}
              data={data}
              className="mb-8"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            CONTACTO DE EMERGENCIA
          </AccordionSummary>
          <AccordionDetails>
            <h3 className="mt-4 ml-2 text-sesBlue font-semibold">
              AGREGAR CONTACTOS
            </h3>
            <div className="grid grid-cols-4 gap-4 gridEmergencyContact">
              {data.emergencyContacts?.length === 0 ? (
                <>
                  <div />
                  <div />
                  <div />
                  <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                    <Plus
                      color="#565656"
                      size={28}
                      className="m-auto p-1 mt-1 cursor-pointer"
                      onClick={manageEContacts(false)}
                    />
                  </div>
                </>
              ) : null}
              {data.emergencyContacts?.map((item, ix) => {
                const onItemChange = handleNestedChange(
                  ix,
                  "emergencyContacts"
                );
                return (
                  <>
                    <DgInput
                      type="text"
                      label="Nombre completo"
                      name="fullName"
                      value={item.fullName}
                      onChange={onItemChange}
                      required={true}
                      iconName={"User"}
                    />
                    <DgInput
                      type="select"
                      label="Parentesco"
                      name="relationship"
                      options={[
                        { label: "Selecciona parentesco", value: "" },
                        ...relationShipCat,
                      ]}
                      value={item.relationship}
                      onChange={onItemChange}
                      required={true}
                      iconName={"User"}
                    />
                    <DgInput
                      type="tel"
                      label="Teléfono"
                      name="phone"
                      placeholder="55-0000-0000"
                      pattern="[0-9]{2}-[0-9]{4}-[0-9]{4}"
                      minLength="12"
                      maxLength="12"
                      value={formatPhone(item.phone)}
                      onChange={(e) => {
                        onItemChange(e);
                      }}
                      required={true}
                      iconName={"Phone"}
                    />
                    <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                      <Tooltip title="Añadir otro contacto">
                        <Plus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 mt-1 cursor-pointer"
                          onClick={manageEContacts(false, ix)}
                        />
                      </Tooltip>
                      <Tooltip title="Quitar contacto">
                        <Minus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 cursor-pointer"
                          onClick={manageEContacts(true, ix)}
                        />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            DATOS DEL BENEFICIARIO
          </AccordionSummary>
          <AccordionDetails>
            <h3 className="mt-4 ml-2 text-sesBlue font-semibold">
              AGREGAR BENEFICIARIOS
            </h3>
            <div className="grid grid-cols-5 gap-4 gridBeneficiary">
              {data.employeeBeneficiaries?.length === 0 ? (
                <>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                    <Plus
                      color="#565656"
                      size={28}
                      className="m-auto p-1 mt-1 cursor-pointer"
                      onClick={manageBeneficiaries(false)}
                    />
                  </div>
                </>
              ) : null}
              {data.employeeBeneficiaries?.map((item, ix) => {
                const onItemChange = handleNestedChange(
                  ix,
                  "employeeBeneficiaries"
                );
                return (
                  <>
                    <DgInput
                      type="text"
                      label="Nombre completo"
                      name="fullName"
                      value={item.fullName}
                      onChange={onItemChange}
                      required={true}
                      iconName={"User"}
                    />
                    <DgInput
                      type="tel"
                      label="Teléfono"
                      name="phone"
                      placeholder="55-0000-0000"
                      pattern="[0-9]{2}-[0-9]{4}-[0-9]{4}"
                      maxLength="12"
                      value={formatPhone(item.phone)}
                      onChange={onItemChange}
                      required={true}
                      iconName={"Phone"}
                    />
                    <DgInput
                      type="text"
                      label="Dirección"
                      name="address"
                      placeholder="Calle, número, colonia, municipio, etc."
                      value={item.address}
                      onChange={onItemChange}
                      required={true}
                      iconName={"MapPin"}
                    />
                    <DgInput
                      type="number"
                      label="Porcentaje"
                      name="percentage"
                      placeholder="100%"
                      value={item.percentage}
                      onChange={onItemChange}
                      required={true}
                      iconName={"Percent"}
                    />
                    <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                      <Tooltip title="Añadir otro beneficiario">
                        <Plus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 mt-1 cursor-pointer"
                          onClick={manageBeneficiaries(false, ix)}
                        />
                      </Tooltip>
                      <Tooltip title="Quitar beneficiario">
                        <Minus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 cursor-pointer"
                          onClick={manageBeneficiaries(true, ix)}
                        />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
        <div
          style={{
            width: "40%",
            marginLeft: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button onClick={handleSubmit}>Enviar</Button>
        </div>
      </form>
    </div>
  );
};

export default PersonInfo;
