import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Request } from "../../../helpers/api";
import { getColumnsPayments } from "../../../helpers/datatableHelpers";
import DgButton from "../../../components/Button";
import Swal from "sweetalert2";
import InvoiceDetails from "./ModalDetails";
import DgTable from "../../../components/DgTable";
import DgModal from "../../../components/DgModal";
import DgLoader from "../../../components/DgLoader";
import ModalCancel from "./ModalCancel";
import { handleCancelCfdi, handleInvoice } from "../handlers";
import { handleDownloadClick } from "../../../helpers/utils";
import PostInvoiceModal from "./PostInvoiceModal";

const ModalServicePayments = ({
  isOpen,
  toggle,
  data: serviceData,
  fetchData: fetchListData,
}) => {
  const [postCfdiModalOpen, setPostCfdiModalOpen] = useState(false);
  const [cfdiList, setCfdiList] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalCancelComplement, setModalCancelComplement] = useState(false);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [paymentComplementData, setPaymetComplementData] = useState({
    paymentsDetailsServices: [],
  });
  const fetchData = useCallback(async (idService) => {
    const { ok, data } = await Request(
      `/service/detailservice/${idService}/paymentDetailService?isActive=true`
    );
    if (ok) {
      setPayments(data);
    }
  }, []);
  const resetData = () => {
    setSingleData({});
    setPaymetComplementData({ paymentsDetailsServices: [] });
    setPayments((prevPayments) =>
      prevPayments.map((payment) => ({
        ...payment,
        checked: !payment.checked,
      }))
    );
  };
  const handleComplementCreation = async (paymentsIds) => {
    setLoading(true);
    const { ok, error, objupdateafacturar } = await Request(
      "/invoice/paymentcomplement",
      paymentsIds,
      "POST"
    );
    if (ok) {
      resetData();
      handleInvoice(
        objupdateafacturar,
        () => {
          fetchData(serviceData.idDetailService);
          fetchListData();
          setPostCfdiModalOpen(true);
          setCfdiList(objupdateafacturar);
        },
        ""
      );
    } else {
      Swal.fire({
        title: "Complemento de pago",
        text: error,
        icon: "error",
      });
    }
    setLoading(false);
  };

  const dtFuncs = {
    selectPaymentComplement: (row, checked) => {
      const { idPaymentDetailService: idToFind, idBankDeposit } = row;
      const itemToAdd = { idPaymentDetailService: idToFind, idBankDeposit };
      if (checked) {
        setPaymetComplementData((prevComplementData) => {
          return {
            ...prevComplementData,
            paymentsDetailsServices: [
              ...prevComplementData.paymentsDetailsServices,
              itemToAdd,
            ],
          };
        });
      } else {
        setPaymetComplementData((prevComplementPaymentData) => {
          return {
            ...prevComplementPaymentData,
            paymentsDetailsServices:
              prevComplementPaymentData.paymentsDetailsServices.filter(
                ({ idPaymentDetailService }) =>
                  idPaymentDetailService !== idToFind
              ),
          };
        });
      }
    },
    paymentDocument: (row) => () => {
      setSingleData({
        ...row,
        document: "payment-complement",
      });
      setModalOpenInvoice(true);
    },
    cancelPaymentComplement: (row) => () => {
      setSingleData(row);
      setModalCancelComplement((prevData) => !prevData);
      // handleComplementDelete(row);
    },
    delete: (row) => {
      const { idPaymentDetailService } = row;

      Swal.fire({
        title: "¿Confirma la siguiente operación?",
        text: "Cancelación de pago",
        icon: "question",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          setLoading(true);
          const { ok } = await Request(
            `/client/bankDeposit/paymentDetailService/${idPaymentDetailService}`,
            {},
            "PATCH"
          );
          setLoading(false);
          if (ok) {
            Swal.fire({
              title: "Éxito",
              text: "Pago cancelado con éxito.",
              icon: "success",
            }).then(() => {
              fetchData(serviceData.idDetailService);
              fetchListData();
            });
          }
        }
      });
    },
    downloadXML: (row) => {
      handleDownloadClick(row.urlXml, setLoading);
    },
    downloadPDF: (row) => {
      handleDownloadClick(row.urlPaymentComplement, setLoading);
    },
  };
  useEffect(() => {
    if (serviceData.idDetailService && isOpen) {
      fetchData(serviceData.idDetailService);
    }
  }, [serviceData]);

  useEffect(() => {
    resetData();
  }, [isOpen]);
  return (
    <>
      <DgModal
        toggle={toggle}
        style={{ width: "85%", overflow: "auto" }}
        withClose={true}
        withSend={{
          action: () => {
            handleComplementCreation(paymentComplementData);
          },
          title: "Crear complemento",
          disabled: paymentComplementData.paymentsDetailsServices.length
            ? false
            : true,
        }}
        title={"PAGOS DE SERVICIO"}
        open={isOpen}
      >
        <div style={{ width: "100%" }}>
          <DgTable
            columnsDef={getColumnsPayments(
              dtFuncs,
              paymentComplementData.paymentsDetailsServices[0]?.idBankDeposit
            )}
            data={payments}
          />
        </div>
        <DgLoader open={loading} />
      </DgModal>
      <InvoiceDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={singleData}
      />
      <ModalCancel
        isLoading={loading}
        showForm={false}
        isOpen={modalCancelComplement}
        toggle={() => {
          setModalCancelComplement((prevData) => !prevData);
        }}
        data={singleData}
        onConfirm={(epBody) =>
          handleCancelCfdi(
            singleData,
            {
              epBody,
              endPoint: "/invoice/paymentComplement/serviceDetailCfdi/",
              cfdiId: "idServiceDetailCfdi",
              setLoading: setLoading,
            },
            () => {
              fetchData(serviceData.idDetailService);
              fetchListData();
              setModalCancelComplement(false);
            },
            {
              text: "Cancelación de complemento de pago",
              onSuccessMessage: "Complemento cancelado con éxito!",
            }
          )
        }
        filterCancelMethodBy={"applyPaymentComplement"}
        loading={loading}
      />
      <PostInvoiceModal
        open={postCfdiModalOpen}
        toggle={() => setPostCfdiModalOpen((prevState) => !prevState)}
        withSend={{
          title: "Cerrar",
          action: () => {
            setPostCfdiModalOpen(false);
          },
        }}
        data={cfdiList}
      />
      <DgLoader open={loading} />
    </>
  );
};
export default ModalServicePayments;
