import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { enumFileTypes } from "../helpers/enums";
import { Tooltip } from "@mui/material";
import { Paperclip } from "lucide-react";
import Button from "./Button";

function DgInputMultipleFiles({ onChange, name, style }) {
  return (
    <FileUploader
      classes="cursor-pointer outline-none"
      handleChange={(files) => {
        onChange({ target: { name: name, value: files } });
      }}
      name={name}
      types={enumFileTypes}
      multiple={true}
    >
      <Tooltip title="Adjuntar archivos" placement="right" arrow>
        <div style={{ width: "fit-content", ...style }}>
          <Button style={{ padding: 20 }}>
            <Paperclip color={"white"} />
          </Button>
        </div>
      </Tooltip>
    </FileUploader>
  );
}

export default DgInputMultipleFiles;
