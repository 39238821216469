import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DgInput from "../../../components/DgInput";
import { Request } from "../../../helpers/api";
import ClientCard from "../../../components/ClientCard";
import Button from "../../../components/Button";
import Swal from "sweetalert2";
import { downloadBlobFile } from "../../../helpers/utils";
import useGetScreenWidth from "../../../helpers/useGetScreenWidth";
import DgLoader from "../../../components/DgLoader";

const AccountState = () => {
  const [loading, setLoading] = useState(false);
  const [clientFilterValue, setClientFilterValue] = useState("");
  const [screenWidth] = useGetScreenWidth();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [downloadParams, setDownloadParams] = useState({
    idClient: null,
    reportType: null,
  });

  const fetchData = useCallback(async () => {
    const { ok, data } = await Request("/client");
    if (ok) {
      const parsedClients = data.map((c) => ({ ...c, selected: false }));
      setAllData(parsedClients);
      setData(parsedClients);
    }
  }, []);

  const handleTapCard = (ix) => () => {
    const client = data[ix];
    const newClients = data.map((c) => ({ ...c, selected: false }));
    newClients[ix].selected = !client.selected;
    setDownloadParams((prevState) => ({
      ...prevState,
      idClient: client.idClient,
    }));
    setAllData((prevData) =>
      prevData.map((card) => ({
        ...card,
        selected: card.idClient == client.idClient ? !client.selected : false,
      }))
    );
    setData(newClients);
  };

  const handleSubmit = async () => {
    const clientSelected = data.find((c) => c.selected);
    if (clientSelected) {
      const { idClient, reportType } = downloadParams;
      setLoading(true);
      const res = await Request(
        `/report/accountStatus?idClient=${idClient}&reportType=${reportType}`,
        null,
        "GET",
        true,
        false,
        true
      );
      setLoading(false);

      if (res) {
        downloadBlobFile(
          res,
          `Estado de Cuenta - ${clientSelected.name.toUpperCase()}.xlsx`
        );
      }
    } else {
      Swal.fire(
        "Error",
        "Debes seleccionar un cliente antes de generar el reporte.",
        "error"
      );
    }
  };

  useState(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (clientFilterValue) {
      const filterValues = clientFilterValue
        .split(",")
        .map((inputValue) => inputValue.trim().toUpperCase());
      setData(
        allData.filter(({ name, tradeName }) => {
          return filterValues.some(
            (filterValue) =>
              name?.toUpperCase().includes(filterValue) ||
              tradeName?.toUpperCase().includes(filterValue)
          );
        })
      );
    } else {
      setData(allData);
    }
  }, [allData, clientFilterValue]);

  return (
    <div className="w-full">
      <div className="flex  gap-2 my-2">
        <DgInput
          label="Buscar Cliente"
          placeholder="Buscar ..."
          iconName="Search"
          name="search"
          onChange={({ target: { value } }) => {
            setClientFilterValue(value);
          }}
          value={clientFilterValue}
          containerClassName={"flex-grow m-0"}
        />
        <DgInput
          label="Tipo"
          name="reportType"
          type="select"
          iconName={"Landmark"}
          options={[
            { label: "Seleccione una opción..", value: "" },
            { label: "Cuenta deudor", value: "DEBTOR" },
            { label: "General", value: "GENERAL" },
          ]}
          onChange={({ target: { name, value } }) => {
            setDownloadParams((prevState) => ({
              ...prevState,
              reportType: value,
            }));
          }}
          containerStyle={{ width: 500, flexGrow: 1, margin: 0 }}
        />
        <Button
          containerStyle={{ width: 500, margin: 0 }}
          style={{ marginTop: "50px" }}
          onClick={handleSubmit}
        >
          Generar Reporte
        </Button>
      </div>
      <div className="flex flex-wrap gap-3">
        {data.map((c, ix) => (
          <ClientCard
            style={{ width: "32%", minWidth: screenWidth < 920 ? "100%" : 460 }}
            client={{ ...c, ix }}
            selectable={true}
            onTap={handleTapCard}
            md={6}
          />
        ))}
      </div>
      <DgLoader open={loading} />
    </div>
  );
};

AccountState.propTypes = {};

export default AccountState;
