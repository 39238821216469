import React from "react";
import { Modal } from "@mui/material";
import Button from "./Button";
import PropTypes from "prop-types";
import DgContainer from "./DgContainer";

//ESTE MODAL SÓLO SIRVE PARA MOSTRAR INFORMACIÓN DETALLADA, COMO SE PUEDE VER, SE LE PASA COMO PROP UN ESTADO Y LA FUNCIÓN PARA MODIFICAR EL MISMO. ESE ESTADO SE USA PARA ABRIR Y CERRAR EL MODAL, SU VALOR INICIAL DEBE SER FALSE.

function DgModal({
  open,
  toggle,
  children,
  modalStyle,
  style,
  withSend,
  withClose,
  title,
  titleStyle,
  onClose,
  className,
  modalClassName,
}) {
  return (
    <Modal
      open={open}
      className={`flex items-center justify-center w-full h-full ${modalClassName}`}
      style={modalStyle}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          toggle();
        }
        onClose && onClose();
      }}
    >
      <DgContainer
        className={`dgModalContainer rounded-xl ${className}`}
        style={style}
      >
        <div className={`w-full h-5 bg-sesBlue rounded-t-xl`}>
          <div style={{ opacity: 0 }}>AAAAAAAAAAAAAAAAAAAAA</div>
        </div>
        {title && (
          <h1
            style={{
              fontSize: 23,
              fontWeight: 700,
              color: "#2A2F99",
              paddingTop: 24,
              paddingBottom: 16,
              ...titleStyle,
            }}
          >
            {title}
          </h1>
        )}
        {children}

        {(withClose || withSend) && (
          <div
            style={{
              display: "flex",
              paddingBottom: 20,
              gap: 20,
              width: "fit-content",
            }}
          >
            {withSend && (
              <Button
                style={{ width: "fit-content", whiteSpace: "nowrap" }}
                onClick={withSend.action}
                disabled={withSend.disabled}
              >
                {withSend.title}
              </Button>
            )}
            {withClose && (
              <Button
                onClick={() => {
                  toggle();
                }}
              >
                Cerrar
              </Button>
            )}
          </div>
        )}
      </DgContainer>
    </Modal>
  );
}

DgModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DgModal;
