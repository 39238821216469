import React from "react";
import FormTitle from "./FormTitle";

function PostPaymentComplementCardTitle({ data }) {
  const name = data?.paymentComplement.Receiver.Name || "";
  const type = data?.dataBankDeposit.typeBankDeposit || "";
  const folio = (data?.prefix || "") + (data?.internalFolio || "");
  return (
    <FormTitle
      className={"text-center p-0 m-0"}
      title={`${name} ${type} - ${folio}`}
    />
  );
}

export default PostPaymentComplementCardTitle;
