import React, { useState } from "react";
import Swal from "sweetalert2";
import { Request } from "./api";
import { downloadBlobFile } from "./utils";

function useGetFile(url = "/invoice/downloadInvoice?invoiceId=") {
  const [isLoading, setIsLoading] = useState(false);
  const fromBase64ToDocument = async (invoiceId = "", fileName) => {
    //PEDIDO DEL XML EN FORMATO BASE64

    setIsLoading(true);
    const { ok, ...res } = await Request(`${url}${invoiceId}`);

    if (ok) {
      const { ContentType, Content } = res.result;
      //DECODIFICACIÓN DE BASE64 A CONTENIDO XML EN FORMATO STRING
      const decodedRes = atob(Content);

      // CREAMOS UN BLOB A PARTIR DEL XML EN FORMATO STRING
      const arrayBuffer = new ArrayBuffer(decodedRes.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < decodedRes.length; i++) {
        uint8Array[i] = decodedRes.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: `text/${ContentType}` });

      // DESCARGAMOS EL DOCUMENTO
      downloadBlobFile(
        blob,
        `${fileName || res.fileName || ""}.${ContentType}`
      );
    }

    setIsLoading(false);
  };

  return [fromBase64ToDocument, isLoading];
}

export default useGetFile;
