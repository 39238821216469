import React, { useEffect, useState } from "react";
import { useForm } from "../../../helpers/useForm";
import { clientSchema } from "../../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { getGeolocationData, parseInvoiceErrors } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { Request } from "../../../helpers/api";
import { addressInputs, invoiceInputs } from "../../../helpers/formUtils";
import iconoPlantillaServices from "../../../css/img/IconoPlantillaServicio.svg";
import DgForm from "../../../components/DgForm";
import DgButton from "../../../components/Button";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";
import DgLoader from "../../../components/DgLoader";

const ModalRemissionInvoice = ({
  isOpen,
  toggle,
  fetchData: fetchInvData,
  data: singleData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientSchema,
  });
  const catalogues = useSelector((s) => s.catalogues);
  const [suburbs, setSuburbs] = useState([]);
  const handleZipChange = async (value) => {
    const {
      localities = [],
      state = "",
      municipality = "",
    } = await getGeolocationData(value, data.country);
    setSuburbs(localities);
    setAllData({
      ...data,
      state,
      municipality,
      suburb: localities.length === 1 ? localities[0].value : data.suburb,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { idService } = singleData;
    setIsLoading(true);
    const res = await Request(
      `/invoice/invoiceremission`,
      {
        idService,
        ...data,
      },
      "POST"
    );
    setIsLoading(false);
    const errorsList = [];

    if (!res.ok) {
      return Swal.fire({
        title: "Error",
        icon: "error",
        text: res.error,
      });
    }
    if (res.objupdateafacturar) {
      res.objupdateafacturar.forEach(
        ({ createdPdf, invoiced, remitted, nameService, msgInvoiced }) => {
          const completedInvoice = createdPdf && (invoiced || remitted);
          if (!completedInvoice) {
            errorsList.push({ nameService, msgInvoiced });
          }
        }
      );
    } else {
      const {
        error: { Message, ModelState },
      } = res;
      if (ModelState) {
        Object.keys(ModelState).map((errorKey) => {
          errorsList.push(ModelState[errorKey]);
        });
      } else {
        errorsList.push(Message);
      }
    }
    const allInvoiced = errorsList.length === 0;

    Swal.fire({
      title: allInvoiced ? "Éxito" : "Error",
      icon: allInvoiced ? "success" : "error",
      html:
        res.objupdateafacturar && !errorsList.length
          ? "Facturación exitosa!" //errorsList[0].nameService +
          : "Facturación fallida!" + //   ": " +
            //   parseInvoiceErrors(errorsList[0].msgInvoiced)
            errorsList.flat(999).join("</br>"),
    }).then(() => {
      if (allInvoiced) {
        setAllData({ ...clientSchema });
        fetchInvData();
        toggle();
      }
    });
  };
  const getFields = () => {
    return [
      {
        title: "DOMICILIO FISCAL",
        fields: addressInputs({ ...data, clientType: "FISCAL" }, suburbs),
      },
      {
        title: "FACTURACIÓN",
        fields: [
          {
            label: "Razón social",
            placeholder: "Razón social",
            type: "text",
            name: "name",
            minLength: "2",
            required: true,
            iconName: "FormInput",
            errorMessage: "Ésta razón social no es válida",
          },
          {
            label: "RFC",
            placeholder: "RFC",
            type: "text",
            maxLength: 13,
            minLength: 12,
            name: "rfc",
            required: true,
            iconName: "FormInput",
          },
          ...invoiceInputs(data, onDataChange, catalogues),
        ],
      },
    ];
  };
  const fetchData = async () => {
    const { idClient } = singleData;
    if (idClient) {
      const res = await Request("/client/" + idClient);
      if (res.ok) {
        setAllData(res.data);
        //handleZipChange(res.data.postalCode);
      }
    }
  };
  useEffect(() => {
    handleZipChange(data.postalCode);
  }, [data.postalCode]);
  useEffect(() => {
    const { idClient } = singleData;
    if (idClient && isOpen) {
      fetchData(idClient);
    }
  }, [singleData]);

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <div
          style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
        >
          FACTURAR REMISIÓN
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={iconoPlantillaServices} style={{ width: "5rem" }} />
            <div>
              <span className="sesStrongText">SERVICIO:</span>
              {singleData.nameService}
            </div>
          </div>
          <div>$ Servicio</div>
        </div>
        <DgForm
          data={data}
          classNameGrid="grid grid-cols-2 gap-4"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <ModalFooter>
            <div className="w-full grid grid-cols-4 gap-4">
              <div />
              <div />
              <DgButton type="submit" color="primary">
                Generar Factura
              </DgButton>
              <DgButton color="secondary" onClick={toggle}>
                Cerrar
              </DgButton>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
      <DgLoader open={isLoading} />
    </Modal>
  );
};

export default ModalRemissionInvoice;
