import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "../../../helpers/useForm";
import { useSelector } from "react-redux";
import { noop, parseObjectDate, sanitizeDate } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import { useState } from "react";
import { TODAY } from "../../../helpers/consts";
import { invoicePaymentSchema } from "../../../helpers/schemas";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import DgForm from "../../../components/DgForm";

const ModalPaymentStatus = ({
  isOpen,
  toggle,
  data: paymentData,
  fetchData = noop,
}) => {
  const [clientAccounts, setClientAccounts] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [data, onDataChange, clearData, setAllData] =
    useForm(invoicePaymentSchema);
  const { CatStatusPaymentService, SesAccount = [] } = useSelector(
    (s) => s.catalogues
  );
  const handleConceptSelection = (ix) => (e) => {
    const newConcepts = [...concepts];
    newConcepts[ix].selected = e.target.checked;
    setConcepts(newConcepts);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { comments, paymentDate, amount } = data;

    if (amount <= balance || true) {
      const res = await Request(
        "/service/statuspayment/" + paymentData.idService,
        {
          idCatStatusPaymentService: 3,
          comments,
          amount,
          paymentDate: parseObjectDate(new Date(paymentDate), 1, true),
        },
        "PUT"
      );
      if (res.ok) {
        Swal.fire("Éxito", "Pago registrado con éxito.", "success").then(() => {
          setAllData({ ...invoicePaymentSchema });
          fetchData();
          toggle();
        });
      }
    }
  };
  const fetchClientAccounts = async () => {
    const { idClient } = paymentData;
    if (idClient && idClient !== "") {
      const { ok, data } = await Request(`/client/${idClient}`);
      if (ok) {
        setClientAccounts(data.clientAccounts);
      }
    }
  };
  const fetchServiceData = async () => {
    const { idService } = paymentData;
    if (idService && idService !== "") {
      const { ok, data } = await Request(
        `/service/conceptsdetail/${idService}`
      );
      if (ok) {
        setConcepts(
          data.serviceConcepts.map((c) => {
            return { ...c, selected: c.hasPaymentService ? true : false };
          })
        );
        setBalance(data.positiveBalance);
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      setAllData({ idService: paymentData.idService, comments: "" });
      fetchServiceData();
      //fetchClientAccounts();
    }
  }, [paymentData.idService, isOpen]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={toggle}>Registrar Pago</ModalHeader>
      <ModalBody>
        <div className="m-auto mx-2 mt-0">
          <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
            Saldo a favor: <span style={{ color: "#85bb65" }}>$ {balance}</span>
          </h2>
          <DgForm
            data={data}
            onChange={onDataChange}
            onSubmit={onSubmit}
            groups={[
              {
                fields: [
                  {
                    type: "date",
                    label: "Fecha de pago",
                    max: parseObjectDate(TODAY, 4),
                    required: true,
                    iconName: "Calendar",
                    name: "paymentDate",
                    value: data.paymentDate,
                  },
                  {
                    type: "number",
                    label: "Monto de pago",
                    min: "0.01",
                    step: "0.01",
                    required: true,
                    name: "amount",
                    value: data.amount,
                    iconName: "FormInput",
                  },
                  {
                    type: "textarea",
                    label: "Comentarios",
                    required: false,
                    name: "comments",
                    value: data.comments,
                    iconName: "FormInput",
                  },
                ],
              },
            ]}
            classNameGrid="grid-cols-1"
          >
            <div className="grid grid-cols-2 gap-3">
              <Button type="submit">Aceptar</Button>
              <Button
                onClick={() => {
                  toggle();
                }}
                color="secondary"
              >
                Cancelar
              </Button>
            </div>
          </DgForm>
        </div>
      </ModalBody>
    </Modal>
  );
};

ModalPaymentStatus.propTypes = {};

export default ModalPaymentStatus;
