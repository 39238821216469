import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { userInputs } from "../../helpers/formUtils";
import { useSelector } from "react-redux";
import { useForm } from "../../helpers/useForm";
import { getColumnsNewUser } from "../../helpers/datatableHelpers";
import { userSchema } from "../../helpers/schemas";
import Button from "../../components/Button";
import DgForm from "../../components/DgForm";
import DgTable from "../../components/DgTable";
import { Request } from "../../helpers/api";
import Swal from "sweetalert2";
import { uploadImage } from "../../helpers/commonFetchs";
import { GENERAL_PERMISSIONS } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { modifyPermissions } from "../../redux/actions/auth";
import DgLoader from "../../components/DgLoader";

const AdminUserDetails = ({
  data: userData,
  isOpen,
  toggle,
  fetch,
  isModified,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [permData, setPermData] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...userSchema,
  });
  const catalogues = useSelector((s) => s.catalogues);
  const {
    auth: {
      data: { idUser },
    },
  } = useSelector((s) => s);

  const onPasswordConfirmChange = (e) => {
    const { value } = e.target;
    if (value !== data.password) {
      e.target.setCustomValidity("Las contraseñas no coinciden");
      e.target.reportValidity();
    } else {
      e.target.setCustomValidity("");
      e.target.reportValidity();
    }
    onDataChange(e);
  };
  const onSelectFile = (e) => {
    const { files } = e.target;
    setSelectedFile(!files || files.length === 0 ? null : files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //delete data.password;
    //delete data.passwordConfirm;
    setIsLoading(true);
    const res = await Request(
      `/user/${data.idUser}`,
      {
        ...data,
        permissions: permData.map(({ idCatAction, visibility }) => ({
          idCatAction,
          visibility,
        })),
      },
      "PUT"
    );
    setIsLoading(false);
    fetch(!isModified);
    if (res.ok) {
      idUser === data.idUser && dispatch(modifyPermissions(permData));
      if (originalData.clave !== data.password) {
        await Request(
          `/user/${data.idUser}/recover-password`,
          {
            password: data.password,
          },
          "POST"
        );
      }

      const resImg = await uploadImage(userData.idUser, selectedFile);
      if (resImg.ok) {
        Swal.fire({
          title: "Éxito",
          text: "Actualización exitosa",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Éxito",
          text: "Actualización parcial, no se ha cargado imagen.",
          icon: "success",
        });
      }
    }
    toggle();
  };
  const handleActiveChange = (row) => (e) => {
    const newPermData = [...permData];
    const permIx = newPermData.findIndex(
      (p) => p.idCatAction == row.idCatAction
    );
    const perm = newPermData[permIx];
    perm.visibility = e.target.checked;
    newPermData[permIx] = perm;
    setPermData(newPermData);
  };
  const fetchData = async () => {
    const { idUser } = userData;
    if (idUser) {
      const { ok, data } = await Request(`/user/${idUser}`);
      const { clave } = data.user;
      if (ok) {
        setOriginalData(data.user);
        setAllData({ ...data.user, password: clave, passwordConfirm: clave });
        setPermData(
          data.permissions.map((p) => ({
            ...p,
            visibility: p.visibility === 1,
          }))
        );
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [userData]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>ACTUALIZAR USUARIO</ModalHeader>
      <ModalBody>
        <DgForm
          data={data}
          onChange={onDataChange}
          groups={userInputs(
            { ...data, selectedFile },
            { onPasswordConfirmChange, onSelectFile },
            catalogues
          )}
          className="grid grid-cols-2 gap-4"
          colsNumber={1}
          onSubmit={handleSubmit}
        >
          <div className="w-full">
            <h2 className="text-sesBlue font-semibold text-lg mt-4">
              PERMISOS
            </h2>
            <p>
              Seleccione los permisos y funcionalidades a los que el usuario
              tendrá acceso.
            </p>
            <br />
            <DgTable
              columnsDef={getColumnsNewUser({ handleActiveChange })}
              data={permData}
              className="containerTableMid mt-16"
              pagination={false}
            />
          </div>
          <ModalFooter className="col-span-2">
            <div className="w-full grid grid-cols-3 gap-4">
              <div />
              <Button type="submit">Actualizar </Button>
              <Button
                onClick={() => {
                  toggle();
                }}
                color="secondary"
              >
                Cancelar
              </Button>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
      <DgLoader open={isLoading} />
    </Modal>
  );
};

AdminUserDetails.propTypes = {};

export default AdminUserDetails;
