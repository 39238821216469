import React, { useEffect, useState } from "react";
import { useForm } from "../../../helpers/useForm";
import { subClientSchema } from "../../../helpers/schemas";
import { useSelector } from "react-redux";
import DgForm from "../../../components/DgForm";
import DgLoader from "../../../components/DgLoader";
import DgModal from "../../../components/DgModal";
import { subclientReBillingSchema } from "../../../helpers/formUtils";
import Swal from "sweetalert2";
import { parseInvoiceErrors } from "../../../helpers/utils";
import useGetZipCode from "../../../helpers/useGetZipCode";
import { Request } from "../../../helpers/api";
import { handleRebill } from "../handlers";

const ModalReSubclients = ({
  isOpen,
  toggle,
  data,
  fetchData: fetchListData,
  zIndex,
}) => {
  const [formData, onDataChange, clearData, setAllData] = useForm({});
  const [loading, setLoading] = useState(false);
  const { CatReasonCfdiCancellation, CatRegime, CatCfdi, CatPaymentWay } =
    useSelector((s) => s.catalogues);

  const rebill = async (data) => {
    const { nameDetailService: nameClient, internalFolio: invoice } = data;
    Swal.fire({
      title: "¿Confirma la siguiente operación?",
      html: `Se refacturará el siguiente servicio:<br> ${nameClient} <br> ${invoice}.`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setLoading(true);
        const res = await Request(
          `/invoice/rebilldetailservice`,
          formData,
          "POST"
        );
        setLoading(false);

        handleRebill(
          res,
          () => {
            fetchListData();
            toggle();
          },
          setLoading,
          res.error || "Error al inicio de la refacturación"
        );
      }
    });
  };
  const [handleZipChange, suburbs] = useGetZipCode(
    formData.postalCode,
    formData,
    setAllData
  );

  useEffect(() => {
    handleZipChange();
  }, [formData.postalCode]);

  useEffect(() => {
    setAllData({
      ...data,
      idCatReasonCfdiCancellation: "2",
      commentInactive: "",
    });
  }, [isOpen]);

  return (
    <DgModal
      open={isOpen}
      toggle={toggle}
      withClose={true}
      withSend={{
        action: () => {
          rebill(formData);
        },
        title: "Refacturar",
      }}
      style={{ height: "90vh", overflow: "auto", zIndex: zIndex }}
      title={"Refacturar parcialidad"}
    >
      <div style={{ width: "90%", marginTop: 20 }}>
        <DgForm
          data={formData}
          groups={[
            {
              fields: subclientReBillingSchema(
                formData,
                { onDataChange },
                {
                  CatRegime,
                  CatCfdi,
                  CatPaymentWay,
                  CatReasonCfdiCancellation,
                  suburbs: suburbs,
                }
              ),
            },
          ]}
        />
      </div>
      <DgLoader open={loading} />
    </DgModal>
  );
};
export default ModalReSubclients;
