import React from "react";
import FormTitle from "./FormTitle";

function PostInvoiceCardTitle({ data }) {
  return (
    <FormTitle
      className={"text-center p-0 m-0"}
      title={`${
        data?.detailService.nameDetailService
      } ${data?.detailService.clientTypeDetailService.replace(
        "NONFISCAL",
        "NO FISCAL"
      )} - ${data?.prefix}${data?.internalFolio}`}
    />
  );
}

export default PostInvoiceCardTitle;
