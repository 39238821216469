import React from "react";
import ListItem from "./ListItem";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Tooltip, capitalize } from "@mui/material";
import InputIcon from "./InputIcon";

function FiltrableDataList({
  options,
  name,
  label,
  required,
  containerStyle,
  iconName,
  className,
  onInputChange,
  placeholder,
  value,
  disabled,
  style,
}) {
  const [isActive, setIsActive] = useState(false);
  const [optionsCopy, setOptionsCopy] = useState(options);
  const [inputValue, setInputValue] = useState({
    label: "",
    value: "",
  });

  const handleIsActive = () => {
    setIsActive((isActive) => !isActive);
  };
  const handleInputValue = (e) => {
    setInputValue({ label: e.target.value, value: e.target.value });
    onInputChange({ target: { name: name, value: e.target.value } });
  };
  useEffect(() => {
    const filteredOptions = options.filter((option) =>
      option.label.toUpperCase().includes(inputValue.label.toUpperCase())
    );
    !inputValue.value
      ? setOptionsCopy(options)
      : setOptionsCopy(filteredOptions);
  }, [options, inputValue]);
  useEffect(() => {
    if (value) {
      setInputValue({
        label: value,
        value: value,
      });
    }
  }, [value]);
  return (
    <div
      className={className}
      style={{ position: "relative", ...containerStyle }}
    >
      {label && (
        <label className="text-sesBlue text-left text-base labelForm p-2">
          {required && "*"} {label}
        </label>
      )}
      <Tooltip title={inputValue.label}>
        <div style={{ display: "flex", position: "relative" }}>
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: -7,
            }}
          >
            {iconName && (
              <InputIcon name={iconName} color="#565656" size={42} />
            )}
          </div>
          <input
            disabled={disabled}
            placeholder={placeholder}
            onFocus={handleIsActive}
            onBlur={() => {
              setTimeout(() => {
                handleIsActive();
              }, 100);
            }}
            className={`cssInput text-sesGray placeholder-textGray placeholder-opacity-30 py-3 pr-8 pl-14 w-full`}
            onChange={(e) => {
              handleInputValue(e);
            }}
            value={inputValue.label}
            style={{ cursor: "pointer", margin: 0, ...style }}
          />
        </div>
      </Tooltip>
      <div
        style={{
          position: "absolute",
          overflowY: "scroll",
          width: "100%",
          minHeight: "fit-content",
          maxHeight: 200,
          zIndex: 999,
          display: isActive ? "block" : "none",
        }}
      >
        {optionsCopy.map((o, index) => (
          <ListItem
            key={index}
            containerStyle={{
              cursor: "pointer",
              marginTop: 0,
              backgroundColor: "#FFFFFF",
              height: 40,
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              borderLeft: "1px solid rgba(0, 0, 0, 1)",
              borderBottom:
                index === options.length - 1 && "1px solid rgba(0, 0, 0, 1)",
            }}
            coloredBarStyle={{ display: "none" }}
            style={{ padding: 0 }}
            showItem={false}
            title={o.label}
            onMouseDown={() => {
              onInputChange({ target: { name: name, value: o.value } });
              setInputValue(o);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default FiltrableDataList;
