import React from "react";
import PostInvoiceCard from "../../../components/PostInvoiceCard";
import DgModal from "../../../components/DgModal";
import { useNavigate } from "react-router-dom";

function PostInvoiceModal({
  data = [],
  open,
  toggle,
  withSend,
  onClose,
  title = "Post facturación",
}) {
  const navigate = useNavigate();
  const witSendAction = withSend || {
    title: "Volver a la tabla principal",
    action: () => {
      navigate("/facturacion");
    },
  };
  const onCloseAction =
    onClose ||
    function () {
      navigate("/facturacion");
    };
  return (
    <DgModal
      style={{ gap: 20, maxHeight: "80%", overflow: "auto" }}
      title={title}
      open={open}
      toggle={toggle}
      withSend={witSendAction}
      onClose={onCloseAction}
    >
      {data.map((serviceData) => {
        if (serviceData.invoiced || serviceData.generatedPaymentComplement) {
          return <PostInvoiceCard data={serviceData} />;
        }
      })}
    </DgModal>
  );
}

export default PostInvoiceModal;
