import React from "react";
import LoaderSvg from "../assets/loader.svg";
const Loader = ({ isLoading }) => {
  return isLoading ? (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        backgroundColor: "#ececec78",
      }}
    >
      <img
        style={{ width: "12.5%", margin: "13% auto" }}
        src={LoaderSvg}
        alt="Cargando ... "
      />
    </div>
  ) : null;
};

export default Loader;
