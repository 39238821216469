import React from "react";
import PropTypes from "prop-types";

const KpiCard = ({
  data,
  keys,
  prefix = "",
  posfix = "",
  applyToAll = true,
}) => {
  const [
    { title: t1, key: k1 },
    { title: t2, key: k2 },
    { title: t3, key: k3 },
  ] = keys;
  return (
    <div className="border-sesBlue border-solid border-2 rounded-xl pt-3">
      <div className=" text-center text-xl">
        <p>{t1}</p>
        <b className="text-3xl mb-4 block">{`${prefix} ${
          data[k1] || "-"
        } ${posfix}`}</b>
      </div>
      <div className="grid grid-cols-2 border-t-sesBlue border-2">
        <div className="border-r-sesBlue border-2 ">
          <p className="text-md text-center mb-0">{t2}</p>
          <b className="text-xl block text-center">{`${
            applyToAll ? prefix : ""
          } ${data[k2] || "-"} ${applyToAll ? posfix : ""}`}</b>
        </div>
        <div className="">
          <p className="text-md text-center mb-0">{t3}</p>
          <b className="text-xl block text-center">{`${
            applyToAll ? prefix : ""
          } ${data[k3] || "-"} ${applyToAll ? posfix : ""}`}</b>
        </div>
      </div>
    </div>
  );
};

KpiCard.propTypes = {
  data: PropTypes.object,
  titles: PropTypes.array,
  prefix: PropTypes.string,
  posfix: PropTypes.string,
};

export default KpiCard;
