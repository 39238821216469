import * as React from "react";
import DataTable from "react-data-table-component";
import DgTableExpandable from "./DgTableExpandable";
import NoDataComponent from "./NoDataComponent";

const DgTable = ({
  columnsDef,
  data,
  pagination = 10,
  expandableRows = false,
  expandableRowsComponent = undefined,
  noTableHead = false,
  className,
  rootContainerStyle,
}) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "0.8rem",
        fontWeight: 600,
        padding: "1% 1%",
        backgroundColor: "#2A2F99",
        color: "#fff",
      },
    },
    cells: {
      style: {
        paddingLeft: "1%",
        paddingRight: "1%",
        fontSize: "0.9rem",
        color: "#2A2F99",
      },
    },
    pagination: { style: { borderRadius: "0px 0px 20px 20px" } },
  };
  return (
    <div style={{ width: "100%", ...rootContainerStyle }}>
      <DataTable
        noTableHead={noTableHead}
        pagination={pagination}
        columns={columnsDef}
        responsive={true}
        highlightOnHover={true}
        customStyles={customStyles}
        data={data}
        striped={true}
        noDataComponent={<NoDataComponent />}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        className={className}
      />
    </div>
  );
};

export default DgTable;
