import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "../../../helpers/useForm";
import { getColumnsDeposits } from "../../../helpers/datatableHelpers";
import { noop, parseCat, parseObjectDate } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import DgInput from "../../../components/DgInput";
import Button from "../../../components/Button";
import DgTable from "../../../components/DgTable";
import { useSelector } from "react-redux";
import ListItem from "../../../components/ListItem";
import DgModal from "../../../components/DgModal";
import CancelCashinModal from "../modals/CancelCashinModal";
import { useEffect } from "react";
import Title from "../../../components/Title";

const ClientDeposit = ({ clientData = {}, fetchData = noop }) => {
  const banksCatalogues = useSelector((state) => state.catalogues.bank);
  const [deposits, setDeposits] = useState([]);
  const [data, setData, clearData, setAllData] = useForm({
    amount: "",
    idCatBank: "1",
    checkNumber: "",
  });
  const [cancelCashinModalOpen, setCancelCashinModalOpen] = useState(false);
  const [depositDetails, setDepositDetails] = useState({});
  const [singleData, setSingleData] = useState({});

  const fetchDeposits = async (clientId) => {
    const { data: deposits, ok } = await Request(
      `/client/${clientId}/bankDeposit?isActive=true`
    );
    if (ok) {
      setDeposits(deposits);
    }
  };
  const cancelDeposit = async (idBankDeposit) => {
    const { ok } = await Request(
      `/client/bankDeposit/${idBankDeposit}`,
      {},
      "PATCH"
    );
    if (ok) {
      Swal.fire({
        title: "Éxito",
        text: "Deposito cancelado con éxito.",
        icon: "success",
      }).then(({ isConfirmed, isDismissed }) => {
        fetchDeposits(clientData.idClient);
        (isConfirmed || isDismissed) &&
          setCancelCashinModalOpen((prevState) => !prevState);
      });
    }
  };
  useEffect(() => {
    fetchDeposits(clientData.idClient);
  }, [clientData.idClient]);

  const dtFuncs = {
    delete: (row) => async () => {
      const { ok, data } = await Request(
        `/client/bankDeposit/${row.idBankDeposit}/paymentDetailService`
      );
      ok && setDepositDetails(data);
      setSingleData(row);
      setCancelCashinModalOpen((prevState) => !prevState);
    },
  };

  return (
    <div className="w-full m-auto">
      <div>
        <div>
          <Title className={"py-3"}> DEPÓSITOS</Title>

          <DgTable columnsDef={getColumnsDeposits(dtFuncs)} data={deposits} />
        </div>
      </div>

      <CancelCashinModal
        data={depositDetails}
        open={cancelCashinModalOpen}
        toggle={() =>
          setCancelCashinModalOpen((previousState) => !previousState)
        }
        confirmAction={() => {
          cancelDeposit(singleData.idBankDeposit);
        }}
      />
    </div>
  );
};

ClientDeposit.propTypes = {};

export default ClientDeposit;
