import React from "react";
import DgContainer from "./DgContainer";
import Button from "./Button";

function EmptyCard({ containerStyle, children, vertical }) {
  return (
    <DgContainer
      style={{
        background: "#FFFFFF",
        display: "flex",
        flexDirection: vertical ? "column" : "row",
        ...containerStyle,
      }}
    >
      <div
        style={{
          flex: 1,
          background: "rgb(42, 47, 153)",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: vertical && "20px",
          borderBottomLeftRadius: !vertical && "20px",
        }}
      />
      {children}
    </DgContainer>
  );
}

export default EmptyCard;
