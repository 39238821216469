import React from "react";
import DgModal from "../../components/DgModal";
import DgTable from "../../components/DgTable";
import { getInvoicingHistoryColumns } from "../../helpers/datatableHelpers";
import { invoicingHistoryFiltersSchema } from "../../helpers/schemas";
import DgInput from "../../components/DgInput";
import { useForm } from "../../helpers/useForm";
import { useEffect } from "react";
import { Request } from "../../helpers/api";
import { useState } from "react";
import { useSelector } from "react-redux";
import ModalCancel from "./modals/ModalCancel";
import { handleCancelCfdi } from "./handlers";
import ModalDetails from "./modals/ModalDetails";
import {
  downloadBlobFile,
  handleDownloadClick,
  parseObjectDate,
} from "../../helpers/utils";
import Swal from "sweetalert2";
import DgLoader from "../../components/DgLoader";
import Button from "../../components/Button";
import useGetFile from "../../helpers/useGetFile";
import { Tooltip } from "@mui/material";
import { Download } from "lucide-react";
import ModalSendEmail from "./modals/ModalSendEmail";

function ModalInvoicingHistory({ isOpen, toggle }) {
  const [downloadFile, isLoading] = useGetFile(
    "/invoice/cancellationAccusation?invoiceId="
  );
  const [downloadHistory, historyLoading] = useGetFile(
    "/report/cfdisProvider?status=all"
  );
  const { CatCfdiType } = useSelector((state) => state.catalogues);
  const [filters, onDataChange, resetFilters] = useForm({
    client: "",
    catCfdiType: "",
    isActive: "",
    initialDate: "",
    endDate: "",
    internalFolio: "",
    amount: "",
  });
  const [modalSendEmail, setModalSendEmail] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [CFDIDetailsModalOpen, setCFDIDetailsModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [clients, setClients] = useState({
    clients: [],
  });
  const reset = () => {
    resetFilters();
    setSingleData({});
  };

  const fetchAndSetClients = async () => {
    const { ok, data: clients } = await Request("/client/");
    if (ok) {
      setClients((previousData) => {
        return { ...previousData, clients };
      });
    }
  };
  const [loading, setLoading] = useState(false);
  const fetchInvoicingHistoryTableData = async (filters) => {
    const {
      client,
      catCfdiType,
      isActive,
      initialDate,
      endDate,
      internalFolio,
      amount,
    } = filters;
    if (typeof filters.client == "number" || !filters.client) {
      const { ok, data } = await Request(
        `/invoice?client=${client}&catCfdiType=${catCfdiType}&isActive=${isActive}&initialDate=${initialDate}&endDate=${endDate}&internalFolio=${internalFolio}&amount=${amount}`
      );
      if (ok) {
        setHistoryData(data);
      }
    }
  };

  const funcs = {
    cancelGeneralInvoice: (row) => {
      setSingleData(row);
      setCancelModalOpen(true);
    },
    viewCFDI: (row) => {
      setSingleData(row);
      setCFDIDetailsModalOpen(true);
    },
    downloadCancelledInvoice: async (id) => {
      downloadFile(id);
    },
    removeCancellation: async (id) => {
      setLoading(true);
      const res = await Request(
        `/invoice/statusCfdiProvider/${id}`,
        {},
        "PATCH"
      );
      setLoading(false);

      if (res.ok) {
        fetchInvoicingHistoryTableData(filters);
        Swal.fire("Exito", res.data, "success");
      } else {
        Swal.fire("Error", res.error, "error");
      }
    },

    sendEmail: (row) => {
      setSingleData(row);
      setModalSendEmail((prevValue) => !prevValue);
    },
  };

  const downloadInvoiceHistory = async () => {
    setLoading(true);
    const res = await Request(
      "/report/cfdisProvider?status=all",
      null,
      "GET",
      true,
      false,
      true
    );
    setLoading(false);
    if (res) {
      setLoading(true);
      downloadBlobFile(res, `Historial de facturama.xlsx`);
      setLoading(false);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  const downloadExcelReport = async (filters) => {
    const {
      client,
      catCfdiType,
      isActive,
      initialDate,
      endDate,
      internalFolio,
      amount,
    } = filters;
    setLoading(true);
    const res = await Request(
      `/report/invoiceHistory?client=${client}&catCfdiType=${catCfdiType}&isActive=${isActive}&initialDate=${initialDate}&endDate=${endDate}&internalFolio=${internalFolio}&amount=${amount}`,
      null,
      "GET",
      true,
      false,
      true
    );
    setLoading(false);
    if (res) {
      setLoading(true);
      downloadBlobFile(
        res,
        `Reporte facturación - ${parseObjectDate(new Date())}.xlsx`
      );
      setLoading(false);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  useEffect(() => {
    fetchInvoicingHistoryTableData(filters);
    fetchAndSetClients();
    reset();
  }, [isOpen]);

  return (
    <DgModal
      title={"Historial de facturación"}
      withClose={true}
      withSend={{
        title: "Descargar reporte",
        action: () => downloadExcelReport(filters),
      }}
      open={isOpen}
      toggle={toggle}
      style={{ width: "98%", maxHeight: "98%", overflowX: "auto" }}
    >
      <div
        style={{
          display: "flex",
          gap: 10,
          width: "100%",
          padding: "0px 12px",
          alignItems: "end",
        }}
      >
        {invoicingHistoryFiltersSchema(
          {
            ...clients,
            cfdiTypes: CatCfdiType,
          },
          {
            onChange: onDataChange,
          }
        ).map((inputSchema) => (
          <DgInput {...inputSchema} />
        ))}
        <Button
          onClick={() => downloadInvoiceHistory()}
          style={{ margin: 0, margin: "8px 0px" }}
          containerStyle={{ width: "fit-content", margin: 0 }}
        >
          <Tooltip title="Descargar historial de facturama">
            <Download />
          </Tooltip>
        </Button>
        <Button
          onClick={() => {
            fetchInvoicingHistoryTableData(filters);
          }}
          containerStyle={{ width: "fit-content", margin: 0 }}
          style={{ margin: 0, margin: "8px 0px" }}
        >
          Filtrar
        </Button>
      </div>
      <div className="overflow-scroll max-w-full">
        <DgTable
          rootContainerStyle={{
            padding: "0px 12px",
            borderRadius: "20px 20px 0px 0px",
            minWidth: 2000,
          }}
          data={historyData}
          columnsDef={getInvoicingHistoryColumns(funcs)}
        />
      </div>
      <ModalCancel
        isOpen={cancelModalOpen}
        toggle={() => setCancelModalOpen((prevState) => !prevState)}
        data={singleData}
        showForm={false}
        onConfirm={(epBody) =>
          handleCancelCfdi(
            singleData,
            {
              epBody,
              endPoint: "/invoice/generalinvoice/serviceDetailCfdi/",
              cfdiId: "idServiceDetailCfdi",
              setLoading: setLoading,
            },
            () => {
              fetchInvoicingHistoryTableData(filters);
            },
            {
              text: "Cancelación de factura general",
              onSuccessMessage: "Factura cancelada con éxito!",
            }
          )
        }
        filterCancelMethodBy={"applyGeneralInvoice"}
        loading={loading}
      />
      <ModalDetails
        isOpen={CFDIDetailsModalOpen}
        toggle={() => setCFDIDetailsModalOpen((prevState) => !prevState)}
        data={singleData}
      />
      <DgLoader open={loading || isLoading} />
      <ModalSendEmail
        toggle={() => setModalSendEmail((modalSendEmail) => !modalSendEmail)}
        isOpen={modalSendEmail}
        endPoint={`/client/mailBill`}
        idService={singleData?.idDetailService}
        internalFolio={singleData?.internalFolio}
        cfdiType={singleData?.cfdiType}
      />
    </DgModal>
  );
}

export default ModalInvoicingHistory;
