import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DgButton from "../../../components/Button";

const ModalDocumentPersonDetail = ({ isOpen, toggle = {}, data = {} }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className="text-center justify-center">
        <div className="text-xl text-sesGray ml-2">DOCUMENTO</div>
      </ModalHeader>
      <ModalBody style={{ padding: "1.5rem" }}>
        <embed
          className="w-full m-auto"
          height={500}
          src={data.url}
          type="application/pdf"
        />
      </ModalBody>
      <ModalFooter>
        <div className="w-full grid grid-cols-2">
          <div />
          <DgButton
            onClick={toggle}
            innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
          >
            Aceptar
          </DgButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalDocumentPersonDetail.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalDocumentPersonDetail;
