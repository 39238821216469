import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../components/ButtonIcon";
import {
  clientTypes,
  enumClientTypes,
  enumDepositMethods,
  enumPaymentMethods,
  rolesCat,
} from "./enums";
import municipalitiesCat from "./statesMunicipalities.json";
import {
  formatDate,
  formatThousands,
  handleNestedItems,
  parseCat,
  parseObjectDate,
  unformatThousands,
} from "./utils";
import { conceptSchema, subClientSchema } from "./schemas";

export const invoiceInputs = (data, handlers, catalogues, props) => {
  const isFiscal = data.clientType === "FISCAL";
  const { onInputChange = null } = handlers;
  const { CatCfdi, CatPaymentWay, CatRegime } = catalogues;
  return [
    {
      label: "Uso de CFDI",
      type: "select",
      name: "idCatCfdi",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatCfdi, "name", "idCatCfdi"),
      ],
      iconName: "FormInput",
      ...props,
    },
    {
      label: "Forma de pago",
      type: "select",
      name: "idCatPaymentWay",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatPaymentWay, "name", "idCatPaymentWay"),
      ],
      iconName: "FormInput",
      ...props,
    },
    {
      label: "Método de pago",
      type: "select",
      name: "paymentMethod",

      onInputChange,
      disabled: !isFiscal,
      required: isFiscal,
      options: isFiscal
        ? [
          { value: "", label: "Selecciona un elemento" },
          ...enumPaymentMethods,
        ]
        : [{ value: "PUE", label: "PUE" }],
      iconName: "FormInput",
      ...props,
    },
    {
      label: "Régimen",
      type: "select",
      name: "idCatRegime",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatRegime, "name", "idCatRegime"),
      ],
      iconName: "FormInput",
      ...props,
    },
  ];
};

export const subClientInputs = (
  nestedData,
  handlers,
  catalogues,
  props,
  invoiced
) => {
  const { onInputChange = null } = handlers;
  const { CatCfdi, CatPaymentWay, CatRegime } = catalogues;
  const isFiscal = !nestedData.clientTypeDetailService
    ? true
    : nestedData.clientTypeDetailService === "FISCAL";
  return [
    {
      label: "Uso de CFDI",
      type: "select",
      name: "idCatCfdi",
      onInputChange,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatCfdi, "name", "idCatCfdi"),
      ],
      value: nestedData.idCatCfdi,
      iconName: "FormInput",
      ...props,
      disabled: props.disabled || nestedData.applyClientFiscalData,
    },
    {
      label: "Forma de pago",
      type: "select",
      name: "idCatPaymentWay",
      value: nestedData.idCatPaymentWay,
      onInputChange,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatPaymentWay, "name", "idCatPaymentWay"),
      ],
      iconName: "FormInput",
      disabled: false,

      ...props,
    },
    {
      label: "Método de pago",
      type: "select",
      name: "paymentMethod",
      value: nestedData.paymentMethod,
      onInputChange,
      options: isFiscal ? enumPaymentMethods : [{ label: "PUE", value: "PUE" }],
      iconName: "FormInput",

      disabled: false,
      ...props,
    },
    {
      label: "Régimen",
      type: "select",
      name: "idCatRegime",
      value: nestedData.idCatRegime,
      onInputChange,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatRegime, "name", "idCatRegime"),
      ],
      iconName: "FormInput",
      ...props,
      disabled: props.disabled || nestedData.applyClientFiscalData,
    },
    {
      label: "Tipo",
      type: "select",
      name: "clientTypeDetailService",
      value: nestedData.clientTypeDetailService,
      onInputChange: ({ target: { value, name } }) => {
        onInputChange({
          target: {
            name: "paymentMethod",
            value: value === "FISCAL" ? nestedData.paymentMethod : "PUE",
          },
        });
        onInputChange({
          target: { name, value },
        });
      },
      options: enumClientTypes,
      iconName: "FormInput",
      disabled: invoiced,
      ...props,
      disabled: props.disabled || nestedData.applyClientFiscalData,
    },
  ];
};

export const addressInputs = (data = null, suburbs = [], fieldPostfix = "") => {
  const isFiscal = data.clientType === "FISCAL";
  const municipalities = (
    municipalitiesCat[data["state" + fieldPostfix]] || []
  ).map((m) => ({
    value: m,
    label: m,
  }));

  return [
    {
      label: "Calle",
      placeholder: "Av General",
      type: "text",
      name: "street" + fieldPostfix,
      maxLength: "150",
      minLength: "1",
      required: isFiscal,
      iconName: "FormInput",
    },
    {
      label: "Código postal",
      placeholder: "00000",
      type: "text",
      maxLength: 6,
      minLength: 5,
      pattern: "^[0-9]{5-6}",
      name: "postalCode" + fieldPostfix,
      required: isFiscal,
      iconName: "FormInput",
    },
    {
      label: "Núm Ext",
      placeholder: "123",
      type: "text",
      name: "outdoorNumber" + fieldPostfix,
      maxLength: "20",
      minLength: "1",
      required: isFiscal,
      iconName: "FormInput",
    },
    {
      label: "Núm Int",
      placeholder: "",
      type: "text",
      name: "internalNumber" + fieldPostfix,
      maxLength: "20",
      minLength: "1",
      required: false,
      iconName: "FormInput",
    },
    {
      label: "Colonia",
      placeholder: "Ingrese una colonia",
      type: "dataList",
      name: "suburb" + fieldPostfix,
      options: suburbs || [],
      required: isFiscal,
      disabled: data.postalCode === "",
      value: data["suburb" + fieldPostfix],
      iconName: "Map",
    },
    {
      label: "Municipio",
      placeholder: "Ingrese un municipio",
      type: "dataList",
      value: data["municipality" + fieldPostfix],
      name: "municipality" + fieldPostfix,
      options: municipalities,
      required: isFiscal,
      disabled: data.postalCode === "",
      iconName: "Map",
    },
    {
      label: "Estado",
      placeholder: "-",
      type: "text",
      name: "state" + fieldPostfix,
      required: isFiscal,
      disabled: suburbs?.length,
      iconName: "FormInput",
    },
    {
      label: "País",
      type: "select",
      options: [
        {
          label: "México",
          value: "MX",
        },
      ],
      value: "MX",
      name: "country" + fieldPostfix,
      required: isFiscal,
      disabled: true,
      iconName: "FormInput",
    },
  ];
};

export const clientInputs = (data, handlers, catalogues) => {
  const { handlePhoneChange, onInputChange = null, onDataChange } = handlers;
  const { suburbs } = catalogues;
  const isFiscal = data.clientType === "FISCAL";
  return [
    {
      title: "DATOS DE CLIENTE",
      fields: [
        {
          type: "radio",
          name: "clientType",
          options: [
            { value: "FISCAL", label: "Cliente fiscal" },
            { value: "NONFISCAL", label: "Cliente no fiscal" },
          ],
          value: data.clientType,
          containerClassName: "col-span-2",
        },
        {
          label: "Razón social",
          placeholder: "Seguritas S.A. de C.V",
          type: "text",
          name: "name",
          minLength: "2",
          required: isFiscal,
          iconName: "FormInput",
          errorMessage: "Ésta razón social no es válida",
        },
        {
          label: "RFC",
          placeholder: "SRTAS2S1S",
          type: "text",
          maxLength: 13,
          minLength: 12,
          name: "rfc",
          onInputChange: ({ target: { name, value } }) => {
            onDataChange({ target: { name, value: value.replace(" ", "") } });
          },
          required: isFiscal,
          iconName: "FormInput",
        },
        {
          label: "Teléfono",
          placeholder: "XX-XXXX-XXXX",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          minLength: "12",
          maxLength: "12",
          type: "tel",
          name: "phone",
          onInputChange: (e) => {
            handlePhoneChange(e, onDataChange);
          },
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato 55-5555-5555",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "email",
          required: false,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DATOS DEL ADMINISTRADOR",
      fields: [
        {
          label: "Nombre",
          placeholder: "Juan Pérez",
          type: "text",
          name: "administratorName",
          onInputChange,
          maxLength: "150",
          minLength: "2",
          //required: true,
          iconName: "FormInput",
        },
        {
          label: "Teléfono",
          placeholder: "XX-XXXX-XXXX",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          minLength: "12",
          maxLength: "12",
          type: "tel",
          name: "administratorPhone",
          onInputChange: (e) => {
            handlePhoneChange(e, onDataChange);
          },
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato 55-5555-5555",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          onInputChange,
          name: "administratorEmail",
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DATOS DEL CONTADOR O RESPONSABLE DE PAGO",
      fields: [
        {
          label: "Nombre",
          placeholder: "Juan Pérez",
          type: "text",
          name: "counterName",
          onInputChange,
          maxLength: "150",
          minLength: "2",
          //required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico SES",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "counterEmail",
          onInputChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DOMICILIO FISCAL",
      fields: addressInputs(data, suburbs),
    },
    {
      title: "FACTURACIÓN",
      fields: invoiceInputs(data, handlers, catalogues),
    },
  ];
};

export const userInputs = (data, handlers, catalogues) => {
  const { CatRole } = catalogues;
  const { onPasswordConfirmChange, onSelectFile, onRoleChange } = handlers;
  return [
    {
      title: "DATOS DEL USUARIO",
      fields: [
        {
          label: "Imagen de Perfil",
          type: "file",
          name: "selectedFile",
          onInputChange: onSelectFile,
          value: data.selectedFile,
          required: true,
          accept: "image/png, image/gif, image/jpeg",
          containerClassName: "col-span-2",
        },
        {
          label: "Nombre",
          placeholder: "Nombre",
          type: "text",
          name: "name",
          minLength: "10",
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Rol",
          type: "select",
          name: "idCatRole",
          options: [
            { label: "Selecciona un rol", value: "" },
            ...parseCat(CatRole, "name", "idCatRole"),
          ],
          onInputChange: onRoleChange,
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "email",
          required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
        {
          label: "Contraseña",
          placeholder: "Contraseña",
          type: "password",
          name: "password",
          pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,12}$",
          required: true,
          maxLength: 12,
          minLength: 6,
          iconName: "FormInput",
        },
        {
          label: "Confirmar contraseña",
          placeholder: "Confirmar contraseña",
          type: "password",
          name: "passwordConfirm",
          onInputChange: onPasswordConfirmChange,
          required: true,
          iconName: "FormInput",
          errorMessage: "Las contraseñas no coinciden",
        },
      ],
    },
  ];
};

export const editUserInputs = (data, handlers, catalogues) => {
  const onInputChange = handlers;
  return [
    {
      fields: [
        {
          label: "Nombre",
          placeholder: "Nombre",
          type: "text",
          name: "userName",
          onInputChange,
          minLength: "10",
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Rol",
          type: "select",
          name: "userRole",
          onInputChange,
          options: [
            { label: "Selecciona un rol", value: 1 },
            { label: "Cobranza y CRM", value: 2 },
            { label: "Facturación", value: 3 },
            { label: "RH y Nómina", value: 4 },
            { label: "Administración", value: 5 },
            { label: "Personalizado", value: 6 },
          ],
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "userEmail",
          onInputChange,
          required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
        {
          label: "Contraseña",
          placeholder: "Contraseña",
          type: "password",
          name: "userPassword",
          onInputChange,
          required: true,
          iconName: "FormInput",
        },
      ],
    },
  ];
};

export const personInputsGeneral = (data, handlers, catalogues) => {
  const { handlePhoneChange, onDataChange } = handlers;

  return [
    {
      title: "",
      fields: [
        {
          label: "Nombre(s)",
          placeholder: "Mateo",
          type: "text",
          name: "name",
          minLength: "2",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido Paterno",
          placeholder: "Villamil",
          type: "text",
          name: "paternalLastName",
          minLength: "2",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido Materno",
          placeholder: "Flores",
          type: "text",
          name: "maternalLastName",
          minLength: "2",
          required: true,
          iconName: "User",
        },
        {
          label: "Clave de empleado",
          placeholder: "0021193",
          type: "text",
          name: "employeeKey",
          minLength: "2",
          required: true,
          iconName: "CreditCard",
        },
        {
          label: "Fecha de nacimiento",
          placeholder: "25/07/1978",
          type: "date",
          name: "birthDate",
          required: true,
          iconName: "Calendar",
        },
        {
          label: "Sexo",
          type: "select",
          name: "gender",
          options: [
            { label: "Selecciona el género", value: "" },
            { label: "Masculino", value: "MALE" },
            { label: "Femenino", value: "FEMALE" },
            { label: "Otro", value: "OTHER" },
          ],
          required: true,
          iconName: "User",
        },
        {
          label: "Teléfono",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          placeholder: "55-0000-0000",
          type: "tel",
          name: "phone",
          onInputChange: (e) => {
            handlePhoneChange(e, onDataChange);
          },
          minLength: "12",
          maxLength: "12",
          required: true,
          iconName: "Phone",
        },
        {
          label: "Celular o teléfono para recados",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          placeholder: "55-0000-0000",
          type: "tel",
          name: "secondaryPhone",
          onInputChange: (e) => {
            handlePhoneChange(e, onDataChange);
          },
          minLength: "12",
          maxLength: "12",
          required: false,
          iconName: "Phone",
        },
        {
          label: "Rol de empleado",
          type: "select",
          name: "role",
          options: [{ label: "Selecciona el rol", value: "" }, ...rolesCat],
          required: true,
          iconName: "User",
        },
        {
          label: "Fecha de absorción",
          placeholder: "25/07/1978",
          type: "date",
          name: "absorptionDate",
          required: false,
          iconName: "Calendar",
        },
      ],
    },
  ];
};

export const personInputsSecondary = (data, handlers, catalogues) => {
  const { CatMaritalStatus, CatEducationalLevel } = catalogues;

  return [
    {
      title: "",
      fields: [
        {
          label: "Lugar de nacimiento",
          placeholder: "Ameca",
          type: "text",
          name: "birthPlace",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Peso (kg)",
          placeholder: "82",
          type: "number",
          step: 0.1,
          name: "weight",
          min: 0.1,
          max: 250,
          required: true,
          iconName: "User",
        },
        {
          label: "Estatura (cm)",
          placeholder: "179",
          type: "number",
          name: "height",
          step: 1,
          min: "1",
          max: 250,
          required: true,
          iconName: "User",
        },
        {
          label: "Escolaridad",
          type: "select",
          name: "idCatEducationalLevel",
          options: [
            { label: "Elige un valor", value: "" },
            ...parseCat(CatEducationalLevel, "name", "idCatEducationalLevel"),
          ],
          required: true,
          iconName: "User",
        },
        {
          label: "Estado civil",
          type: "select",
          name: "idCatMaritalStatus",
          options: [
            { label: "Elige un valor", value: "" },
            ...parseCat(CatMaritalStatus, "name", "idCatMaritalStatus"),
          ],
          required: true,
          iconName: "User",
        },
        {
          label: "Fecha de anti-doping",
          placeholder: "25/09/2022",
          type: "date",
          name: "antidopingDate",
          required: true,
          iconName: "Calendar",
        },
        {
          label: "Fecha de ingreso",
          placeholder: "25/09/2022",
          type: "date",
          name: "admissionDate",
          required: true,
          iconName: "Calendar",
        },
      ],
    },
  ];
};

export const personInputsAddress = (data, handlers, catalogues) => {
  return [
    {
      title: "",
      fields: [
        {
          label: "Calle",
          placeholder: "Av General",
          type: "text",
          name: "street",
          maxLength: "150",
          minLength: "1",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Código postal",
          placeholder: "00000",
          type: "text",
          maxLength: 6,
          minLength: 5,
          pattern: "^[0-9]{5-6}",
          name: "postalCode",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Núm Ext",
          placeholder: "123",
          type: "text",
          name: "outdoorNumber",
          maxLength: "20",
          minLength: "1",
          iconName: "MapPin",
        },
        {
          label: "Núm Int",
          placeholder: "2-A",
          type: "text",
          name: "internalNumber",
          maxLength: "20",
          minLength: "1",
          required: false,
          iconName: "MapPin",
        },
        {
          label: "Colonia",
          placeholder: "Álamos",
          type: "text",
          name: "suburb",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Municipio o Delegación",
          placeholder: "Elige un municipio",
          type: "text", //LUEGO PASA A SELECT, SOLUCIÓN PROVISORIA
          name: "municipality",
          options: [{ label: "Elige un municipio", value: "Ameca" }],
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Estado",
          placeholder: "Elige un Estado",
          type: "text", //LUEGO PASA A SELECT, SOLUCIÓN PROVISORIA
          name: "state",
          options: [{ label: "Elige un Estado", value: "Jalisco" }],
          required: true,
          iconName: "MapPin",
        },
      ],
    },
  ];
};

export const personInputsDocuments = (data, handlers, catalogues) => {
  const { bank } = catalogues;
  return [
    {
      title: "",
      fields: [
        {
          label: "RFC",
          placeholder: "VIFM780725JK5",
          type: "text",
          name: "rfc",
          minLength: 10,
          maxLength: 13,
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "CURP",
          placeholder: "VIFM39388WKE91N01",
          type: "text",
          name: "curp",
          minLength: 16,
          maxLength: 18,
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Número de IMSS",
          placeholder: "928100930019",
          type: "text",
          name: "imssNumber",
          minLength: 10,
          maxLength: 11,
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Actualmente en observación de IMSS",
          type: "select",
          name: "imssComments",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "No", value: "No" },
            { label: "Sí", value: "Si" },
          ],
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "INFONAVIT",
          type: "select",
          name: "hasInfonavit",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "No", value: 1 },
            { label: "Sí", value: 2 },
          ],
          required: true,
          iconName: "CreditCard",
        },
        {
          label: "Pensión alimenticia",
          type: "select",
          name: "givesAlimony",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "No", value: 1 },
            { label: "Sí", value: 2 },
          ],
          required: true,
          iconName: "CreditCard",
        },
        {
          label: "Número de Servicio Militar",
          placeholder: "B-83910039",
          type: "text",
          name: "militarService",
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Número de Credencial de Elector",
          placeholder: "41393020019",
          type: "text",
          name: "votingLisenseNumber",
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Tipo de comprobante de domicilio",
          type: "select",
          name: "idCatProofResidence",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "Agua", value: 1 },
            { label: "Luz", value: 2 },
            { label: "Gas", value: 3 },
            { label: "Otro", value: 4 },
          ],
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Institución bancaria",
          type: "select",
          name: "idCatBank",
          options: [
            { label: "Seleccione institución bancaria", value: 1 },
            ...parseCat(bank, "name", "idCatBank"),
          ],
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Número de tarjeta de nómina",
          placeholder: "0000 0000 0000 0000",
          type: "text",
          name: "cardNumber",
          required: false,
          minLength: 16,
          maxLength: 16,
          iconName: "CreditCard",
        },
        {
          label: "CLABE interbancaria",
          placeholder: "0000 0000 0000 000000",
          type: "text",
          name: "interbankKey",
          required: false,
          minLength: 18,
          maxLength: 18,
          iconName: "CreditCard",
        },
        {
          label: "Entregó expediente físico",
          type: "select",
          name: "file",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "Sí", value: true },
            { label: "No", value: false },
          ],
          required: false,
          iconName: "FileCheck",
        },
        {
          label: "Sueldo neto quincenal",
          type: "number",
          name: "biweeklyNetSalary",
          min: 1,
          step: 1,
          required: true,
          iconName: "CreditCard",
        },
      ],
    },
  ];
};

export const invoiceEventSchema = (concepts) => [
  {
    label: "Concepto de factura",
    placeholder: "Concepto de factura",
    type: "select",
    options: [
      { value: "", label: "Elige un concepto" },
      ...parseCat(concepts.catConcepts, "name", "idCatServiceConcept"),
    ],
    name: "idCatServiceConcept",
    required: true,
    iconName: "Files",
    containerStyle: { width: 300 },

    errorMessage: "Éste concepto de factura no es válido",
  },
  {
    label: "Cantidad",
    placeholder: "2",
    minLength: "1",
    maxLength: "2",
    min: "1",
    containerStyle: { width: 280 },
    errorMessage: "Valor requerido y no puede ser menor a 1",
    type: "number",
    name: "quantity",
    required: true,
    iconName: "Hash",
  },
  {
    label: "Precio base",
    type: "number",
    name: "price",
    containerStyle: { width: 280 },
    step: 0.1,
    min: 0.1,
    required: true,
    //  value: sc.price,
    //   onInputChange: onNestedChange(sIx, ix),
    iconName: "DollarSign",
    errorMessage: "Valor requerido y no puede ser menor a 0.1",
  },
  {
    label: "Observaciones",
    type: "text",
    disabled: false,
    name: "description",
    containerStyle: { width: 280 },
    required: false,
    //   onInputChange: onNestedChange(sIx, ix),
    //   value: sc.description,
    iconName: "FormInput",
  },
];
export const serviceConceptsSchema = {
  description: "",
  idCatServiceConcept: "",
  quantity: "",
  price: "",
};

export const personInputsEmergencyContact = (data, handlers, catalogues) => {
  const { handlePhoneChange } = handlers;
  return [
    {
      title: "",
      fields: [
        {
          label: "Nombre del contacto",
          placeholder: "Brandon",
          type: "text",
          name: "name",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido paterno del contacto",
          placeholder: "Flores",
          type: "text",
          name: "paternalLastName",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido materno del contacto",
          placeholder: "Martínez",
          type: "text",
          name: "maternalLastName",
          required: true,
          iconName: "User",
        },
        {
          label: "Teléfono del contacto",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          placeholder: "55-0000-0000",
          type: "tel",
          name: "phone",
          onInputChange: handlePhoneChange,
          minLength: "12",
          maxLength: "12",
          required: true,
          iconName: "Phone",
        },
        {
          label: "Parentesco",
          type: "select",
          name: "relationship",
          options: [
            { label: "Selecciona parentesco", value: "" },
            { label: "Madre", value: "Madre" },
            { label: "Padre", value: "Padre" },
            { label: "Cónyuge", value: "Cónyuge" },
            { label: "Hijo", value: "Hijo" },
            { label: "Hija", value: "Hija" },
            { label: "Otro", value: "Otro" },
          ],
          required: true,
          iconName: "User",
        },
      ],
    },
  ];
};

export const personInputsGeneralReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "Nombres",
        placeholder: "Mateo",
        type: "text",
        name: "personNames",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Apellido Paterno",
        placeholder: "Villamil",
        type: "text",
        name: "fatherLastName",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Apellido Materno",
        placeholder: "Flores",
        type: "text",
        name: "motherLastName",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Clave de empleado",
        placeholder: "0021193",
        type: "text",
        name: "employeeNumber",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Fecha de nacimiento",
        placeholder: "25/07/1978",
        type: "date",
        name: "dateOfBirth",
        onInputChange,
        required: true,
        iconName: "Calendar",
        disabled: "true",
      },
      {
        label: "Sexo",
        type: "select",
        name: "gender",
        onInputChange,
        options: [
          { label: "Masculino", value: 1 },
          { label: "Femenino", value: 2 },
          { label: "Otro", value: 3 },
        ],
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Teléfono",
        pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
        placeholder: "55-0000-0000",
        type: "tel",
        name: "phoneNumber",
        onInputChange,
        minLength: "12",
        maxLength: "12",
        required: true,
        iconName: "Phone",
        disabled: "true",
      },
      {
        label: "Celular o teléfono para recados",
        pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
        placeholder: "55-0000-0000",
        type: "tel",
        name: "secondPhoneNumber",
        onInputChange,
        minLength: "12",
        maxLength: "12",
        required: true,
        iconName: "Phone",
        disabled: "true",
      },
    ],
  };
};

export const personInputsSecondaryReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "Edad",
        placeholder: "44",
        type: "number",
        name: "age",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Lugar de nacimiento",
        placeholder: "Ameca",
        type: "text",
        name: "placeOfBirth",
        onInputChange,
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Peso (kg)",
        placeholder: "82",
        type: "number",
        name: "weight",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Estatura (cm)",
        placeholder: "179",
        type: "number",
        name: "height",
        onInputChange,
        minLength: "3",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Escolaridad",
        type: "select",
        name: "education",
        onInputChange,
        options: [
          { label: "Primaria", value: 1 },
          { label: "Secundaria", value: 2 },
          { label: "Bachillerato", value: 3 },
          { label: "Técnico", value: 4 },
          { label: "Superior", value: 5 },
          { label: "Otro", value: 6 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Estado civil",
        type: "select",
        name: "maritalStatus",
        onInputChange,
        options: [
          { label: "Soltero", value: 1 },
          { label: "Casado", value: 2 },
          { label: "Divorciado", value: 3 },
          { label: "Viudo", value: 4 },
          { label: "Otro", value: 5 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Fecha de anti-doping",
        placeholder: "25/09/2022",
        type: "date",
        name: "antidopingTestDate",
        onInputChange,
        required: true,
        iconName: "Calendar",
        disabled: "true",
      },
    ],
  };
};

export const personInputsAddressReadOnly = (data, handlers, catalogues) => {
  return {
    title: "",
    fields: [
      {
        label: "Calle",
        placeholder: "Av General",
        type: "text",
        name: "street",
        maxLength: "150",
        minLength: "1",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Código postal",
        placeholder: "00000",
        type: "text",
        maxLength: 6,
        minLength: 5,
        pattern: "^[0-9]{5-6}",
        name: "postalCode",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Núm Ext",
        placeholder: "123",
        type: "text",
        name: "outdoorNumber",
        maxLength: "20",
        minLength: "1",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Núm Int",
        placeholder: "2-A",
        type: "text",
        name: "internalNumber",
        maxLength: "20",
        minLength: "1",
        required: false,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Colonia",
        placeholder: "Álamos",
        type: "text",
        name: "suburb",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Municipio o Delegación",
        placeholder: "Elige un municipio",
        type: "select",
        name: "municipality",
        options: [{ label: "Elige un municipio", value: "Ameca" }],
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Estado",
        placeholder: "Elige un Estado",
        type: "select",
        name: "state",
        options: [{ label: "Elige un Estado", value: "Jalisco" }],
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
    ],
  };
};

export const personInputsDocumentsReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "RFC",
        placeholder: "VIFM780725JK5",
        type: "text",
        name: "rfc",
        onInputChange,
        minLength: "10",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "CURP",
        placeholder: "VIFM39388WKE91N01",
        type: "text",
        name: "curp",
        onInputChange,
        minLength: "16",
        maxLength: "16",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de IMSS",
        placeholder: "928100930019",
        type: "text",
        name: "imss",
        onInputChange,
        minLength: "12",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Actualmente en observación de IMSS",
        type: "select",
        name: "medicalObservation",
        onInputChange,
        options: [
          { label: "No", value: 1 },
          { label: "Sí", value: 2 },
        ],
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "INFONAVIT",
        type: "select",
        name: "infonavit",
        onInputChange,
        options: [
          { label: "No", value: 1 },
          { label: "Sí", value: 2 },
        ],
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Pensión alimenticia",
        type: "select",
        name: "alimony",
        onInputChange,
        options: [
          { label: "No", value: 1 },
          { label: "Sí", value: 2 },
        ],
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de Servicio Militar",
        placeholder: "B-83910039",
        type: "text",
        name: "militarService",
        onInputChange,
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de Credencial de Elector",
        placeholder: "41393020019",
        type: "text",
        name: "officialIdentification",
        onInputChange,
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Tipo de comprobante de domicilio",
        type: "select",
        name: "proofOfResidency",
        onInputChange,
        options: [
          { label: "Agua", value: 1 },
          { label: "Luz", value: 2 },
          { label: "Gas", value: 3 },
          { label: "Otro", value: 4 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Institución bancaria",
        type: "select",
        name: "bank",
        onInputChange,
        options: [
          { label: "Seleccione institución bancaria", value: 1 },
          { label: "BBVA", value: 2 },
          { label: "Banbajío", value: 3 },
          { label: "Citibanamex", value: 4 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de tarjeta de nómina",
        placeholder: "0000 0000 0000 0000",
        type: "text",
        name: "cardNumter",
        onInputChange,
        required: true,
        minLength: 16,
        maxLength: 16,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "CLABE interbancaria",
        placeholder: "0000 0000 0000 000000",
        type: "text",
        name: "clabe",
        onInputChange,
        required: true,
        minLength: 18,
        maxLength: 18,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Sueldo neto quincenal",
        placeholder: "5000",
        type: "number",
        name: "netIncome",
        onInputChange,
        required: true,
        minLength: 4,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Entregó expediente físico",
        type: "select",
        name: "file",
        onInputChange,
        options: [
          { label: "Sí", value: 1 },
          { label: "No", value: 2 },
        ],
        required: true,
        iconName: "FileCheck",
        disabled: "true",
      },
    ],
  };
};

export const conceptsSchema = (
  data,
  conceptData,
  nestedIndex,
  handlers,
  catalogues
) => {
  const { onNestedChange, onDataChange } = handlers;
  const { catConcepts } = catalogues;
  return [
    {
      label: "Concepto de factura",
      placeholder: "Concepto de factura",
      type: "select",
      options: [
        { value: "", label: "Elige un concepto" },
        ...parseCat(catConcepts, "name", "idCatServiceConcept"),
      ],
      name: "idCatServiceConcept",
      onInputChange: onNestedChange,
      required: true,
      iconName: "Files",
      containerClassName: "mt-0",
      value: conceptData.idCatServiceConcept,
      errorMessage: "Éste concepto de factura no es válido",
    },
    {
      label: "Cantidad",
      placeholder: "2",
      minLength: "1",
      maxLength: "2",
      min: "1",
      type: "number",
      name: "quantity",
      onInputChange: onNestedChange,
      required: false,
      iconName: "Hash",
      containerClassName: "mt-0",
      value: conceptData.quantity,
      errorMessage: "Indique una cantidad en el rango 1 a 99",
    },
    {
      label: "Precio base individual",
      type: "number",
      name: "price",
      step: 0.01,
      min: "0.01",
      required: true,
      onInputChange: onNestedChange,
      value: conceptData.price,
      iconName: "DollarSign",
      containerClassName: "mt-0",
    },
    {
      label: "Descripción",
      type: "text",
      name: "description",
      required: false,
      onInputChange: onNestedChange,
      value: conceptData.description,
      iconName: "Outdent",
      containerClassName: "mt-0",
    },
    {
      element: (
        <ButtonIcon
          className="text-sesRed  px-1"
          onClick={() =>
            handleNestedItems(
              data,
              conceptSchema,
              { onDataChange },
              "serviceConcepts",
              true,
              nestedIndex
            )
          }
          icon={faMinusSquare}
        />
      ),
    },
  ];
};

export const editServiceConceptsSchema = (
  data,
  conceptData,
  nestedIndex,
  handlers,
  catalogues
) => {
  const { onNestedChange, onDataChange } = handlers;
  const { catConcepts } = catalogues;
  return [
    {
      label: "Concepto de factura",
      placeholder: "Concepto de factura",
      type: "select",
      options: [
        { value: "", label: "Elige un concepto" },
        ...parseCat(catConcepts, "name", "idCatServiceConcept"),
      ],
      name: "idCatServiceConcept",
      onInputChange: onNestedChange,
      required: true,
      iconName: "Files",
      containerClassName: "mt-0",
      value: conceptData.idCatServiceConcept,
      errorMessage: "Éste concepto de factura no es válido",
    },
    {
      label: "Cantidad",
      placeholder: "2",
      minLength: "1",
      maxLength: "2",
      min: "1",
      type: "number",
      name: "quantity",
      onInputChange: onNestedChange,
      required: false,
      iconName: "Hash",
      containerClassName: "mt-0",
      value: conceptData.quantity,
      errorMessage: "Indique una cantidad en el rango 1 a 99",
    },
    {
      label: "Precio base individual",
      type: "number",
      name: "price",
      step: 0.01,
      min: "0.01",
      required: true,
      onInputChange: onNestedChange,
      value: conceptData.price,
      iconName: "DollarSign",
      containerClassName: "mt-0",
    },
    {
      label: "Descuento por faltas",
      type: "number",
      name: "absenceDiscount",
      step: 0.01,
      min: 0,
      placeholder: "-",
      disabled: false,
      required: true,
      onInputChange: onNestedChange,
      value: conceptData.absenceDiscount,
      iconName: "UserMinus",
      containerClassName: "mt-0",
      defaultValue: 0,
    },
    {
      label: "Descripción",
      type: "text",
      name: "description",
      required: false,
      onInputChange: onNestedChange,
      value: conceptData.description,
      iconName: "Outdent",
      containerClassName: "mt-0",
    },
    {
      element: (
        <ButtonIcon
          className="text-sesRed  px-1"
          onClick={() =>
            handleNestedItems(
              data,
              conceptSchema,
              { onDataChange },
              "serviceConcepts",
              true,
              nestedIndex
            )
          }
          icon={faMinusSquare}
        />
      ),
    },
  ];
};

export const subclientsSchema = (
  data,
  subclientData,
  nestedIndex,
  handlers,
  catalogues,
  props,
  isServiceEdit
) => {

  const { onNestedChange, onDataChange } = handlers;
  const { CatRegime, CatCfdi, CatPaymentWay, suburbs } = catalogues;
  return [
    {
      label: "Nombre",
      placeholder: "Nombre de subcliente..",
      type: "text",
      name: "nameDetailService",
      required: true,
      iconName: "User",
      containerStyle: { margin: " 0px" },
      value: subclientData.nameDetailService,
      onInputChange: onNestedChange,
      errorMessage: "Éste nombre no es válido",
      ...props,
    },
    {
      label: "Razón social",
      placeholder: "DIGITIZING MÉXICO TECH",
      type: "text",
      name: "tradeNameDetailService",
      required:
        subclientData.clientTypeDetailService === "FISCAL" ? true : false,
      iconName: "ClipboardList",
      containerStyle: { margin: " 0px" },
      value: subclientData.tradeNameDetailService,
      onInputChange: onNestedChange,
      errorMessage: "La razón social es requerida",
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "RFC",
      type: "text",
      name: "rfc",
      required:
        subclientData.clientTypeDetailService === "FISCAL" ? true : false,
      value: subclientData.rfc,
      onInputChange: ({ target: { name, value } }) => {
        onNestedChange({ target: { name, value: value.replace(" ", "") } });
      },
      iconName: "Files",
      containerStyle: { margin: " 0px" },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Código postal",
      type: "text",
      name: "postalCode",
      required:
        subclientData.clientTypeDetailService === "FISCAL" ? true : false,
      value: subclientData.postalCode,
      onInputChange: onNestedChange,
      containerStyle: { margin: 0 },
      iconName: "DollarSign",

      errorMessage:
        "Datos geográficos no encontrados, ingrese la información manualmente",
      error: subclientData.error,
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Calle",
      type: "text",
      name: "street",
      required: false,
      value: subclientData.street,
      onInputChange: onNestedChange,
      iconName: "DollarSign",
      containerStyle: { margin: " 0px" },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Colonia",
      type: "dataList",
      name: "suburb",
      required: false,
      value: subclientData.suburb,
      onInputChange: onNestedChange,
      options: suburbs[nestedIndex + 1],
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Municipio",
      type: "dataList",
      name: "municipality",
      required: false,
      value: subclientData.municipality,
      options: (municipalitiesCat[subclientData.state] || []).map((m) => ({
        value: m,
        label: m,
      })),
      onInputChange: onNestedChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Número interno",
      type: "text",
      name: "internalNumber",
      required: false,
      value: subclientData.internalNumber,
      onInputChange: onNestedChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Número externo",
      type: "text",
      name: "outdoorNumber",
      required: false,
      value: subclientData.outdoorNumber,
      onInputChange: onNestedChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Estado",
      type: "text",
      name: "state",
      required: false,
      value: subclientData.state,
      onInputChange: onNestedChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
      ...props,
      disabled: props?.disabled || subclientData.applyClientFiscalData,
    },
    {
      label: "Monto asignado",
      type: "number",
      name: "amount",
      min: 0.001,
      step: 0.001,
      required: false,
      value: subclientData.amount,
      onInputChange: onNestedChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
      ...props,
    },
    ...subClientInputs(
      subclientData,
      { onInputChange: onNestedChange },
      { CatRegime, CatCfdi, CatPaymentWay },
      {
        containerStyle: { margin: 0 },
        disabled: [2, 3].includes(subclientData.idCatStatusInvoiceService),
      }
    ),
    {
      label: "Observaciones",
      type: "text",
      name: "description",
      required: false,
      value: subclientData.description,
      onInputChange: onNestedChange,
      iconName: "Book",
      containerStyle: { margin: 0 },
      ...props,
    },
    {
      label: "Heredar datos fiscales",
      type: "checkbox",
      name: "applyClientFiscalData",
      required: false,
      value: subclientData.applyClientFiscalData,
      onInputChange: onNestedChange,
      containerStyle: { margin: 0 },
      ...props,
    },
    {
      element: (
        <ButtonIcon
          {...{ ...props }}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: 50,
          }}
          className="px-1"
          onClick={() =>
            handleNestedItems(
              data,
              subClientSchema,
              { onDataChange },
              "detailService",
              true,
              nestedIndex
            )
          }
          icon={faMinusSquare}
        />
      ),
    },
    { type: "text", containerStyle: { zIndex: -9999999, opacity: 0 } },
  ];
};

export const subclientBillingSchema = (subclientData, handlers, catalogues) => {
  const { onDataChange } = handlers;
  const { CatRegime, CatCfdi, CatPaymentWay, suburbs } = catalogues;
  return [
    {
      label: "Nombre",
      placeholder: "Nombre de subcliente..",
      type: "text",
      name: "nameDetailService",
      required: true,
      iconName: "User",
      containerStyle: { margin: " 0px" },
      value: subclientData.nameDetailService,
      onInputChange: onDataChange,
      errorMessage: "Éste nombre no es válido",
    },
    {
      label: "Razón social",
      placeholder: "DIGITIZING MÉXICO TECH",
      type: "text",
      disabled: subclientData.applyClientFiscalData,
      name: "tradeNameDetailService",
      required: true,
      iconName: "ClipboardList",
      containerStyle: { margin: " 0px" },
      value: subclientData.tradeNameDetailService,
      onInputChange: onDataChange,
      errorMessage: "La razón social es requerida",
    },
    {
      label: "RFC",
      type: "text",
      name: "rfc",
      required: true,
      disabled: subclientData.applyClientFiscalData,
      value: subclientData.rfc,
      onInputChange: ({ target: { name, value } }) => {
        onDataChange({ target: { name, value: value.replace(" ", "") } });
      },
      iconName: "Files",
      containerStyle: { margin: " 0px" },
    },
    {
      label: "Código postal",
      type: "text",
      name: "postalCode",
      disabled: subclientData.applyClientFiscalData,
      required: true,
      value: subclientData.postalCode,
      onInputChange: onDataChange,
      containerStyle: { margin: 0 },
      iconName: "DollarSign",
      errorMessage:
        "Datos geográficos no encontrados, ingrese la información manualmente",
      error: subclientData.error,
    },
    {
      label: "Calle",
      type: "text",
      name: "street",
      disabled: subclientData.applyClientFiscalData,
      required: false,
      value: subclientData.street,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: " 0px" },
    },
    {
      label: "Colonia",
      type: "dataList",
      name: "suburb",
      required: false,
      disabled: subclientData.applyClientFiscalData,
      value: subclientData.suburb,
      onInputChange: onDataChange,
      options: suburbs[0],
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Municipio",
      type: "dataList",
      disabled: subclientData.applyClientFiscalData,
      name: "municipality",
      required: false,
      value: subclientData.municipality,
      options: (municipalitiesCat[subclientData.state] || []).map((m) => ({
        value: m,
        label: m,
      })),
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Número interno",
      type: "text",
      name: "internalNumber",
      disabled: subclientData.applyClientFiscalData,
      required: false,
      value: subclientData.internalNumber,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Número externo",
      type: "text",
      name: "outdoorNumber",
      required: false,
      disabled: subclientData.applyClientFiscalData,
      value: subclientData.outdoorNumber,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Estado",
      type: "text",
      name: "state",
      disabled: subclientData.applyClientFiscalData,
      required: false,
      value: subclientData.state,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Monto asignado",
      type: "number",
      name: "amount",
      min: 0.001,
      step: 0.001,
      disabled: true,
      required: false,
      value: subclientData.amount,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Observaciones",
      type: "text",
      disabled: false,
      name: "description",
      required: false,
      onInputChange: onDataChange,
      value: subclientData.description,
      iconName: "FormInput",
      containerStyle: { margin: 0 },
    },
    ...subClientInputs(
      subclientData,
      { onInputChange: onDataChange },
      { CatRegime, CatCfdi, CatPaymentWay },
      {
        containerStyle: { margin: 0 },
      }
    ),
    {
      label: "Descuento",
      type: "number",
      name: "absenceDiscountSubClient",
      min: 0.001,
      step: 0.001,
      required: false,
      value: subclientData.absenceDiscountSubClient,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Descripción de descuento ",
      name: "absenceDiscountSubClientDescription",
      required: false,
      value: subclientData.absenceDiscountSubClientDescription,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
  ];
};

export const subclientReBillingSchema = (
  subclientData,
  handlers,
  catalogues
) => {
  const { onDataChange } = handlers;
  const {
    CatRegime,
    CatCfdi,
    CatPaymentWay,
    CatReasonCfdiCancellation,
    suburbs,
  } = catalogues;

  return [
    {
      label: "Nombre",
      placeholder: "Nombre de subcliente..",
      type: "text",
      name: "nameDetailService",
      required: true,
      iconName: "User",
      containerStyle: { margin: "0px" },
      value: subclientData.nameDetailService,
      onInputChange: onDataChange,
      errorMessage: "Éste nombre no es válido",
    },
    {
      label: "Razón social",
      placeholder: "DIGITIZING MÉXICO TECH",
      type: "text",
      name: "tradeNameDetailService",
      required: true,
      iconName: "ClipboardList",
      containerStyle: { margin: " 0px" },
      value: subclientData.tradeNameDetailService,
      onInputChange: onDataChange,
      errorMessage: "La razón social es requerida",
    },
    {
      label: "RFC",
      type: "text",
      name: "rfc",
      required: true,
      value: subclientData.rfc,
      onInputChange: ({ target: { name, value } }) => {
        onDataChange({ target: { name, value: value.replace(" ", "") } });
      },
      iconName: "Files",
      containerStyle: { margin: " 0px" },
    },
    {
      label: "Código postal",
      type: "text",
      name: "postalCode",
      required: true,
      value: subclientData.postalCode,
      onInputChange: onDataChange,
      containerStyle: { margin: 0 },
      iconName: "DollarSign",
      errorMessage:
        "Datos geográficos no encontrados, ingrese la información manualmente",
      error: subclientData.error,
    },
    {
      label: "Calle",
      type: "text",
      name: "street",
      required: false,
      value: subclientData.street,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: " 0px" },
    },
    {
      label: "Colonia",
      type: "dataList",
      name: "suburb",
      required: false,
      value: subclientData.suburb,
      onInputChange: onDataChange,
      options: suburbs[0],
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Municipio",
      type: "dataList",
      disabled: subclientData.applyClientFiscalData,
      name: "municipality",
      required: false,
      value: subclientData.municipality,
      options: (municipalitiesCat[subclientData.state] || []).map((m) => ({
        value: m,
        label: m,
      })),
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Número interno",
      type: "text",
      name: "internalNumber",
      disabled: subclientData.applyClientFiscalData,
      required: false,
      value: subclientData.internalNumber,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Número externo",
      type: "text",
      name: "outdoorNumber",
      required: false,
      disabled: subclientData.applyClientFiscalData,
      value: subclientData.outdoorNumber,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Estado",
      type: "text",
      name: "state",
      disabled: subclientData.applyClientFiscalData,
      required: false,
      value: subclientData.state,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Monto asignado",
      type: "text",
      name: "amount",
      min: 0.001,
      step: 0.001,
      disabled: true,
      required: false,
      value: formatThousands(subclientData?.amount),
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Observaciones",
      type: "text",
      disabled: false,
      name: "description",
      required: false,
      onInputChange: onDataChange,
      value: subclientData.description,
      iconName: "FormInput",
      containerStyle: { margin: 0 },
    },
    ...subClientInputs(
      subclientData,
      { onInputChange: onDataChange },
      { CatRegime, CatCfdi, CatPaymentWay },
      {
        containerStyle: { margin: 0 },
      },
      true
    ),
    { containerStyle: { opacity: 0 } },
    {
      type: "select",
      label: "Motivo de cancelación",

      options: [
        { value: "", label: "Elige un Motivo" },
        ...parseCat(
          CatReasonCfdiCancellation,
          "name",
          "idCatReasonCfdiCancellation",
          "rebill"
        ),
      ],
      name: "idCatReasonCfdiCancellation",
      containerStyle: { width: "100%", margin: 0 },
      value: subclientData.idCatReasonCfdiCancellation,
      onInputChange: onDataChange,
      required: true,
      iconName: "XCircle",
    },
    {
      type: "textarea",
      label: "Comentarios u observaciones de cancelación",
      placeholder: "...",
      name: "commentInactive",
      containerStyle: { width: "100%", margin: 0 },
      value: subclientData.commentInactive,
      onInputChange: onDataChange,
      iconName: "StickyNote",
    },
    {
      label: "Descuento",
      type: "number",
      name: "absenceDiscountSubClient",
      min: 0.001,
      step: 0.001,
      required: false,
      value: subclientData.absenceDiscountSubClient,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
    {
      label: "Descripción de descuento ",
      name: "absenceDiscountSubClientDescription",
      required: false,
      value: subclientData.absenceDiscountSubClientDescription,
      onInputChange: onDataChange,
      iconName: "DollarSign",
      containerStyle: { margin: 0 },
    },
  ];
};

export const registerDepositSchema = (data, handlers, catalogues) => {
  const { onDataChange, setValue } = handlers;
  const {
    clientBankAccounts = [],
    receivingBanks = [],
    paymentWays = [],
    clients = [],
  } = catalogues;
  const inputStyle = { width: "49%" };
  return [
    {
      label: "Cliente",
      type: "dataList",
      name: "clientId",
      validations: { required: "El cliente es requerido!" },
      options: clients,
      required: false,
      onInputChange: onDataChange,
      iconName: "Users",
    },
    {
      label: "Fecha de depósito",
      placeholder: "25/07/1978",
      type: "date",
      name: "depositDate",
      validations: {
        required: "La fecha de depósito es requerida!",
        maxLength: {
          value: 10,
          message: "La longitud maxima del año es de 4!",
        },
      },
      onInputChange: onDataChange,
      iconName: "Calendar",
      containerStyle: inputStyle,
    },
    {
      label: "Monto asignado",
      name: "amountDeposit",
      disabled: data.clientId ? false : true,
      validations: {
        required: "Debe ingresar un monto!",
      },
      value: data.amountDeposit,
      onInputChange: onDataChange,
      handleBlur: (e) => {
        const { value } = e.target;
        onDataChange({
          ...e,
          target: {
            ...e.target,
            name: "amountDeposit",
            value: formatThousands(value),
          },
        });
      },
      iconName: "DollarSign",
      containerStyle: inputStyle,
    },
    {
      label: "Forma de pago",
      type: "select",
      name: "idCatPaymentWay",
      validations: {
        required: "La forma de pago es requerida!",
      },
      onInputChange: onDataChange,
      options: [
        { label: "Elige una forma de pago..", value: "" },
        ...paymentWays,
      ],
      iconName: "FormInput",
      containerStyle: inputStyle,
    },
    {
      label: "Observaciones",
      type: "text",
      disabled: false,
      name: "descriptionDeposit",
      required: false,
      onInputChange: onDataChange,
      iconName: "FormInput",
      containerStyle: inputStyle,
    },
    {
      label: "Banco emisor",
      type: "select",
      name: "idClientAccount",
      // validations: {
      //   required: "Debe ingresar un banco emisor!",
      // },
      onInputChange: onDataChange,
      options: [
        { label: "Elige un banco..", value: "" },
        ...clientBankAccounts,
      ],
      iconName: "FormInput",
      containerStyle: inputStyle,
    },
    {
      label: "Banco receptor",
      type: "select",
      name: "idSesAccount",
      // // validations: {
      // //   required: "Debe ingresar un banco receptor!",
      // // },
      onInputChange: onDataChange,
      options: [{ label: "Elige un banco..", value: "" }, ...receivingBanks],
      iconName: "FormInput",
      containerStyle: inputStyle,
    },
    {
      type: "checkbox",
      name: "applyDeposit",
      value: data.applyDeposit,
      placeholder: "Aplicar depósito",
      onInputChange: onDataChange,
      containerStyle: inputStyle,
    },
    { style: { opacity: 0 }, containerStyle: inputStyle },
  ];
};

export const editTemplateServiceBasicDataSchema = (data, { onDataChange }) => {
  return [
    {
      label: "Nombre comercial",
      placeholder: "Guardia de seguridad",
      type: "text",
      name: "name",
      value: data.name,
      minLength: "5",
      required: true,
      iconName: "ClipboardList",
      containerClassName: "mt-0",
      errorMessage: "Éste nombre comercial no es válido",
      onChange: onDataChange,
    },
    {
      label: "Periodicidad del pago",
      placeholder: "Periodicidad del pago",
      type: "select",
      name: "paymentPeriod",
      value: data.paymentPeriod,
      options: [
        { label: "Selecciona un elemento", value: "" },
        { label: "Quincenal", value: "BIWEEKLY" },
        { label: "Mensual", value: "MONTHLY" },
      ],
      required: true,
      containerClassName: "mt-0",
      iconName: "DollarSign",
      errorMessage: "Seleccione una opción",
      onChange: onDataChange,
    },
    {
      label: "Periodicidad de facturación",
      placeholder: "Periodicidad de facturación",
      type: "select",
      name: "invoicePeriod",
      value: data.invoicePeriod,
      options: [
        { label: "Selecciona un elemento", value: "" },
        { label: "Quincenal", value: "BIWEEKLY" },
        { label: "Mensual", value: "MONTHLY" },
      ],
      required: true,
      containerClassName: "mt-0",
      iconName: "DollarSign",
      errorMessage: "Seleccione una opción",
      onChange: onDataChange,
    },
    {
      label: "Tipo de servicio",
      type: "select",
      name: "clientTypeService",
      value: data.clientTypeService,
      options: clientTypes,
      required: true,
      containerClassName: "mt-0",
      iconName: "Files",
      errorMessage: "Seleccione una opción",
      onChange: onDataChange,
    },
  ];
};

export const editServiceBasicDataSchema = (data, { onDataChange }) => {
  return [
    {
      label: "Nombre comercial",
      placeholder: "Guardia de seguridad",
      type: "text",
      name: "name",
      value: data.name,
      minLength: "5",
      required: true,
      iconName: "ClipboardList",
      containerClassName: "mt-0",
      errorMessage: "Éste nombre comercial no es válido",
      onChange: onDataChange,
    },
    {
      label: "Periodicidad del pago",
      placeholder: "Periodicidad del pago",
      type: "select",
      name: "paymentPeriod",
      value: data.paymentPeriod,
      options: [
        { label: "Selecciona un elemento", value: "" },
        { label: "Quincenal", value: "BIWEEKLY" },
        { label: "Mensual", value: "MONTHLY" },
      ],
      required: true,
      containerClassName: "mt-0",
      iconName: "DollarSign",
      errorMessage: "Seleccione una opción",
      onChange: onDataChange,
    },
    {
      label: "Periodicidad de facturación",
      placeholder: "Periodicidad de facturación",
      type: "select",
      name: "invoicePeriod",
      value: data.invoicePeriod,
      options: [
        { label: "Selecciona un elemento", value: "" },
        { label: "Quincenal", value: "BIWEEKLY" },
        { label: "Mensual", value: "MONTHLY" },
      ],
      required: true,
      containerClassName: "mt-0",
      iconName: "DollarSign",
      errorMessage: "Seleccione una opción",
      onChange: onDataChange,
    },
    {
      label: "Tipo de servicio",
      type: "select",
      name: "clientTypeService",
      value: data.clientTypeService,
      options: clientTypes,
      required: true,
      containerClassName: "mt-0",
      iconName: "Files",
      errorMessage: "Seleccione una opción",
      onChange: onDataChange,
    },
    {
      label: "Editar plantilla de servicio",
      type: "checkbox",
      name: "editTemplate",
      checked: data.editTemplate,
      value: data.editTemplate,
      containerClassName: "mt-0",
      onChange: onDataChange,
    },
    {
      label: "Editar servicios futuros",
      type: "checkbox",
      name: "editFuturePeriodsServices",
      checked: data.editFuturePeriodsServices,
      value: data.editFuturePeriodsServices,
      containerClassName: "mt-0",
      onChange: onDataChange,
    },
  ];
};
