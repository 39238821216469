import { X } from "lucide-react";
import React from "react";

function ContactAddressContainer({ deleteAction, children, style, textStyle }) {
  return (
    <div style={{ ...styles.rootContainer, ...style }}>
      {deleteAction && <X onClick={deleteAction} style={styles.xButton} size={15} />}
      <p style={{ ...styles.text, ...textStyle }}>{children}</p>
    </div>
  );
}
const styles = {
  rootContainer: {
    padding: "0px 9px",
    borderRadius: 20,
    backgroundColor: "#2a2f99",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    gap: 3,
  },
  text: { color: "white" },
  xButton: {
    color: "white",
    backgroundColor: "red",
    borderRadius: 100,
    cursor: "pointer",
  },
};
export default ContactAddressContainer;
