import { Eye, Trash } from "lucide-react";
import React from "react";

function ListItem({
  title,
  description,
  setSingleData,
  singleData,
  setOpenModal,
  children,
  deleteItem,
  showItem = true,
  containerStyle,
  style,
  coloredBarStyle,
  onMouseDown,
}) {
  return (
    <div
      onMouseDown={onMouseDown}
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "lavender",
        alignItems: "center",
        marginTop: "0.5rem",
        ...containerStyle,
      }}
    >
      <div
        style={{
          height: "6rem",
          width: "1rem",
          backgroundColor: "#2A2F99",
          opacity: 0.5,
          ...coloredBarStyle,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          padding: "0.6rem 0rem",
          width: "100%",
          ...style,
        }}
      >
        <div style={{ color: "#2A2F99" }}>{title}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "grey",
            fontStyle: "italic",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {description}
          <p>{children}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0rem 1rem",
          gap: "5px",
          position: "relative",
          right: 0,
        }}
      >
        {showItem && (
          <Eye
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSingleData(singleData);
              setOpenModal(true);
            }}
          />
        )}
        {deleteItem && (
          <Trash style={{ cursor: "pointer" }} onClick={deleteItem} />
        )}
      </div>
    </div>
  );
}

export default ListItem;
