import { GENERAL_CATALOGUES } from "../actions";

const INITIAL_STATE = {};

export const cataloguesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GENERAL_CATALOGUES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
