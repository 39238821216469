import React from "react";
import Grid from "@mui/material/Grid";
import iconoDetalle from "../css/img/IconoDetalleCliente.svg";

const ClientCard = ({
  style,
  cardContainerStyle,
  client = {},
  service,
  selectable = false,
  onTap = () => { },
  md = "3",
  sm = "4",
  xs = "6",
  bottomComponent = null,
}) => {
  const { selected = false, ix = 0 } = client;
  return (
    <div
      style={{
        marginTop: "0.3vw",
        display: "inline-block",
        cursor: selectable ? "pointer" : "",
        ...style,
      }}
      onClick={onTap(ix)}
    >
      <div
        className="transition-all shadow-md hover:shadow-xl"
        style={{
          border: "solid 0.5px lightgrey",
          borderRadius: 25,
          display: "flex",
          minHeight: 250,
          flexDirection: "row",
          border: selected ? "3px solid #2A2F99" : "",
        }}
      >
        <div
          style={{
            width: 30,
            backgroundColor: "#2A2F99",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 6,
            marginTop: "0.01rem",
            marginLeft: "0.01rem",
            width: "auto",
            padding: 10,
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "5px",
              ...cardContainerStyle,
            }}
          >
            <img src={iconoDetalle} style={{ width: "75px" }} />
            <div
              style={{
                color: "#2A2F99",
                padding: "1rem",
                fontSize: "110%",
                fontWeight: 500,
                flex: 1,
              }}
            >
              {client.name || "Cliente"}
              <br />
              {service ? (
                <>
                  {service?.name} <br />
                </>
              ) : null}
              <br />
            </div>
          </div>
          <div className="  pl-2">
            <div style={{ fontWeight: 500 }}>RFC: {client.rfc || ""}</div>
            <div
              style={{
                fontWeight: 400,
              }}
            >
              {`${client.street || "No existente"} | ${client.outdoorNumber || ""
                } ${client.internalNumber || ""}  ${client.suburb ? "Col: " + client.suburb : "No existente"
                } | ${client.postalCode ? "C.P: " + client.postalCode : "No existente"
                }  ${client.municipality || ""} ${client.state || ""}`}
            </div>
          </div>
          {bottomComponent}
        </div>
      </div>
    </div>
  );
};

ClientCard.propTypes = {};

export default ClientCard;
