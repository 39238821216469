import React, { useCallback, useEffect, useState } from "react";
import { getColumnsPersonal } from "../../helpers/datatableHelpers";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../helpers/useForm";
import { Request } from "../../helpers/api";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import DgTable from "../../components/DgTable";
import ModalEgressPerson from "./modals/ModalEgressPerson";
import ModalEntrancePerson from "./modals/ModalEntrancePerson";
import ModalAssignService from "./modals/ModalAssignService";
import ModalIncidenceLog from "./modals/ModalIncidenceLog";
import ModalCompensations from "./modals/ModalCompensations";
import ModalDoubletRegister from "./modals/ModalDoubletRegister";
import ModalVacations from "./modals/ModalVacations";
import ModalAttachedFiles from "./modals/ModalAttachedFiles";
import {
  getPermissions,
  objetctToQueryStr,
  parseCat,
} from "../../helpers/utils";
import { useSelector } from "react-redux";

const PersonList = () => {
  const navigate = useNavigate();
  const [modalOpenEgress, setModalOpenEgress] = useState(false);
  const [modalOpenEntrance, setModalOpenEntrance] = useState(false);
  const [modalAssignService, setModalAssignService] = useState(false);
  const [modalIncidenceLog, setModalIncidenceLog] = useState(false);
  const [modalCompensations, setModalCompensations] = useState(false);
  const [modalDoubletRegister, setModalDoubletRegister] = useState(false);
  const [openModalVacations, setOpenModalVacations] = useState(false);
  const [openModalAttachedFiles, setOpenModalAttachedFiles] = useState(false);
  const [services, setServices] = useState([]);

  const [filters, setFilters] = useForm({
    serviceName: "",
    isActive: "",
  });
  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { permissions } = useSelector((s) => s);
  const {
    catalogues: { CatServicePayroll, CatSupervisor, CatZone },
  } = useSelector((state) => state);
  const funcs = {
    //ACÁ PODRÍAMOS GUARDAR EN UNA PROPIEDAD, EL ARRAY CON LOS PERMISOS DEL USUARIO LOGUEADO, PARA SABER CUÁLES ÍCONOS MOSTRAR Y CUÁLES NO.
    userPermissions: getPermissions(permissions),
    documents: (row) => () => {
      setSingleData(row);
    },
    modalEgress: (row) => {
      setSingleData(row);
      setModalOpenEgress(true);
    },
    modalEntrance: (row) => {
      setSingleData(row);
      setModalOpenEntrance(true);
    },
    modalAssignService: (row) => {
      setSingleData(row);
      setModalAssignService(true);
    },
    modalIncidenceLog: (row) => {
      setSingleData(row);
      setModalIncidenceLog(true);
    },
    modalCompensations: (row) => {
      setSingleData(row);
      setModalCompensations(true);
    },
    modalDoubletRegister: (row) => {
      setSingleData(row);
      setModalDoubletRegister(true);
    },
    modalVacations: (row) => {
      setSingleData(row);
      setOpenModalVacations(true);
    },
    modalAttachedFiles: (row) => {
      setSingleData(row);
      setOpenModalAttachedFiles(true);
    },
    openPersonDetail: (row) => {
      navigate("/detalle-empleado/" + row.idEmployee);
    },
  };

  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields =
      field === "all" ? ["name", "employeeKey", "supervisor"] : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const fetchPersonsServices = useCallback(async (filters) => {
    const { ok, data } = await Request(
      `/employee?${objetctToQueryStr(filters)}`
    );
    if (ok) {
      setAllData(data);
      setData(data);
    }
  }, []);

  const fetchServices = useCallback(async () => {
    const res = await Request("/client/service");
    if (res.ok) {
      setServices(res.data);
    }
  }, []);

  useEffect(() => {
    fetchPersonsServices(filters);
    fetchServices();
  }, []);

  useEffect(() => {
    const filteredPersonList = allData.filter((person) => {
      if (!(filters.isActive === "0") && !(filters.isActive === "1"))
        return true;
      return filters.isActive === String(person.isActive);
    });

    const finalFilteredList = filteredPersonList.filter((person) => {
      return (
        !filters.serviceName ||
        filters.serviceName === person?.serviceName?.replace("  ", " ").trim()
      );
    });
    setData(finalFilteredList);
  }, [filters]);

  return (
    <Skinpage pageTitle={"LISTA DE PERSONAL"}>
      <div className="grid grid-cols-4 gap-3" style={{ alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar persona"
          iconName="Search"
        />
        <DgInput
          type="select"
          label="Filtrar por servicio"
          options={[
            {
              label: "Seleccione servicio",
              value: "",
            },
            ...parseCat(CatServicePayroll, "name", "serviceName"),
          ]}
          onChange={setFilters}
          name="serviceName"
          value={filters.serviceName}
          iconName="CreditCard"
        />
        <DgInput
          type="select"
          label="Filtrar por estatus"
          options={[
            {
              label: "Todos",
              value: "",
            },
            {
              label: "Activo",
              value: "1",
            },
            {
              label: "Inactivo",
              value: "0",
            },
          ]}
          onChange={setFilters}
          name="isActive"
          value={filters.isActive}
          iconName="Contact"
        />
        <Button
          style={{ margin: "0.5rem" }}
          onClick={() => {
            navigate("/nueva-persona");
          }}
        >
          Nuevo ingreso
        </Button>
      </div>
      <DgTable
        data={data}
        columnsDef={getColumnsPersonal(funcs)}
        className="mt-8 tableHeaderPersonal"
      />

      <ModalEgressPerson
        isOpen={modalOpenEgress}
        data={singleData}
        toggle={() => {
          setModalOpenEgress(!modalOpenEgress);
        }}
        fetchAllData={fetchPersonsServices}
      />

      <ModalEntrancePerson
        isOpen={modalOpenEntrance}
        data={singleData}
        toggle={() => {
          setModalOpenEntrance(!modalOpenEntrance);
        }}
        fetchAllData={fetchPersonsServices}
      />

      <ModalAssignService
        isOpen={modalAssignService}
        data={singleData}
        fetchData={fetchPersonsServices}
        toggle={() => {
          setModalAssignService(!modalAssignService);
        }}
        fetchAllData={fetchPersonsServices}
      />

      <ModalIncidenceLog
        isOpen={modalIncidenceLog}
        data={singleData}
        toggle={() => {
          setModalIncidenceLog(!modalIncidenceLog);
        }}
      />

      <ModalCompensations
        isOpen={modalCompensations}
        data={singleData}
        fetchData={fetchPersonsServices}
        toggle={() => {
          setModalCompensations(!modalCompensations);
        }}
        fetchAllData={fetchPersonsServices}
      />

      <ModalDoubletRegister
        isOpen={modalDoubletRegister}
        data={singleData}
        fetchData={fetchPersonsServices}
        toggle={() => {
          setModalDoubletRegister(!modalDoubletRegister);
        }}
        fetchAllData={fetchPersonsServices}
      />

      <ModalVacations
        isOpen={openModalVacations}
        data={singleData}
        fetchData={fetchPersonsServices}
        toggle={() => {
          setOpenModalVacations(!openModalVacations);
        }}
        fetchAllData={fetchPersonsServices}
      />

      <ModalAttachedFiles
        isOpen={openModalAttachedFiles}
        data={singleData}
        toggle={() => {
          setOpenModalAttachedFiles(!openModalAttachedFiles);
        }}
        fetchAllData={fetchPersonsServices}
      />
    </Skinpage>
  );
};

export default PersonList;
