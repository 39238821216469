import React, { useEffect } from "react";
import DgContainer from "../../../components/DgContainer";
import { useState } from "react";
import Swal from "sweetalert2";
import DocumentDetails from "../ModalDocumentDetails";
import DocumentCard from "../../../components/DocumentCard";
import DgModal from "../../../components/DgModal";
import { Request } from "../../../helpers/api";
import { File } from "lucide-react";
import { CircularProgress } from "@mui/material";
import DgInput from "../../../components/DgInput";
import useFilterItem from "../../../helpers/useFilterItem";

const DocumentsList = ({ documents = [], setDocuments, open, setOpen }) => {
  const [filterInputValue, setFilterInputValue] = useState(null);
  const [filteredList] = useFilterItem(documents, filterInputValue);
  const [modalOpen, setModalOpen] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [loading, setLoading] = useState(true);
  const handleDetail = (d) => {
    setSingleData(d);
    setModalOpen(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      setLoading(true);
    };
  }, [open]);

  const handleDelete = (d, ix) => {
    Swal.fire({
      title: "¿Confirma la siguiente operación?",
      text: `Se eliminará el documento ${d.name}.`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          "/client/document/" + d.idDocumentClient,
          { isActive: false },
          "PATCH"
        );
        if (ok) {
          Swal.fire({
            title: "Éxito",
            text: "Documento eliminado con éxito.",
            icon: "success",
          }).then(() => {
            const newDocuments = [...documents];
            newDocuments.splice(ix, 1);
            setDocuments(newDocuments);
          });
        }
      }
    });
  };
  return (
    <DgModal
      withClose={true}
      modalStyle={{ zIndex: 999 }}
      style={{ background: "white" }}
      toggle={() => setOpen((currentState) => !currentState)}
      open={open}
      title={"Documentos"}
    >
      <DgInput
        label="Filtrar por nombre"
        iconName="Filter"
        containerStyle={{ padding: "0px 20px" }}
        type="text"
        placeholder={"Nombre de archivo.."}
        onChange={(e) => setFilterInputValue(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {!loading ? (
          filteredList
            .filter((d) => d.isActive)
            .map((d, ix) => (
              <DocumentCard
                icon={<File color="#2A2F99" size={30} />}
                document={d}
                handlers={{ handleDelete, handleDetail }}
                ix={ix}
              />
            ))
        ) : (
          <CircularProgress
            style={{
              alignSelf: "center",
              color: "#2A2F99",
              margin: "20px 0px",
            }}
          />
        )}
      </div>
      <DocumentDetails
        document={singleData}
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
      />
    </DgModal>
  );
};

export default DocumentsList;
