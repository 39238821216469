import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ChevronDown, Plus, Minus } from "lucide-react";
import {
  beneficiariesSchema,
  emergencyContactSchema,
  personSchema,
} from "../../helpers/schemas";
import { Tooltip } from "@mui/material";
import {
  personInputsSecondary,
  personInputsDocuments,
  personInputsGeneral,
  addressInputs,
} from "../../helpers/formUtils";
import DgInput from "../../components/DgInput";
import DgForm from "../../components/DgForm";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPhone, parseObjectDate } from "../../helpers/utils";
import Swal from "sweetalert2";
import { relationShipCat } from "../../helpers/enums";
import { handlePhoneChange } from "../../helpers/utils";
import useGetZipCode from "../../helpers/useGetZipCode";

const NewPerson = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { CatEducationalLevel, CatMaritalStatus, bank } = useSelector(
    (s) => s.catalogues
  );
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...personSchema,
    employeeBeneficiaries: [{}],
    emergencyContacts: [{}],
  });

  const [handleZipChange, suburbs] = useGetZipCode(
    data.postalCode,
    data,
    setAllData
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedChange = (ix, key) => (e) => {
    const { value, name } = e.target;
    const arr = data[key];
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: key, value: arr } });
  };
  const manageEContacts = (willDelete, ix) => () => {
    const newArr = [...data.emergencyContacts];
    if (willDelete) {
      newArr.splice(ix, 1);
    } else {
      newArr.push({ ...emergencyContactSchema });
    }
    setAllData({ ...data, emergencyContacts: newArr });
  };

  const manageBeneficiaries = (willDelete, ix) => () => {
    const newArr = [...data.employeeBeneficiaries];
    if (willDelete) {
      newArr.splice(ix, 1);
    } else {
      newArr.push({ ...beneficiariesSchema });
    }
    setAllData({ ...data, employeeBeneficiaries: newArr });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { birthDate, antidopingDate, admissionDate } = data;
    const res = await Request(
      "/employee",
      {
        ...data,
        age: 50,
        state: "México",
        birthDate: parseObjectDate(new Date(birthDate), 1, true),
        antidopingDate:
          antidopingDate !== ""
            ? parseObjectDate(new Date(antidopingDate), 1, true)
            : "",
        admissionDate: parseObjectDate(new Date(admissionDate),1,true),
        hasAbsorptionDate: data.absorptionDate ? true : false,
        absorptionDate: data.absorptionDate
          ? parseObjectDate(new Date(data.absorptionDate),1,true)
          : "",
        //emergencyContacts: [emergencyData],
      },
      "POST"
    );
    if (res.ok) {
      Swal.fire("Éxito", "Personal registrado con éxito.", "success").then(() => {
        clearData();
        navigate("/lista-personal");
      });
    }
  };
  useEffect(() => {
    handleZipChange();
  }, [data.postalCode]);
  return (
    <Skinpage pageTitle={"Alta de Personal"}>
      <h2 className="text-sesBlue text-lg mb-12 font-semibold relative top-6">
        DATOS DEL INGRESO
      </h2>
      <form onSubmit={handleSubmit}>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            DATOS GENERALES
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              data={data}
              onChange={onDataChange}
              groups={personInputsGeneral(data, {
                handlePhoneChange,
                onDataChange,
              })}
              className="mb-8"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            DATOS SECUNDARIOS
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              data={data}
              onChange={onDataChange}
              groups={personInputsSecondary(
                data,
                {},
                {
                  CatMaritalStatus,
                  CatEducationalLevel,
                }
              )}
              className="mb-8"
            ></DgForm>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            DIRECCIÓN
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              zipError={data.error}
              data={data}
              onChange={onDataChange}
              groups={[
                {
                  fields: [
                    ...addressInputs(
                      { ...data, clientType: "FISCAL" },
                      suburbs
                    ),
                  ],
                },
              ]}
              className="mb-8"
            ></DgForm>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            DATOS DE DOCUMENTOS OFICIALES Y BANCARIOS
          </AccordionSummary>
          <AccordionDetails>
            <DgForm
              data={data}
              onChange={onDataChange}
              groups={personInputsDocuments(data, {}, { bank })}
              className="mb-8"
            ></DgForm>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            CONTACTO DE EMERGENCIA
          </AccordionSummary>
          <AccordionDetails>
            <h3 className="mt-4 ml-2 text-sesBlue font-semibold">
              AGREGAR CONTACTOS
            </h3>
            <div className="grid grid-cols-4 gap-4 gridEmergencyContact">
              {data.emergencyContacts?.length === 0 ? (
                <>
                  <div />
                  <div />
                  <div />
                  <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                    <Plus
                      color="#565656"
                      size={28}
                      className="m-auto p-1 mt-1 cursor-pointer"
                      onClick={manageEContacts(false)}
                    />
                  </div>
                </>
              ) : null}
              {data.emergencyContacts.map((item, ix) => {
                const onItemChange = handleNestedChange(
                  ix,
                  "emergencyContacts"
                );
                return (
                  <>
                    <DgInput
                      type="text"
                      label="Nombre completo"
                      name="fullName"
                      value={item.fullName}
                      onChange={onItemChange}
                      required={true}
                      iconName={"User"}
                    />
                    <DgInput
                      type="select"
                      label="Parentesco"
                      name="relationship"
                      options={[
                        { label: "Selecciona parentesco", value: "" },
                        ...relationShipCat,
                      ]}
                      data={item.relationship}
                      onChange={onItemChange}
                      required={true}
                      iconName={"User"}
                    />
                    <DgInput
                      type="tel"
                      label="Teléfono"
                      name="phone"
                      placeholder="55-0000-0000"
                      pattern="[0-9]{2}-[0-9]{4}-[0-9]{4}"
                      value={item.phone && formatPhone(item.phone)}
                      maxLength={12}
                      minLength={12}
                      onChange={onItemChange}
                      required={true}
                      iconName={"Phone"}
                    />
                    <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                      <Tooltip title="Añadir otro contacto">
                        <Plus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 mt-1 cursor-pointer"
                          onClick={manageEContacts(false, ix)}
                        />
                      </Tooltip>
                      <Tooltip title="Quitar contacto">
                        <Minus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 cursor-pointer"
                          onClick={manageEContacts(true, ix)}
                        />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            </div>
            {/*<DgForm
              data={emergencyData}
              onChange={onEmergencyDataChange}
              groups={personInputsEmergencyContact(emergencyData)}
              className="mb-8"
              ></DgForm>*/}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="personInsert"
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ChevronDown color="#FFFFFF" />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            DATOS DEL BENEFICIARIO
          </AccordionSummary>
          <AccordionDetails>
            <h3 className="mt-4 ml-2 text-sesBlue font-semibold">
              AGREGAR BENEFICIARIOS
            </h3>
            <div className="grid grid-cols-5 gap-4 gridBeneficiary">
              {data.employeeBeneficiaries?.length === 0 ? (
                <>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                    <Plus
                      color="#565656"
                      size={28}
                      className="m-auto p-1 mt-1 cursor-pointer"
                      onClick={manageBeneficiaries(false)}
                    />
                  </div>
                </>
              ) : null}
              {data.employeeBeneficiaries.map((item, ix) => {
                const onItemChange = handleNestedChange(
                  ix,
                  "employeeBeneficiaries"
                );
                return (
                  <>
                    <DgInput
                      type="text"
                      label="Nombre completo"
                      name="fullName"
                      value={item.fullName}
                      onChange={onItemChange}
                      required={true}
                      iconName={"User"}
                    />
                    <DgInput
                      type="tel"
                      label="Teléfono"
                      name="phone"
                      placeholder="55-0000-0000"
                      pattern="[0-9]{2}-[0-9]{4}-[0-9]{4}"
                      value={item.phone && formatPhone(item.phone)}
                      maxLength={12}
                      minLength={12}
                      onChange={onItemChange}
                      required={true}
                      iconName={"Phone"}
                    />
                    <DgInput
                      type="text"
                      label="Dirección"
                      name="address"
                      placeholder="Calle, número, colonia, municipio, etc."
                      value={item.address}
                      onChange={onItemChange}
                      required={true}
                      iconName={"MapPin"}
                    />
                    <DgInput
                      type="number"
                      label="Porcentaje"
                      name="percentage"
                      placeholder="100%"
                      value={item.percentage}
                      onChange={onItemChange}
                      required={true}
                      iconName={"Percent"}
                    />
                    <div className="grid pt-8 mt-8 w-full ml-0 place-content-start">
                      <Tooltip title="Añadir otro beneficiario">
                        <Plus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 mt-1 cursor-pointer"
                          onClick={manageBeneficiaries(false, ix)}
                        />
                      </Tooltip>
                      <Tooltip title="Quitar beneficiario">
                        <Minus
                          color="#565656"
                          size={28}
                          className="m-auto p-1 cursor-pointer"
                          onClick={manageBeneficiaries(true, ix)}
                        />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            </div>
            {/*<DgForm
              data={emergencyData}
              onChange={onEmergencyDataChange}
              groups={personInputsEmergencyContact(emergencyData)}
              className="mb-8"
              ></DgForm>*/}
          </AccordionDetails>
        </Accordion>

        <div className="grid grid-cols-3 mt-8">
          <div />
          <div />
          <Button type="submit" className="mt-8">
            Dar de alta
          </Button>
        </div>
      </form>
    </Skinpage>
  );
};

export default NewPerson;
