import React, { useState } from "react";
import DgInput from "../components/DgInput";
import Button from "../components/Button";
import logo from "../css/img/LogoSES.svg";
import InputIcon from "../components/InputIcon";
import { startLogin } from "../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "../helpers/useForm";
import Swal from "sweetalert2";
import DgLoader from "../components/DgLoader";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [keepSession, setKeepSession] = useState(false);
  const [formData, formOnChange] = useForm({
    username: "admin_ses@dgmx.com",
    password: "Aa123456",
  });
  const { username, password } = formData;

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(
      startLogin(
        username,
        password,
        keepSession,
        () => {
          navigate("/inicio");
        },
        setIsLoading
      )
    );
  };

  return (
    <div className="w-full container">
      <div className="cssLoginIzq"></div>
      <div className="cssLoginDer">
        <div className="w-3/4 mx-auto">
          <form onSubmit={handleLogin}>
            <img src={logo} className="w-1/4 mx-auto my-12" />
            <div className="mx-auto">
              <h4 className="text-sesBlue text-center font-semibold text-lg">
                INICIO DE SESIÓN
              </h4>
              <p className="text-sesGray text-center font-regular text-base px-2 mt-4">
                Para acceder al sistema, ingrese su usuario y contraseña.
              </p>
              <DgInput
                type="text"
                value={username}
                required={true}
                label="Usuario"
                placeholder="Usuario"
                name="username"
                onChange={formOnChange}
                iconName="User"
              />
              <DgInput
                type="password"
                value={password}
                required={true}
                label="Contraseña"
                placeholder="Contraseña"
                name="password"
                onChange={formOnChange}
                iconName="Lock"
              />
              <p className="text-sesBlue text-center mt-6">
                ¿Olvidó su contraseña?{" "}
                <a
                  className="cssLink hover:text-sesBlue"
                  onClick={() => {
                    Swal.fire(
                      "Por favor contacta a tu administrador",
                      "",
                      "info"
                    );
                  }}
                >
                  Dé clic aquí.
                </a>
              </p>
              <div className="mx-auto w-1/3">
                <Button type="submit">Iniciar sesión</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <DgLoader open={isLoading} />
    </div>
  );
};

export default Login;
