import React from "react";
import "./Button.css";
import { Tooltip } from "@mui/material";

const Button = ({
  children,
  onClick,
  type = "button",
  style = [],
  disabled = false,
  innerClassName = "",
  color = "primary",
  containerStyle,
  className,
  tooltip,
  tooltipPlacementt = "bottom",
}) => {
  return tooltip ? (
    <Tooltip
      placement={tooltipPlacementt}
      title={tooltip}
      style={containerStyle}
      className={`mx-auto w-full mt-6 ${className}`}
    >
      <button
        {...{
          style,
          onClick,
          type,
          disabled,
        }}
        className={
          innerClassName +
          " dgButton px-8 w-full mx-auto text-center " +
          (!innerClassName.includes("py") ? "py-3 " : " ") +
          color
        }
      >
        {children}
      </button>
    </Tooltip>
  ) : (
    <div style={containerStyle} className={`mx-auto w-full mt-6 ${className}`}>
      <button
        {...{
          style,
          onClick,
          type,
          disabled,
        }}
        className={
          innerClassName +
          " dgButton px-8 w-full mx-auto text-center " +
          (!innerClassName.includes("py") ? "py-3 " : " ") +
          color
        }
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
