import FileUploadedIcon from "./FileUploadedIcon";

function FileUploadedList({ files = [], style, stateSetter }) {
  const updateFileList = (fileName, fileList, stateSetter) => {
    const newFileList = [...fileList].filter((file) => file.name != fileName);
    stateSetter((prevData) => ({ ...prevData, files: newFileList }));
  };

  return files.length ? (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {[...files].map((file, index) => {
        return (
          <FileUploadedIcon
            key={index}
            label={file.name}
            required={file.required}
            fileRemover={() => {
              updateFileList(file.name, files, stateSetter);
            }}
          />
        );
      })}
    </div>
  ) : null;
}

export default FileUploadedList;
