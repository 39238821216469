import React from "react";
import {
  getColorInvoice,
  getColorPayment,
  getColorPaymentComplement,
  getMxFormatNumber,
  parseObjectDate,
  unformatThousands,
} from "./utils";
import {
  Wallet,
  Edit,
  Paperclip,
  Mail,
  Eye,
  Contact,
  PlusCircle,
  Trash2,
  CheckSquare,
  Slash,
  XCircle,
  FileDigit,
  Landmark,
  ShieldCheck,
  Luggage,
  CalendarDays,
  FilePlus2,
  Info,
  CheckCircle2,
  FileX2,
  Files,
  View,
  FileX,
  FileOutput,
  ToggleLeft,
  StickyNote,
  ClipboardList,
  Gift,
  Check,
  X,
  File,
  FileDown,
  FileInput,
} from "lucide-react";

import {
  BsEnvelope,
  BsFileEarmarkArrowDownFill,
  BsFilePdf,
  BsFiletypePdf,
  BsFiletypeXml,
} from "react-icons/bs";
import {
  TbClockPlay,
  TbClockPause,
  TbUsers,
  TbFileInvoice,
  TbCurrencyDollarOff,
} from "react-icons/tb";

import { Checkbox, Switch, Tooltip } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import PaymentLabel from "../components/PaymentLabel";
import Swal from "sweetalert2";
import DgInput from "../components/DgInput";

const customCell = (
  selector,
  alignment = "left",
  renderText = (text) => {
    if (text === 0) return 0;
    if (!text) return "-";
    return text;
  }
) => {
  return (row) => (
    <div style={{ textAlign: alignment, width: "100%" }}>
      {renderText(row[selector])}
    </div>
  );
};

export const getColumnsClients = (funcs) => [
  {
    name: "NRO.",
    center: true,
    width: "10%",
    key: "clientNumber",
    cell: customCell("clientNumber", "center"),
  },
  {
    name: "RAZÓN SOCIAL/NOMBRE",
    width: "15%",
    center: true,
    key: "nameClient",
    cell: customCell("nameClient", "center"),
  },
  {
    name: "RFC",
    width: "10%",
    center: true,
    key: "rfc",
    cell: customCell("rfc", "center"),
  },
  {
    name: "NRO. DE SERVICIO",
    width: "10%",
    center: true,
    key: "serviceNumber",
    cell: customCell("serviceNumber", "center"),
  },
  {
    name: "NOMBRE COMERCIAL",
    width: "15%",
    center: true,
    key: "tradeName",
    cell: customCell("tradeName", "center"),
  },
  {
    name: "REGIÓN",
    width: "10%",
    center: true,
    key: "region",
    cell: customCell("region", "center"),
  },
  {
    name: "TIPO DE CLIENTE",
    width: "10%",
    center: true,
    key: "clientType",
    cell: customCell("clientType", "center", (text) =>
      text.replace("NONFISCAL", "NO FISCAL")
    ),
  },
  {
    name: "SALDO",
    width: "10%",
    center: true,
    key: "balance",
    cell: (row) => (
      <>
        {row.balance != "NA" ? (
          row.balance > 0 ? (
            <span
              style={{ color: "red", fontWeight: 600 }}
              color="#d9534f"
              size={20}
            >
              $ {getMxFormatNumber(row.balance)}
            </span>
          ) : (
            <span style={{ color: "green", fontWeight: 600 }} size={20}>
              $ {getMxFormatNumber(row.balance * -1)}
            </span>
          )
        ) : (
          row.balance
        )}
      </>
    ),
  },
  {
    name: "ACCIONES",
    width: "10%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {/*<ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.service(row);
          }}
          icon={faMoneyBill}
        />
         <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.mail(row);
          }}
          icon={faMailBulk}
        />*/}
        <Tooltip title="Ver detalle de cliente">
          <Eye
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.details(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>

        {row.idService && (
          <Tooltip title="Ver detalle de Servicio">
            <Wallet
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.serviceDetails(row);
              }}
            />
          </Tooltip>
        )}
        {row.idService && (
          <Tooltip title="Enviar Correo">
            <Mail
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.mail(row);
              }}
            />
          </Tooltip>
        )}
        {row.isActive ? (
          <Tooltip title="Dar de baja">
            <Slash
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.deactivate(row);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Dar de alta">
            <Check
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.deactivate(row);
              }}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsInvoices = (funcs, { allSelected }) => {
  const { screenWidth } = funcs;
  return [
    {
      name: <Checkbox checked={allSelected} onChange={funcs.checkAll} />,
      width: "50px",
      center: true,
      cell: (row) => {
        return (
          <Checkbox
            checked={row.selected}
            onChange={funcs.onSelectCheck(row)}
          />
        );
      },
    },
    {
      name: "RAZÓN SOCIAL / SERVICIO",
      width: "200px",
      center: true,
      key: "nameClient",
      cell: (row) => {
        return (
          <div className="w-full">
            <span className="text-sm">{row.nameClient}</span>
            <br />
            <b className="text-md">{row.nameService}</b>
          </div>
        );
      },
    },
    {
      name: "TIPO DE CLIENTE",
      width: "100px",
      center: true,
      key: "clientType",
      cell: customCell("clientType", "center", (t) => (
        <span className="text-xs">
          {t === "NONFISCAL" ? "NO FISCAL" : "FISCAL"}
        </span>
      )),
    },
    {
      name: "FACTURA",
      width: "150px",
      center: true,
      key: "invoiceFolioSes",
      cell: customCell("invoiceFolioSes", "center", (t) => (t ? t : "-")),
    },
    {
      name: "PERIODO",
      width: "150px",
      center: true,
      key: "fiscalPeriod",
      cell: customCell("fiscalPeriod"),
    },
    {
      name: "IMPORTE X PAGAR",
      width: "120px",
      center: true,
      key: "amount",
      cell: (row) => {
        let { amount, missingAmount } = row;
        missingAmount = missingAmount === null ? amount : missingAmount;
        const percentage = Math.floor(
          (Number(missingAmount.replace(",", "")) * 100) /
            Number(amount.replace(",", ""))
        );
        return (
          <div className="w-full">
            <div>
              <ProgressBar
                className="w-full payment-progress"
                completed={percentage}
              />
            </div>
            <div className="text-center">
              <span className="test-xs">$ {missingAmount}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "ESTATUS FACT",
      width: "200px",
      center: true,
      key: "catStatusInvoiceService",
      cell: (row) => {
        return (
          <PaymentLabel
            text={row.catStatusInvoiceService}
            id={row.idCatStatusInvoiceService}
            parserColor={getColorInvoice}
          />
        );
      },
    },
    {
      name: "ESTATUS PAGO",
      width: "150px",
      center: true,
      key: "catStatusPaymentService",
      cell: (row) => {
        return (
          <PaymentLabel
            text={row.catStatusPaymentService}
            id={row.idCatStatusPaymentService}
            parserColor={getColorPayment}
          />
        );
      },
    },
    {
      name: "RECURRENTE",
      width: "150px",
      center: true,
      key: "isRecurring",
      cell: customCell("isRecurring", "center", (t) => (
        <span className="text-xs">{t === 1 ? "SI" : "NO"}</span>
      )),
    },
    {
      name: "COMPLEMENTO",
      width: "200px",
      center: true,
      key: "catStatusPaymentComplementService",
      cell: (row) => {
        return (
          <PaymentLabel
            text={row.catStatusPaymentComplementService}
            id={row.idCatStatusPaymentComplementService}
            parserColor={getColorPaymentComplement}
          />
        );
      },
    },
    {
      name: "ACCIONES",
      flex: 1,
      center: true,
      overflow: "scroll",
      className: "containerAction",
      cell: (row) =>
        row.isActive ? (
          <>
            {!row.distributeAmounts ? (
              <>
                <Tooltip title="Ver detalle de Cliente">
                  <Contact
                    color="#2A2F99"
                    size={25}
                    className="m-auto action-table"
                    style={{ cursor: "pointer" }}
                    onClick={funcs.details(row)}
                  />
                </Tooltip>
                {[1, 4].includes(row.idCatStatusInvoiceService) && (
                  <Tooltip title="Editar Servicio">
                    <Edit
                      color="#2A2F99"
                      size={25}
                      className="m-auto action-table"
                      style={{ cursor: "pointer" }}
                      onClick={funcs.edit(row)}
                    />
                  </Tooltip>
                )}
                {[2, 3].includes(row.idCatStatusInvoiceService) && (
                  <Tooltip
                    title={`Ver ${
                      row.clientTypeService === "FISCAL"
                        ? "Factura"
                        : "Remisión"
                    }`}
                  >
                    <Eye
                      color="#2A2F99"
                      size={25}
                      className="m-auto action-table"
                      onClick={funcs.invoiceDetails(row)}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
                {[2, 3].includes(row.idCatStatusInvoiceService) && (
                  <>
                    {row.invoice && (
                      <Tooltip title={`Descargar XML`}>
                        <div>
                          <BsFiletypeXml
                            color="#2A2F99"
                            size={22}
                            className="m-auto action-table"
                            onClick={funcs.downloadXML(row)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {(row.urlInvoice || row.urlRemission) && (
                      <Tooltip title={`Descargar PDF`}>
                        <div>
                          <BsFilePdf
                            color="#2A2F99"
                            size={22}
                            className="m-auto action-table"
                            onClick={() => funcs.downloadPDF(row)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}

                {row.urlNonFiscalInvoice && (
                  <Tooltip title={`Ver Factura`}>
                    <View
                      color="#2A2F99"
                      size={25}
                      className="m-auto action-table"
                      onClick={funcs.invoiceDetails({
                        ...row,
                        urlInvoice: row.urlNonFiscalInvoice,
                      })}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}

                <Tooltip title="Ver pagos">
                  <Landmark
                    color="#2A2F99"
                    size={25}
                    className="m-auto action-table"
                    style={{ cursor: "pointer" }}
                    onClick={funcs.listPayments(row)}
                  />
                </Tooltip>

                {![3, 4].includes(row.idCatStatusPaymentService) &&
                row.clientTypeService === "FISCAL" &&
                row.idCatStatusInvoiceService === 2 ? (
                  <Tooltip title="Registrar nota de crédito">
                    <StickyNote
                      color="#2A2F99"
                      size={25}
                      className="m-auto action-table"
                      style={{ cursor: "pointer" }}
                      onClick={funcs.creditNote(row)}
                    />
                  </Tooltip>
                ) : null}
                {row.clientTypeService === "FISCAL" && (
                  <Tooltip title="Ver notas de crédito">
                    <ClipboardList
                      color="#2A2F99"
                      size={25}
                      className="m-auto action-table"
                      style={{ cursor: "pointer" }}
                      onClick={funcs.openCreditNotes(row)}
                    />
                  </Tooltip>
                )}

                {row.clientTypeService === "FISCAL" &&
                  row.idCatStatusInvoiceService === 2 &&
                  !row.urlPaymentComplement && (
                    <Tooltip title="Cancelar factura">
                      <FileX
                        color="#2A2F99"
                        size={25}
                        className="m-auto action-table"
                        style={{ cursor: "pointer" }}
                        onClick={funcs.cancel(row)}
                      />
                    </Tooltip>
                  )}

                {row.clientTypeService === "NONFISCAL" &&
                  row.urlNonFiscalInvoice && (
                    <Tooltip title="Cancelar factura">
                      <FileX
                        color="#2A2F99"
                        size={25}
                        className="m-auto action-table"
                        style={{ cursor: "pointer" }}
                        onClick={funcs.cancelGeneral(row)}
                      />
                    </Tooltip>
                  )}

                {[2, 3, 4].includes(row.idCatStatusInvoiceService) &&
                  [1, 2, 5].includes(row.idCatStatusPaymentService) &&
                  !row.urlPaymentComplement &&
                  row.clientTypeService === "FISCAL" && (
                    <Tooltip title={"Refacturar"}>
                      <FileOutput
                        color="#2A2F99"
                        size={25}
                        className="m-auto action-table"
                        style={{ cursor: "pointer" }}
                        onClick={funcs.rebill(row)}
                      />
                    </Tooltip>
                  )}

                {row.clientTypeService === "NONFISCAL" &&
                  row.idCatStatusInvoiceService === 3 &&
                  !row.urlNonFiscalInvoice &&
                  [3, 4].includes(row.idCatStatusPaymentService) && (
                    <Tooltip title={"Facturar remisión"}>
                      <Files
                        color="#2A2F99"
                        size={25}
                        className="m-auto action-table"
                        style={{ cursor: "pointer" }}
                        onClick={funcs.remissionInvoice(row)}
                      />
                    </Tooltip>
                  )}
                {row.clientTypeService === "NONFISCAL" &&
                  row.idCatStatusInvoiceService === 3 && (
                    <Tooltip title={"Cancelar Remisión"}>
                      <FileX
                        color="#2A2F99"
                        size={25}
                        className="m-auto action-table"
                        style={{ cursor: "pointer" }}
                        onClick={funcs.cancelRemission(row)}
                      />
                    </Tooltip>
                  )}
                {row.isEvent &&
                row.idCatStatusInvoiceService !== 2 &&
                ![3, 6].includes(row.idCatStatusPaymentService) ? (
                  <Tooltip title={"Cancelar servicio"}>
                    <div>
                      <Slash
                        color="#2A2F99"
                        size={25}
                        className="m-auto action-table"
                        style={{ cursor: "pointer" }}
                        onClick={() => funcs.cancelEvent(row)}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            ) : null}
            {row.distributeAmounts ? (
              <>
                <Tooltip title="Ver detalle de Cliente">
                  <Contact
                    color="#2A2F99"
                    size={25}
                    className="m-auto action-table"
                    style={{ cursor: "pointer" }}
                    onClick={funcs.details(row)}
                  />
                </Tooltip>
                <Tooltip title={"Visualizar tabla de subclientes"}>
                  <div
                    onClick={funcs.openSubclientsDatatable(row)}
                    style={{ cursor: "pointer" }}
                  >
                    <TbUsers size={25} />
                  </div>
                </Tooltip>
                {[1, 4].includes(row.idCatStatusInvoiceService) ? (
                  <Tooltip title="Editar Servicio">
                    <Edit
                      color="#2A2F99"
                      size={25}
                      className="m-auto action-table"
                      style={{ cursor: "pointer" }}
                      onClick={funcs.edit(row)}
                    />
                  </Tooltip>
                ) : null}
              </>
            ) : null}

            {!row.isEvent ? (
              <Tooltip
                title={`${
                  row.isRecurring ? "Desactivar" : "Activar"
                } recurrencia`}
              >
                <div style={{ cursor: "pointer" }}>
                  {row.isRecurring ? (
                    <TbClockPause
                      size={25}
                      onClick={funcs.changeRecurrency(row)}
                    />
                  ) : (
                    <TbClockPlay
                      size={25}
                      onClick={funcs.changeRecurrency(row)}
                    />
                  )}
                </div>
              </Tooltip>
            ) : (
              <></>
            )}
            {row.isActive &&
              !row.isEvent &&
              [1, 4].includes(row.idCatStatusInvoiceService) &&
              [1, 2, 5].includes(row.idCatStatusPaymentService) && (
                <Tooltip title="Cancelar servicio">
                  <Slash
                    style={{ cursor: "pointer" }}
                    onClick={funcs.cancelService(row)}
                  />
                </Tooltip>
              )}
          </>
        ) : null,
    },
  ];
};

export const getColumnsSubclients = (funcs) => {
  const { screenWidth, listPayments, sendEmail } = funcs;
  return [
    {
      name: "RAZÓN SOCIAL / SERVICIO",
      width: screenWidth < 1920 ? "200px" : "13%",
      center: true,
      key: "nameDetailService",
      cell: (row) => {
        return (
          <div className="w-full">
            <span className="text-md">{row.tradeNameDetailService}</span> <br />
            <b className="text-sm">{row.nameDetailService}</b>
          </div>
        );
      },
    },
    {
      name: "TIPO",
      width: screenWidth <= 1920 ? "100px" : "7%",
      center: true,
      key: "clientTypeDetailService",
      cell: (row) => (
        <span>
          {row.clientTypeDetailService === "NONFISCAL" ? "NO FISCAL" : "FISCAL"}
        </span>
      ),
    },
    {
      name: "FACTURA",
      width: screenWidth < 1920 ? "150px" : "9%",
      center: true,
      key: "internalFolio",
      cell: customCell("internalFolio", "center", (t) => (t ? t : "-")),
    },
    {
      name: "PERIODO",
      width: screenWidth < 1920 ? "150px" : "10%",
      center: true,
      key: "fiscalPeriod",
      cell: customCell("fiscalPeriod", "center"),
    },
    {
      name: "IMPORTE A PAGAR",
      width: screenWidth < 1920 ? "120px" : "7%",
      center: true,
      key: "missingAmount",
      cell: (row) => {
        let { amount, missingAmount } = row;
        missingAmount = missingAmount === null ? amount : missingAmount;
        const percentage = Math.floor(
          (Number(missingAmount.replace(",", "")) * 100) /
            Number(amount.replace(",", ""))
        );
        return (
          <div className="w-full">
            <div>
              <ProgressBar
                className="w-full payment-progress"
                completed={percentage}
              />
            </div>
            <div className="text-center">
              <span className="test-xs">$ {missingAmount}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "ESTATUS FACT",
      width: screenWidth < 1920 ? "150px" : "10%",
      center: true,
      key: "catStatusInvoiceService",
      cell: (row) => {
        return (
          <PaymentLabel
            text={row.catStatusInvoiceService}
            id={row.idCatStatusInvoiceService}
            parserColor={getColorInvoice}
          />
        );
      },
    },
    {
      name: "ESTATUS PAGO",
      width: screenWidth < 1920 ? "150px" : "10%",
      center: true,
      key: "catStatusPaymentService",
      cell: (row) => {
        return (
          <PaymentLabel
            text={row.catStatusPaymentService}
            id={row.idCatStatusPaymentService}
            parserColor={getColorPayment}
          />
        );
      },
    },
    {
      name: "COMPLEMENTO",
      width: screenWidth < 1920 ? "150px" : "8%",
      center: true,
      key: "catStatusPaymentComplementService",
      cell: (row) => {
        return (
          <PaymentLabel
            text={row.catStatusPaymentComplementService}
            id={row.idCatStatusPaymentComplementService}
            parserColor={getColorPaymentComplement}
          />
        );
      },
    },
    {
      name: "RECURRENTE",
      width: screenWidth < 1920 ? "150px" : "6%",
      center: true,
      key: "isRecurring",
      cell: customCell("isRecurring", "center", (t) => (
        <span className="text-xs">{t === 1 ? "SI" : "NO"}</span>
      )),
    },

    {
      name: "ACCIONES",
      flex: 1,
      center: true,
      className: "containerAction",
      cell: (row) => (
        <>
          {(row.clientTypeDetailService === "FISCAL" &&
            row.idCatStatusInvoiceService !== 2) ||
          (row.clientTypeDetailService === "NONFISCAL" &&
            row.idCatStatusInvoiceService !== 3) ? (
            <Tooltip
              title={`${
                row.clientTypeDetailService === "FISCAL"
                  ? "Facturar"
                  : "Remisionar"
              } parcialidad`}
            >
              <div>
                <TbFileInvoice
                  color="#2A2F99"
                  size={25}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={funcs.bill(row)}
                />
              </div>
            </Tooltip>
          ) : null}
          {[2, 3].includes(row.idCatStatusInvoiceService) && (
            <Tooltip
              title={`Ver ${
                row.clientTypeService === "FISCAL" ? "Factura" : "Remisión"
              }`}
            >
              <Eye
                color="#2A2F99"
                size={25}
                className="m-auto action-table"
                onClick={funcs.invoiceDetails(row)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {[2, 3].includes(row.idCatStatusInvoiceService) &&
            row.idCfdiProvider && (
              <Tooltip title="Descargar XML">
                <div>
                  <BsFiletypeXml
                    color="#2A2F99"
                    size={25}
                    className="m-auto action-table"
                    onClick={() => funcs.downloadXML(row)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Tooltip>
            )}
          {(([2, 3].includes(row.idCatStatusInvoiceService) &&
            row.urlInvoice) ||
            row.urlRemission) && (
            <Tooltip title="Descargar PDF">
              <div>
                <BsFiletypePdf
                  color="#2A2F99"
                  size={25}
                  className="m-auto action-table"
                  onClick={() => funcs.downloadPDF(row)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Tooltip>
          )}
          {row.clientTypeDetailService === "FISCAL" &&
            row.idCatStatusInvoiceService === 2 && (
              <Tooltip title="Cancelar factura">
                <FileX
                  color="#2A2F99"
                  size={25}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.cancelInvoice(row)}
                />
              </Tooltip>
            )}
          {row.clientTypeDetailService === "NONFISCAL" &&
            row.idCatStatusInvoiceService === 3 && (
              <Tooltip title="Cancelar remisión">
                <FileX
                  color="#2A2F99"
                  size={25}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.cancelRemission(row)}
                />
              </Tooltip>
            )}
          {![3, 4].includes(row.idCatStatusPaymentService) &&
          row.clientTypeDetailService === "FISCAL" &&
          row.idCatStatusInvoiceService === 2 ? (
            <>
              {
                <Tooltip title="Refacturar">
                  <FileInput
                    color="#2A2F99"
                    size={25}
                    className="m-auto action-table"
                    style={{ cursor: "pointer" }}
                    onClick={() => funcs.rebill(row)}
                  />
                </Tooltip>
              }
              <Tooltip title="Registrar nota de crédito">
                <StickyNote
                  color="#2A2F99"
                  size={25}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.registerCreditNote(row)}
                />
              </Tooltip>
            </>
          ) : null}
          {row.clientTypeDetailService === "FISCAL" && (
            <Tooltip title="Ver notas de crédito">
              <ClipboardList
                color="#2A2F99"
                size={25}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={() => funcs.getCreditNotes(row)}
              />
            </Tooltip>
          )}
          {/* {[2, 3].includes(row.idCatStatusInvoiceService) ? (
            <Tooltip title="Enviar E-mail" style={{ cursor: "pointer" }}>
              <div>
                <BsEnvelope
                  size={25}
                  onClick={() => {
                    sendEmail(row);
                  }}
                />
              </div>
            </Tooltip>
          ) : null} */}
          <Tooltip title="Ver pagos" style={{ cursor: "pointer" }}>
            <Landmark
              onClick={() => {
                listPayments(row);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];
};

export const getColumnsNewUser = (funcs) => [
  {
    name: "MÓDULO",
    center: true,
    width: "25%",
    key: "idFunction",
    cell: (row) => <div>{row.module}</div>,
  },
  {
    name: "SUBMÓDULO",
    center: true,
    width: "25%",
    key: "idFunction",
    cell: (row) => <div>{row.submodule}</div>,
  },
  {
    name: "PERMISO",
    center: true,
    width: "35%",
    key: "name",
    cell: (row) => <div>{row.name}</div>,
  },
  {
    name: <ToggleLeft color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "createFunction",
    cell: (row) => {
      return (
        <Switch
          inputProps={{ "aria-label": "Switch demo" }}
          onChange={funcs.handleActiveChange(row)}
          disabled={funcs.disabledSwitchs}
          checked={row.visibility}
        />
      );
    },
  },
];

export const getColumnsNewUserSubmodule = (funcs) => [
  {
    name: "",
    center: true,
    width: "8.4%",
    key: "idSpatial",
    cell: (row) => <div></div>,
  },
  {
    name: "FUNCIONALIDAD",
    center: true,
    width: "30%",
    key: "idFunction",
    cell: (row) => (
      <div>
        <span
          className="ml-1"
          style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}
        >
          {row.idFunction}
        </span>
      </div>
    ),
  },
  {
    name: <Eye color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "watchFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <PlusCircle color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "createFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: (
      <Tooltip title="Editar Usuario">
        <Edit color="white" size={28} className="m-auto" />
      </Tooltip>
    ),
    width: "15%",
    center: true,
    key: "editFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <Trash2 color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "deleteFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
];

export const getColumnsEditUser = (funcs) => [
  {
    name: "FUNCIONALIDAD",
    center: true,
    width: "30%",
    key: "idFunction",
    cell: (row) => (
      <div>
        <span
          className="ml-1"
          style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}
        >
          {row.idFunction}
        </span>
      </div>
    ),
  },
  {
    name: <Eye color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "watchFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <PlusCircle color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "createFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: (
      <Tooltip title="Editar Usuario">
        <Edit color="white" size={28} className="m-auto" />
      </Tooltip>
    ),
    width: "15%",
    center: true,
    key: "editFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <Trash2 color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "deleteFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
];

export const getColumnsPersonal = (funcs) => [
  {
    name: "NÚM. EMPLEADO",
    center: true,
    width: "5%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "15%",
    center: true,
    key: "name",
    cell: customCell("name"),
  },
  {
    name: "ÚLTIMO SERVICIO",
    width: "10%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "SUPERVISOR",
    width: "10%",
    center: true,
    key: "supervisor",
    cell: customCell("supervisor"),
  },
  {
    name: "FECHA INGRESO",
    width: "10%",
    center: true,
    key: "admissionDate",
    cell: customCell("admissionDate", "center"),
  },
  {
    name: "FECHA BAJA",
    width: "11%",
    center: true,
    key: "inactiveDate",
    cell: customCell("inactiveDate", "center", (t) => t || "-"),
  },
  {
    name: "ROL",
    width: "10%",
    center: true,
    key: "role",
    cell: customCell("role", "center", (t) => t || "-"),
  },
  {
    name: "ESTATUS",
    width: "9%",
    center: true,
    key: "status",
    cell: (row) => {
      return row.isActive === 1 ? (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="activePerson"></div>
          Activo
        </div>
      ) : (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="inactivePerson"></div>
          Inactivo
        </div>
      );
    },
  },
  {
    name: "ACCIONES",
    width: "20%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {row.isActive ? (
          <>
            <Tooltip title="Asignar servicio">
              <ShieldCheck
                color="#2A2F99"
                size={20}
                className="m-auto"
                onClick={() => {
                  funcs.modalAssignService(row);
                }}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip title="Registrar incidencias">
              <CalendarDays
                color="#2A2F99"
                size={20}
                style={{ cursor: "pointer" }}
                className="m-auto"
                onClick={() => {
                  funcs.modalIncidenceLog(row);
                }}
              />
            </Tooltip>
            {funcs.userPermissions.includes(10) ? (
              <Tooltip title="Registrar días de vacaciones">
                <Luggage
                  color="#2A2F99"
                  size={20}
                  style={{ cursor: "pointer" }}
                  className="m-auto"
                  onClick={() => {
                    funcs.modalVacations(row);
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}

            <Tooltip title="Registro de compensaciones">
              <Gift
                color="#2A2F99"
                size={20}
                style={{ cursor: "pointer" }}
                className="m-auto"
                onClick={() => {
                  funcs.modalCompensations(row);
                }}
              />
            </Tooltip>
            {funcs.userPermissions.includes(11) ? (
              <Tooltip title="Registro de doblete">
                <FilePlus2
                  color="#2A2F99"
                  size={20}
                  style={{ cursor: "pointer" }}
                  className="m-auto"
                  onClick={() => {
                    funcs.modalDoubletRegister(row);
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <Tooltip title="Ver archivos adjuntos">
          <Paperclip
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalAttachedFiles(row);
            }}
          />
        </Tooltip>
        <Tooltip title="Ver detalle de empleado">
          <Info
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
          />
        </Tooltip>
        {row.isActive === 1 ? (
          <Tooltip title="Dar de baja">
            <Slash
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.modalEgress(row);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Reingresar al sistema">
            <CheckCircle2
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.modalEntrance(row);
              }}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsServicesHistoric = (funcs) => [
  {
    name: "NÚM. EMPLEADO",
    center: true,
    width: "9%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "15%",
    center: true,
    key: "nameEmployee",
    cell: customCell("nameEmployee"),
  },
  {
    name: "SERVICIO",
    width: "15%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "FECHA DE INICIO",
    width: "13%",
    center: true,
    key: "startDate",
    cell: customCell("startDate", "center"),
  },
  {
    name: "FECHA DE TÉRMINO",
    width: "13%",
    center: true,
    key: "endDate",
    cell: customCell("endDate", "center"),
  },
  {
    name: "TURNO",
    width: "13%",
    center: true,
    key: "shift",
    cell: customCell("shift", "center"),
  },
  {
    name: "ESTATUS",
    width: "13%",
    center: true,
    key: "isActive",
    cell: (row) => {
      return row.isActive === 1 ? (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="activePerson"></div>
          Activo
        </div>
      ) : (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="inactivePerson"></div>
          Inactivo
        </div>
      );
    },
  },
  {
    name: "DETALLE",
    width: "9%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <Tooltip title="Ver detalle de servicio">
          <Info
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
          />
        </Tooltip>
      </>
    ),
  },
];

export const getColumnsUsers = (funcs) => [
  {
    name: "NOMBRE DEL USUARIO",
    center: true,
    width: "16%",
    key: "name",
    cell: customCell("name"),
  },
  {
    name: "CORREO ELECTRÓNICO",
    width: "18%",
    center: true,
    key: "email",
    cell: customCell("email"),
  },
  {
    name: "ROL",
    width: "12%",
    center: true,
    key: "rol",
    cell: customCell("rol"),
  },
  {
    name: "ÚLTIMO INICIO DE SESIÓN",
    width: "14%",
    center: true,
    key: "lastSession",
    cell: customCell("lastSession", "center", (t) =>
      t ? parseObjectDate(new Date(t), 2) : "-"
    ),
  },
  {
    name: "ÚLTIMA ACCIÓN",
    width: "16%",
    center: true,
    key: "lastAction",
    cell: customCell("lastAction", "center", (t) => t || "-"),
  },
  {
    name: "ESTATUS",
    width: "12%",
    center: true,
    key: "status",
    cell: (row) => {
      const isActive = row.status === "Activo";
      const isBlocked = row.status === "Bloqueado";
      const color = isActive ? "success" : "error";
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          {/* <div
            className={
              row.status === "Activo" ? "activePerson" : "inactivePerson"
            }
          ></div>
          */}
          <Switch
            color={color}
            checked={isActive}
            disabled={isBlocked}
            onChange={funcs.activeChange(row)}
          />
          <br />
          <span className="text-xs">{row.status}</span>
        </div>
      );
    },
  },
  /*{
    name: "PERMISOS",
    width: "12%",
    center: true,
    key: "permissions",
    cell: (row) => {
      return (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div>{row.permissions}</div>
          <div style={{ color: "blue", cursor: "pointer" }}>Ver detalle</div>
        </div>
      );
    },
  },*/
  {
    name: "ACCIONES",
    width: "12%",
    center: true,
    className: "containerAction",
    cell: (row) => {
      const isBlocked = row.status === "Bloqueado";
      return (
        <>
          <Tooltip title="Editar usuario">
            <Edit
              color="#2A2F99"
              size={20}
              className="m-auto"
              onClick={funcs.edit(row)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
          {!isBlocked && (
            <Tooltip title="Bloquear usuario">
              <Slash
                color="#2A2F99"
                size={20}
                className="m-auto"
                onClick={funcs.block(row)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {/* <Tooltip title="Activar/Desactivar">
          <Switch defaultChecked={row.status == 1} />
    </Tooltip>*/}
        </>
      );
    },
  },
];
export const getColumnsPayments = (funcs, firstIdBankDeposit) => [
  {
    name: "",
    center: true,
    key: "watchFunction",
    cell: (row) => {
      return (
        <Checkbox
          disabled={
            firstIdBankDeposit
              ? firstIdBankDeposit !== row.idBankDeposit ||
                row.hasPaymentComplement
              : row.hasPaymentComplement
          }
          checked={row.selected}
          onChange={({ target: { checked } }) => {
            funcs.selectPaymentComplement(row, checked);
          }}
        />
      );
    },
  },
  {
    name: "NOMBRE DE DEPÓSITO",
    center: true,
    width: "400px",
    key: "depositDetail",
    cell: (row) => <div>{row.depositDetail}</div>,
  },
  {
    name: "FECHA DE PAGO",
    center: true,
    width: "150px",
    key: "paymentDate",
    cell: customCell("paymentDate", "center", (t) =>
      parseObjectDate(new Date(t.split("T")[0] + "T12:00:00"))
    ),
  },
  {
    name: "MONTO",
    width: "200px",
    center: true,
    key: "amount",
    cell: customCell("amount", "center", (t) => t + " $"),
  },
  {
    name: "ESTATUS",
    center: true,
    key: "amount",
    cell: (row) => {
      return row.isActive ? "Activo" : "Cancelado";
    },
  },
  {
    name: "COMPLEMENTO DE PAGO",
    width: "200px",
    center: true,
    key: "hasPaymentComplement",
    cell: (row) => {
      return row.hasPaymentComplement ? (
        <Check color="green" />
      ) : (
        <X color="red" />
      );
    },
  },
  {
    name: "ACCIONES",
    center: true,
    flex: 1,
    cell: (row) => {
      return (
        <div style={{ display: "flex", gap: 10 }}>
          {row.urlXml ? (
            <Tooltip title="Descargar XML">
              <div>
                <BsFiletypeXml
                  color="#2A2F99"
                  size={20}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.downloadXML(row)}
                />
              </div>
            </Tooltip>
          ) : null}
          {row.urlPaymentComplement ? (
            <Tooltip title="Descargar PDF">
              <div>
                <BsFilePdf
                  color="#2A2F99"
                  size={20}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.downloadPDF(row)}
                />
              </div>
            </Tooltip>
          ) : null}
          {row.hasPaymentComplement && row.urlPaymentComplement ? (
            <Tooltip title="Ver complemento de pago">
              <FileDigit
                color="#2A2F99"
                size={20}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={funcs.paymentDocument(row)}
              />
            </Tooltip>
          ) : null}
          {row.hasPaymentComplement ? (
            <Tooltip title="Cancelar Complemento Pago">
              <FileX2
                color="#2A2F99"
                size={20}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={funcs.cancelPaymentComplement(row)}
              />
            </Tooltip>
          ) : null}
          {row.isActive && !row.uuidPaymentComplementService ? (
            <Tooltip title="Cancelar pago">
              <XCircle
                color="#2A2F99"
                size={20}
                className="m-auto"
                onClick={() => funcs.delete(row)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          ) : null}
        </div>
      );
    },
  },
];

export const getColumnsCreditNotes = (funcs) => [
  {
    name: "MONTO",
    width: "33%",
    center: true,
    key: "amount",
    cell: customCell("amount", "center", (t) => "$" + t),
  },
  {
    name: "ESTATUS",
    width: "34%",
    center: true,
    key: "amount",
    cell: (row) => {
      return row.isActive ? "Activo" : "Cancelado";
    },
  },
  {
    name: "ACCIONES",
    width: "33%",
    center: true,
    className: "containerAction",
    cell: (row) => {
      return (
        <>
          {row.urlXml ? (
            <Tooltip title="Descargar XML">
              <div>
                <BsFiletypeXml
                  color="#2A2F99"
                  size={20}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.downloadXML(row.urlXml)}
                />
              </div>
            </Tooltip>
          ) : null}
          {row.urlInvoice ? (
            <Tooltip title="Descargar PDF">
              <div>
                <BsFilePdf
                  color="#2A2F99"
                  size={20}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={() => funcs.downloadPDF(row.urlInvoice)}
                />
              </div>
            </Tooltip>
          ) : null}
          {row.isActive && row.urlInvoice ? (
            <div style={{ display: "flex" }}>
              <Tooltip title="Ver nota de crédito">
                <FileDigit
                  color="#2A2F99"
                  size={20}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={funcs.paymentDocument(row)}
                />
              </Tooltip>
              <Tooltip title="Cancelar nota de crédito">
                <FileX2
                  color="#2A2F99"
                  size={20}
                  className="m-auto action-table"
                  style={{ cursor: "pointer" }}
                  onClick={funcs.cancelCreditNote(row)}
                />
              </Tooltip>
            </div>
          ) : null}
        </>
      );
    },
  },
];

export const getColumnsDeposits = (funcs) => [
  {
    name: "FECHA",
    center: true,
    width: "200px",
    key: "depositDate",
    cell: customCell("depositDate", "center", (t) =>
      parseObjectDate(new Date(t), 1, true)
    ),
  },
  {
    name: "BANCO EMISOR",
    width: "200px",
    center: true,
    key: "emitterBank",
    cell: customCell("emitterBank", "center", (t) => t || "N/A"),
  },
  {
    name: "BANCO RECEPTOR",
    width: "200px",
    center: true,
    key: "receptorBank",
    cell: customCell("receptorBank", "center", (t) => t || "N/A"),
  },
  {
    name: "MONTO",
    width: "200px",
    center: true,
    key: "amountDeposit",
    cell: customCell("amountDeposit", "center", (t) => "$" + t),
  },
  {
    name: "FACTURAS/REMISIONES PAGADAS",
    width: "300px",
    center: true,
    key: "invoices",
    cell: (row) => <div> {row.invoices || "N/A"} </div>,
  },

  {
    name: "SALDO A FAVOR",
    width: "200px",
    center: true,
    key: "positiveBalance",
    cell: (row) => {
      return <div>{row.positiveBalance}</div>;
    },
  },
  {
    name: "ACCIONES",
    minWidth: "100px",
    flex: 1,
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <Tooltip title="Cancelar depósito">
          <XCircle
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={funcs.delete(row)}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        {/* <Tooltip title="Historial de pagos">
          <FileClock
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() =>
              Swal.fire("Historial de pagos", "botón presionado", "success")
            }
            style={{ cursor: "pointer" }}
          />
        </Tooltip> */}
      </>
    ),
  },
];

export const getColumnsPaySheet = (funcs) => [
  {
    name: "CAS",
    center: true,
    width: "6%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "10%",
    center: true,
    key: "employeeName",
    cell: customCell("employeeName"),
  },
  {
    name: "SERVICIO",
    width: "8%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "ZONA",
    width: "5%",
    center: true,
    key: "zone",
    cell: customCell("zone"),
  },
  {
    name: "TURNOS",
    width: "10%",
    center: true,
    key: "shift",
    cell: customCell("shift", "center"),
  },
  {
    name: "SUELDO MENSUAL",
    width: "10%",
    center: true,
    key: "monthlyNetSalary",
    cell: customCell("monthlyNetSalary", "center"),
  },
  {
    name: "SUELDO QUINCENAL",
    width: "10%",
    center: true,
    key: "payrollAmount",
    cell: customCell("payrollAmount", "center"),
  },
  {
    name: "SUELDO DIARIO",
    width: "10%",
    center: true,
    className: "dailyNetSalary",
    cell: customCell("dailyNetSalary", "center"),
  },
  {
    name: "COMPENSACIÓN QUINCENAL",
    width: "12%",
    center: true,
    key: "biweeklyCompensation",
    cell: customCell("biweeklyCompensation", "center"),
  },
  {
    name: "AYUDA DE TRANSPORTE",
    width: "10%",
    center: true,
    key: "transportHelp",
    cell: customCell("transportHelp", "center"),
  },
  {
    name: "DIAS LABORADOS",
    width: "10%",
    center: true,
    key: "workedDays",
    cell: customCell("workedDays", "center"),
  },
  {
    name: "FALTAS/ INCAPACIDADES",
    width: "20",
    center: true,
    key: "absencesDisabilitiesNumber",
    cell: customCell("absencesDisabilitiesNumber", "center"),
  },
  {
    name: "IMPORTE POR FALTAS",
    width: "10%",
    center: true,
    key: "absencesDisabilities",
    cell: customCell("absencesDisabilities", "center"),
  },
  {
    name: "OTRO DESCUENTO",
    width: "10%",
    center: true,
    key: "otherDiscounts",
    cell: customCell("otherDiscounts", "center"),
  },
  {
    name: "VACACIONES",
    width: "10%",
    center: true,
    key: "holidays",
    cell: customCell("holidays", "center"),
  },
  {
    name: "PRIMA VACACIONAL",
    width: "10%",
    center: true,
    key: "holidayAmount",
    cell: customCell("holidayAmount", "center"),
  },
  {
    name: "AGUINALDO",
    width: "10%",
    center: true,
    key: "endYearBonus",
    cell: customCell("endYearBonus", "center"),
  },
  {
    name: "OBSERVACIONES",
    width: "10%",
    center: true,
    key: "comments",
    cell: customCell("comments", "center", (t) => t || "No hay observaciones"),
  },
  {
    name: "FECHA DE INGRESO",
    width: "12%",
    center: true,
    key: "entryDate",
    cell: customCell("entryDate", "center"),
  },
  {
    name: "TOTAL A PAGAR",
    width: "15%",
    center: true,
    key: "payrollAmount",
    cell: (row) => {
      return (
        <div>
          <div>{row.payrollAmount}</div>
          <div>{row.catStatusPaymentPayroll}</div>
        </div>
      );
    },
  },
  {
    name: "NÚMERO DE CUENTA",
    width: "10%",
    center: true,
    key: "accountNumber",
    cell: customCell("accountNumber", "center"),
  },
  {
    name: "ACCIONES",
    width: "8%",
    center: true,
    key: "actions",
    cell: (row) => (
      <>
        <Tooltip title="Registrar incidencias">
          <CalendarDays
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalIncidenceLog(row);
            }}
          />
        </Tooltip>
        <Tooltip title="Detalle de empleado">
          <Info
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Cambiar estatus de pago">
          <CheckCircle2
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalPaysheetStatus(row);
            }}
          />
        </Tooltip>
      </>
    ),
  },
];

export const getColumnsDoubletView = (funcs) => [
  {
    name: "CAS",
    center: true,
    width: "8%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "15%",
    center: true,
    key: "employeeName",
    cell: customCell("employeeName"),
  },
  {
    name: "SERVICIO",
    width: "10%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "ZONA",
    width: "7%",
    center: true,
    key: "zone",
    cell: customCell("zone", "center"),
  },
  {
    name: "24 HRS",
    width: "6%",
    center: true,
    key: "count24",
    cell: customCell("count24", "center"),
  },
  {
    name: "$550",
    width: "6%",
    center: true,
    key: "amount24",
    cell: customCell("amount24", "center", (r) => `${r} $`),
  },
  {
    name: "12 HRS",
    width: "6%",
    center: true,
    key: "count12",
    cell: customCell("count12", "center"),
  },
  {
    name: "$280",
    width: "6%",
    center: true,
    key: "amount12",
    cell: customCell("amount12", "center", (r) => `${r} $`),
  },
  {
    name: "FMF",
    width: "6%",
    center: true,
    key: "countFMF",
    cell: customCell("countFMF", "center"),
  },
  {
    name: "$350",
    width: "6%",
    center: true,
    key: "amountFMF",
    cell: customCell("amountFMF", "center", (r) => `${r} $`),
  },
  {
    name: "APOYO COMIDA",
    width: "10%",
    center: true,
    key: "foodHelpAmount",
    cell: customCell("foodHelpAmount", "center", (r) => `${r} $`),
  },
  {
    name: "TOTAL DE APOYOS DE COMIDA",
    width: "10%",
    center: true,
    key: "totalFoodHelpAmount",
    cell: customCell("totalFoodHelpAmount", "center", (r) => `${r}`),
  },
  {
    name: "ACCIONES",
    width: "8%",
    center: true,
    key: "actions",
    cell: (row) => (
      <>
        <Tooltip title="Registrar incidencias">
          <CalendarDays
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalIncidenceLog(row);
            }}
          />
        </Tooltip>
        <Tooltip title="Detalle de empleado">
          <Info
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Cambiar estatus de pago">
          <CheckCircle2
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalPaysheetStatus(row);
            }}
          />
        </Tooltip>
      </>
    ),
  },
];

export const getColumnsServicesToPay = (funcs, { deposits }) => [
  {
    name: <Checkbox onChange={funcs.checkAll} />,
    width: "50px",
    center: true,
    cell: (row) => {
      return (
        <Checkbox
          checked={row.selected}
          onChange={(e) => {
            funcs.handleAmountsChange(
              {
                selected: e.target.checked,
                idDetailService: row.idDetailService,
              },
              row.missingAmount.replace(",", ""),
              !e.target.checked
            );
            funcs.onSelectCheck(row, e.target.checked);
          }}
        />
      );
    },
  },
  {
    name: "SERVICIO",
    key: "nameDetailService",
    cell: (row) => (
      <div>
        {row.tradeNameDetailService} <br />
        <b>{row.nameDetailService}</b>
      </div>
    ),
  },
  {
    name: "ESTATUS PAGO",
    width: "150px",
    center: true,
    key: "catStatusPaymentService",
    cell: (row) => {
      return (
        <PaymentLabel
          text={row.catStatusPaymentService}
          id={row.idCatStatusPaymentService}
          parserColor={getColorPayment}
        />
      );
    },
  },
  {
    name: "SUBCLIENTE",
    center: true,
    key: "isSubclient",
    cell: (row) =>
      row.isSubclient ? <Check color="green" /> : <X color="red" />,
  },
  {
    name: "TIPO",
    center: true,
    key: "clientTypeDetailService",
    cell: (row) => {
      const internalFolio = row.prefix + row.internalFolio;
      return (
        <div>
          {internalFolio
            ? internalFolio + " - " + row.paymentMethod
            : row.paymentMethod}
        </div>
      );
    },
  },
  {
    name: "PERIODO",
    center: true,
    key: "fiscalPeriod",
    cell: ({ fiscalPeriod }) => {
      return <div className="text-center">{fiscalPeriod}</div>;
    },
  },
  {
    name: "MONTO TOTAL DEL SERVICIO",
    center: true,
    key: "totalAmount",
    cell: (row) => <div>{row.amount}</div>,
  },
  {
    name: "MONTO RESTANTE",
    center: true,
    key: "missingAmount",
    cell: (row) => <div>{row.missingAmount}</div>,
  },
  {
    name: "MONTO A PAGAR",
    center: true,
    key: "amount",
    width: "300px",
    cell: (row) => {
      const getAmount = () => {
        const correctObject = deposits.find(
          (el) => row.idDetailService === el.idDetailService
        );
        return correctObject?.amount;
      };
      return (
        <div>
          <DgInput
            disabled={!row.selected}
            onChange={({ target: { value } }) =>
              funcs.handleAmountsChange(row, value, false)
            }
            value={getAmount()}
            placeholder={row.missingAmount}
            iconName={"DollarSign"}
            containerStyle={{ margin: 0 }}
            step={0.001}
            type="number"
          />
        </div>
      );
    },
  },
];

export const getColumnsPositiveDeposits = (funcs) => [
  {
    name: <Checkbox onChange={funcs.checkAll} />,
    width: "50px",
    center: true,
    cell: (row) => {
      return (
        <Checkbox
          checked={row.selected}
          onChange={(e) => {
            funcs.applyDeposit(row, e.target.checked);
          }}
        />
      );
    },
  },
  {
    name: "FECHA",
    center: true,
    key: "depositDate",
    cell: (row) => <div>{row.depositDate}</div>,
  },
  {
    name: "NOMBRE",
    center: true,
    key: "emitterBank",
    cell: (row) => <div>{row.emitterBank}</div>,
  },

  {
    name: "MONTO",
    center: true,
    key: "positiveBalance",
    cell: (row) => <div>{row.positiveBalance}</div>,
  },
  {
    name: "DESCRIPCIÓN",
    center: true,
    key: "typeBankDeposit",
    cell: (row) => {
      return (
        <div>
          {row.typeBankDeposit ? "Abonar para " + row.typeBankDeposit : "N/A"}
        </div>
      );
    },
  },
  {
    name: "ACCIONES",
    center: true,
    key: "actions",
    cell: (row) => {
      return (
        <>
          {row.isActive ? (
            <Tooltip style={{ cursor: "pointer" }} title="Cancelar depósito">
              <Slash onClick={() => funcs.cancelDeposit(row)} />
            </Tooltip>
          ) : null}
        </>
      );
    },
  },
];

export const getColumnsServicesPayedWithCertainDeposit = (funcs) => [
  {
    name: "NOMBRE DE SERVICIO",
    center: true,
    key: "nameDetailService",
    cell: (row) => <div>{row.nameDetailService}</div>,
  },
  {
    name: "MONTO",
    center: true,
    key: "amountPaid",
    cell: (row) => <div>${row.amountPaid}</div>,
  },
  {
    name: "TIENE COMPLEMENTO DE PAGO",
    center: true,
    key: "hasPaymentComplement",
    cell: (row) =>
      row.hasPaymentComplement ? <Check color="green" /> : <X color="red" />,
  },
  {
    name: "ACCIONES",
    flex: 1,
    center: true,
    key: "actions",
    cell: (row) => (
      <>
        {row.hasPaymentComplement ? (
          <Tooltip title="Cancelar complemento de pago">
            <div>
              <TbCurrencyDollarOff
                color="#2A2F99"
                size={20}
                style={{ cursor: "pointer" }}
                className="m-auto"
                onClick={() => {
                  funcs.cancelPaymentComplement(row);
                }}
              />
            </div>
          </Tooltip>
        ) : null}
      </>
    ),
  },
];

export const getInvoicingHistoryColumns = (funcs) => [
  {
    name: "CLIENTE",
    center: true,
    key: "client",
    cell: (row) => <div>{row.client}</div>,
  },
  {
    name: "SUBCLIENTE",
    center: true,
    key: "subClient",
    cell: (row) => <div>{row.subClient}</div>,
  },
  {
    name: "FOLIO INTERNO",
    center: true,
    key: "folio",
    cell: (row) => <div>{row.folio}</div>,
  },
  {
    name: "TIPO DE CFDI",
    center: true,
    key: "catCfdiType",
    cell: (row) => <div>{row.catCfdiType}</div>,
  },
  {
    name: "MONTO",
    center: true,
    key: "amount",
    cell: (row) => <div>{row.amount}</div>,
  },
  {
    name: "STATUS DE CFDI",
    center: true,
    key: "status",
    cell: (row) => (row.status ? <Check color="green" /> : <X color="red" />),
  },
  {
    name: "ESTADO DE CANCELACIÓN SAT",
    center: true,
    key: "canceledStatusProvider",
    cell: (row) => row.canceledStatusProvider,
  },
  {
    name: "FECHA DE GENERACIÓN",
    center: true,
    key: "insertDate",
    cell: (row) => <div>{parseObjectDate(new Date(row.insertDate))}</div>,
  },
  {
    name: "USUARIO QUE LA GENERÓ",
    center: true,
    key: "insertUser",
    cell: (row) => <div>{row.insertUser}</div>,
  },
  {
    name: "USUARIO QUE LA CANCELÓ",
    center: true,
    key: "inactiveUser",
    cell: (row) => <div>{row.inactiveUser}</div>,
  },
  {
    name: "FOLIO DE CFDI RELACIONADO",
    center: true,
    key: "relatedFolio",
    cell: (row) => <div>{row.relatedFolio}</div>,
  },
  {
    name: "ACCIONES",
    center: true,
    key: "actions",
    cell: (row) => {
      return (
        <div className="flex gap-2 items-center">
          {row.urlInvoice ? (
            <>
              <Tooltip style={{ cursor: "pointer" }} title="Ver CFDI">
                <Eye onClick={() => funcs.viewCFDI(row)} />
              </Tooltip>
            </>
          ) : null}
          {(row.cfdiType === "remission" || "invoice") && row.isActive ? (
            <Tooltip style={{ cursor: "pointer" }} title="Enviar correo">
              <div>
                <BsEnvelope size={20} onClick={() => funcs.sendEmail(row)} />
              </div>
            </Tooltip>
          ) : null}
          {row.commentInactive ? (
            <Tooltip
              style={{ cursor: "pointer" }}
              title="Ver descripcion de cancelación"
            >
              <File
                onClick={() =>
                  Swal.fire("Descripcion", row.commentInactive, "info")
                }
              />
            </Tooltip>
          ) : null}

          {row.idCatCfdiType === 1 && row.isActive && row.isGeneralInvoice ? (
            <Tooltip
              style={{ cursor: "pointer" }}
              title="Cancelar factura general"
            >
              <FileX onClick={() => funcs.cancelGeneralInvoice(row)} />
            </Tooltip>
          ) : null}
          {!row.isActive ? (
            <Tooltip
              style={{ cursor: "pointer" }}
              title="Descargar acuse de cancelación"
            >
              <FileDown
                onClick={() =>
                  funcs.downloadCancelledInvoice(row.idCfdiProvider)
                }
              />
            </Tooltip>
          ) : null}
          {row.canceledStatusProvider == "pending" ? (
            <Tooltip
              style={{ cursor: "pointer" }}
              title="Cambiar estado de cancelación"
            >
              <FileX2
                onClick={() => funcs.removeCancellation(row.idCfdiProvider)}
              />
            </Tooltip>
          ) : null}
        </div>
      );
    },
  },
];

export const getColumnsGeneralInvoice = (funcs) => [
  {
    name: "",
    width: "50px",
    center: true,
    cell: (row) => {
      return (
        <Checkbox
          checked={row.selected}
          onChange={({ target: { checked } }) => {
            funcs.selectService(row, checked);
          }}
        />
      );
    },
  },
  {
    name: "NOMBRE DE CLIENTE",
    center: true,
    key: "clientName",
    cell: (row) => <div>{row.clientName}</div>,
  },
  {
    name: "NOMBRE DEL SERVICIO",
    center: true,
    key: "nameDetailService",
    cell: (row) => row.nameDetailService,
  },
  {
    name: "PERIODO",
    center: true,
    key: "fiscalPeriod",
    cell: (row) => row.fiscalPeriod,
  },
  {
    name: "FECHA DE PAGO",
    center: true,
    key: "paymentDate",
    cell: (row) => parseObjectDate(new Date(row.paymentDate)),
  },
  {
    name: "MONTO",
    center: true,
    key: "amount",
    cell: (row) => <div>${row.amount}</div>,
  },
];
