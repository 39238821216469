import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "../../helpers/useForm";
import { getColumnsInvoices } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import {
  downloadBlobRes,
  getOperatingYearsCat,
  handleDownloadClick,
  parseCat,
} from "../../helpers/utils";
import { useSelector } from "react-redux";
import { TODAY } from "../../helpers/consts";
import { clientTypes, monthsEs } from "../../helpers/enums";
import { DollarSign, FileInput, Files, MailPlus } from "lucide-react";
import { Tooltip } from "@mui/material";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import DgTable from "../../components/DgTable";
import Swal from "sweetalert2";
import ModalPaymentStatus from "./modals/ModalPaymentStatus";
import ModalDocumentDetails from "./modals/ModalDoumentDetails";
import ModalServiceDetails from "./modals/ModalServiceDetails";
import ModalDetails from "./modals/ModalDetails";
import ModalCancel from "./modals/ModalCancel";
import ModalRe from "./modals/ModalRe";
import ModalFilterClients from "./modals/ModalFilterClients";
import ModalRemissionInvoice from "./modals/ModalRemissionInvoice";
import ModalServicePayments from "./modals/ModalServicePayments";
import ModalServiceCreditNotes from "./modals/ModalServiceCreditNotes";
import ModalCreditNote from "./modals/ModalCreditNote";
import DgLoader from "../../components/DgLoader";
import useGetScreenWidth from "../../helpers/useGetScreenWidth";
import { BsCalendar } from "react-icons/bs";
import ModalEvents from "./modals/ModalEvents";
import ModalSendEmail from "./modals/ModalSendEmail";
import ModalSubclients from "./modals/ModalSubclients";
import ModalServiceDeposits from "./modals/ModalServiceDeposits";
import { handleCancelCfdi } from "./handlers";
import { FileClock } from "lucide-react";
import ModalInvoicingHistory from "./ModalInvoicingHistory";
import { TbFileDiff, TbFileInvoice } from "react-icons/tb";
import ModalGeneralInvoice from "./modals/ModalGeneralInvoice";
import ModalInvoiceExternalData from "./modals/ModalInvoiceExternalData";

const localPrefix = "invoicing";
const filtersSchema = {
  year: TODAY.getFullYear(),
  month: (TODAY.getMonth() + 1 + "").padStart(2, "0"),
  clients: "",
  idCatStatusPaymentService: "",
  idCatStatusInvoiceService: "",
  idFiscalPeriod: "",
  clientType: "",
  clientTypeService: "",
  idCatRegion: "",
};

const setStoredFilters = () => {
  const storedOb = {};
  Object.keys(filtersSchema).forEach((k) => {
    let storedVal = localStorage.getItem(`${localPrefix}_${k}`);
    if (storedVal && k !== "clients") {
      storedOb[k] = storedVal;
    }
  });
  return { ...filtersSchema, ...storedOb };
};
const Invoicing = () => {
  const [screenWidth] = useGetScreenWidth();
  const navigate = useNavigate();
  const [dataCopy, setDataCopy] = useState([]);
  const [data, setData] = useState([]);
  const [clientFilterValue, setClientFilterValue] = useState("");
  const [singleData, setSingleData] = useState({});
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generalInvoiceModal, setGeneralInvoiceModal] = useState(false);
  const [invoicingHistoryModal, setInvoicingHistoryModal] = useState(false);
  const [cancelRemissionModal, setCancelRemissionModal] = useState(false);
  const [invoiceWithOtherData, setInvoiceWithOtherData] = useState(false);
  const [modalOpenPayment, setModalOpenPayment] = useState(false);
  const [modalOpenDocs, setModalOpenDocs] = useState(false);
  const [modalOpenServ, setModalOpenServ] = useState(false);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [modalOpenCancel, setModalOpenCancel] = useState(false);
  const [modalOpenRe, setModalOpenRe] = useState(false);
  const [modalOpenEvents, setModalOpenEvents] = useState(false);
  const [modalOpenRemissionInvoice, setModalOpenRemissionInvoice] =
    useState(false);
  const [modalOpenServicePayments, setModalOpenServicePayments] =
    useState(false);
  const [modalOpenServiceCreditNotes, setModalOpenServiceCreditNotes] =
    useState(false);
  const [modalOpenFilterClients, setModalOpenFilterClients] = useState(false);
  const [modalOpenNewCreditNote, setModalOpenNewCreditNote] = useState(false);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  const [modalOpenSubclients, setModalOpenSubclients] = useState(false);
  const [modalDeposit, setModalDeposit] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);
  const [filters, setFilters, _, setAllFilters] = useForm(setStoredFilters);
  const { CatStatusPaymentService, CatStatusInvoiceService, catRegions } =
    useSelector((s) => s.catalogues);

  /*Logical funcs*/
  const funcs = {
    screenWidth: screenWidth,
    documents: (row) => () => {
      setSingleData(row);
      setModalOpenDocs(true);
    },
    details:
      ({ idClient }) =>
        () => {
          navigate("/detalle-cliente/" + idClient);
        },
    edit: (row) => () => {
      setSingleData(row);
      setModalOpenServ(true);
    },
    payment: (row) => () => {
      setSingleData(row);
      setModalOpenPayment(true);
    },
    listPayments: (row) => () => {
      setSingleData(row);
      setModalOpenServicePayments(true);
    },
    onSelectCheck: (row) => () => {
      const { idService, idClient } = row;
      let ix;
      data.find(({ idService: idS, idClient: idC }, i) => {
        ix = i;
        return idS === idService && idC === idClient;
      });
      const newData = [...data];
      const client = newData[ix];
      client.selected = !client.selected;
      newData[ix] = client;
      setData([...newData]);
    },
    invoiceDetails: (row) => () => {
      setSingleData(row);
      setModalOpenInvoice(true);
    },
    downloadXML: (row) => () => {
      handleDownloadClick(row.urlXml, setLoading);
    },
    downloadPDF: (row) => {
      const url = row.urlInvoice || row.urlRemission;
      handleDownloadClick(url, setLoading);
    },
    cancel: (row) => () => {
      setSingleData(row);
      setModalOpenCancel(true);
    },
    cancelGeneral: (row) => () => {
      handleGeneralInvoiceDelete(row);
    },
    rebill: (row) => () => {
      setSingleData(row);
      setModalOpenRe(true);
    },
    download: (row) => () => {
      downloadBlobRes(row.urlInvoice);
    },
    cancelPayment: (row) => () => {
      handlePaymentDelete(row);
    },
    checkAll: () => {
      const newSel = !allSelected;
      const newData = data.map((d) => ({ ...d, selected: newSel }));
      setAllSelected(newSel);
      setData(newData);
    },
    remissionInvoice: (row) => () => {
      setSingleData(row);
      setModalOpenRemissionInvoice(true);
    },
    openCreditNote: (row) => () => {
      const { urlCreditNote } = row;
      setSingleData({
        ...row,
        isInvoice: true,
        urlInvoice: urlCreditNote,
        name: "Nota de crédito",
      });
      setModalOpenInvoice(true);
    },
    creditNote: (row) => () => {
      setSingleData(row);
      setModalOpenNewCreditNote(true);
    },
    openCreditNotes: (row) => () => {
      setSingleData(row);
      setModalOpenServiceCreditNotes(true);
    },
    cancelRemission: (row) => () => {
      setSingleData(row);
      setCancelRemissionModal((closed) => !closed);
    },
    cancelEvent: (row) => () => {
      Swal.fire({
        title: "¿Confirma la siguiente operación?",
        html: `Se dará de baja al evento ${row.nameService}`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then(async () => {
        const res = await Request(
          `/invoice/event/${row.idService}`,
          {},
          "PATCH"
        );
        if (res.ok) {
          Swal.fire({
            title: "Éxito",
            html: res.msg,
            icon: "success",
          });
          fetchData(filters);
        } else {
          Swal.fire({
            title: "Error",
            html: res.msg,
            icon: "error",
          });
        }
      });
    },
    cancelService: (row) => () => {
      Swal.fire({
        title: "¿Confirma la siguiente operación?",
        html: `Se cancelará el servicio ${row.nameService}`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          setLoading(true);
          const res = await Request(`/service/${row.idService}`, {}, "PATCH");
          setLoading(false);

          if (res.ok) {
            Swal.fire({
              title: "Éxito",
              html: res.msg,
              icon: "success",
            });
            fetchData(filters);
          } else {
            Swal.fire({
              title: "Error",
              html: res.msg,
              icon: "error",
            });
          }
        }
      });
    },
    changeRecurrency: (row) => () => {
      Swal.fire({
        title: "¿Confirma la siguiente operación?",
        html: `Se ${row.isRecurring ? "desactivará" : "activará"
          } la recurrencia del servicio ${row.nameService}`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          setLoading(true);
          const res = await Request(
            `/service/recurrence/${row.idService}`,
            { isRecurring: !row.isRecurring },
            "PUT"
          );
          setLoading(false);
          if (res.ok) {
            Swal.fire({
              title: "Éxito",
              html: res.msg,
              icon: "success",
            });
            fetchData(filters);
          } else {
            Swal.fire({
              title: "Error",
              html: res.msg,
              icon: "error",
            });
          }
        }
      });
    },
    openSubclientsDatatable: (row) => () => {
      setModalOpenSubclients(true);
      setSingleData(row);
    },
  };
  const fetchFiscalPeriods = async (filters) => {
    const { year, month } = filters;
    const skipValues = ["", "all"];
    if (!skipValues.includes(year) && !skipValues.includes(month)) {
      const { ok, data } = await Request(
        `/user/fiscalPeriods?limit=6&all=true&year=${year}&month=${month}`
      );
      if (ok) {
        setFiscalPeriodList(data);

        const localidFiscalPeriod = localStorage.getItem(
          `${localPrefix}_idFiscalPeriod`
        );
        if (!localidFiscalPeriod || localidFiscalPeriod === "") {
          const lastPeriod = data[1].idFiscalPeriod;
          setFilters({ target: { name: "idFiscalPeriod", value: lastPeriod } });
        }
      }
    } else {
      setFiscalPeriodList([]);
    }
  };
  const fetchData = useCallback(async (filters) => {
    if (filters.idFiscalPeriod !== "") {
      const filterStr = Object.keys(filters)
        .map((k) => `${k}=${filters[k]}`)
        .join("&");
      const res = await Request(`/invoice/service?${filterStr}`);
      if (res.ok) {
        const invoiceList = res.data.map((f) => ({ ...f, selected: false }));
        setData(invoiceList);
      }
    }
  }, []);

  const fetchClients = async () => {
    const resC = await Request(`/client`);
    if (resC.ok) {
      setClients(resC.data.map((c) => ({ ...c, selected: false })));
    }
  };

  const getSelectedServicesToInvoice = () => {
    //const validateNotSubclient = (service) => !service.distributeAmounts;
    const filteredData = data.filter(
      (d) => d.selected && [1, 4].includes(d.idCatStatusInvoiceService)
    );
    return filteredData;
  };
  const getServiceToExternalInvoice = () => {
    const filteredData = data.filter(
      (d) => d.selected && [1, 4].includes(d.idCatStatusInvoiceService)
    );
    return filteredData;
  };

  const getSelectedServicesLength = () =>
    data.filter((singleData) => singleData.selected).length;
  const getSelectedServicesToAdvance = () => {
    return data.filter((d) => d.selected);
  };
  const getSelectedServicesToEmail = () => {
    const serviceToReturn = data.filter(
      (singleData) =>
        singleData.selected &&
        [2, 3].includes(singleData.idCatStatusInvoiceService)
    );
    if (serviceToReturn.length == 1) {
      return serviceToReturn[0];
    } else return null;
  };

  const filterMonths = () => {
    const currYear = TODAY.getFullYear();
    return monthsEs.filter(({ value }) =>
      parseInt(filters.year) === currYear ? parseInt(value) : true
    );
  };
  const storeFilters = () => {
    Object.keys(filters).forEach((k) => {
      localStorage.setItem(`${localPrefix}_${k}`, filters[k]);
    });
  };

  const handlePaymentDelete = (service) => {
    const { idService } = service;
    Swal.fire({
      title: "¿Confirma la siguiente operación?",
      text: `Se cencelará el pago, esta acción es irreversible.`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          `/service/statuspayment/${idService}`,
          {},
          "PATCH"
        );
        if (ok) {
          Swal.fire("Éxito", "Pago cancelado con éxito.", "success").then(
            () => {
              fetchData(filters);
            }
          );
        }
      }
    });
  };
  const handleGeneralInvoiceDelete = (service) => {
    const { idService } = service;
    Swal.fire({
      title: "¿Confirma la siguiente operación?",
      text: `Se cancelará la factura general, esta acción es irreversible.`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          `/invoice/generalinvoice/${idService}`,
          {},
          "PATCH"
        );
        if (ok) {
          Swal.fire(
            "Éxito",
            "Factura general cancelada con éxito.",
            "success"
          ).then(() => {
            fetchData(filters);
          });
        }
      }
    });
  };

  /* State hooks */
  useEffect(() => {
    fetchData(filters);
  }, [filters]);
  useEffect(() => {
    fetchFiscalPeriods(filters);
    const { year, month, idFiscalPeriod } = filters;
    if (year === "all" && (month !== "all" || idFiscalPeriod !== "all")) {
      setAllFilters({ ...filters, month: "all", idFiscalPeriod: "all" });
    }
    if (month === "all" && idFiscalPeriod !== "all") {
      setAllFilters({ ...filters, idFiscalPeriod: "all" });
    }
  }, [filters.year, filters.month]);
  useEffect(() => {
    setAllFilters({ ...filters, idFiscalPeriod: "all" });
  }, [filters.month]);
  useEffect(() => {
    storeFilters();
  }, [filters]);
  useEffect(() => {
    setStoredFilters();
    fetchClients();
  }, []);

  useEffect(() => {
    if (clientFilterValue) {
      const filterValues = clientFilterValue
        .split(",")
        .map((value) => value.trim().toUpperCase());
      setDataCopy(
        data.filter(({ nameClient, nameService }) => {
          return filterValues.some(
            (filterValue) =>
              nameClient?.toUpperCase().includes(filterValue) ||
              nameService?.toUpperCase().includes(filterValue)
          );
        })
      );
    } else {
      setDataCopy(data);
    }
  }, [data, clientFilterValue]);

  // Render Consts
  const servicesToInvoice = getSelectedServicesToInvoice();
  const servicesToAdvance = getSelectedServicesToAdvance();
  return (
    <Skinpage pageTitle={"Facturación"}>
      <div className="grid grid-cols-5 gap-4" style={{ alignItems: "end" }}>
        <DgInput
          type="select"
          label="Año de consulta"
          onChange={setFilters}
          options={[
            { value: "all", label: "Todos" },
            ...getOperatingYearsCat(),
          ]}
          name="year"
          value={filters.year}
          placeholder="Seleccionar año"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Mes de consulta"
          onChange={setFilters}
          options={[{ value: "all", label: "Todos" }, ...filterMonths()]}
          name="month"
          value={filters.month}
          placeholder="Seleccionar mes"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Periodo"
          onChange={setFilters}
          options={[
            { value: "all", label: "Todos" },
            ...parseCat(fiscalPeriodList, "name", "idFiscalPeriod"),
          ]}
          name="idFiscalPeriod"
          value={filters.idFiscalPeriod}
          placeholder="Seleccionar periodo"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Estatus Facturación"
          options={[
            { value: "", label: "Todos" },
            ...parseCat(
              CatStatusInvoiceService,
              "name",
              "idCatStatusInvoiceService"
            ),
          ]}
          onChange={setFilters}
          name="idCatStatusInvoiceService"
          value={filters.idCatStatusInvoiceService}
          placeholder="Seleccionar estatus"
          iconName="Files"
        />
        <DgInput
          type="select"
          label="Estatus Pago"
          options={[
            { value: "", label: "Todos" },
            ...parseCat(
              CatStatusPaymentService,
              "name",
              "idCatStatusPaymentService"
            ),
          ]}
          onChange={setFilters}
          name="idCatStatusPaymentService"
          value={filters.idCatStatusPaymentService}
          placeholder="Seleccionar estatus"
          iconName="Files"
        />
      </div>
      <div className="grid grid-cols-1 gap-4" style={{ alignItems: "end" }}>
        <div className="grid grid-cols-3 gap-4">
          <DgInput
            type="text"
            label="Filtrar Clientes"
            name="clientsList"
            value={clientFilterValue}
            placeholder="Filtrar Clientes"
            iconName="Users"
            onChange={({ target: { value } }) => {
              setClientFilterValue(value);
            }}
          />
          <DgInput
            type="select"
            label="Tipo de cliente"
            onChange={setFilters}
            options={[{ value: "all", label: "Todos" }, ...clientTypes]}
            name="clientType"
            value={filters.clientType} // TODO: REPLACE THIS VALUE FOR clientTypeService ?
            placeholder="Seleccionar tipo de cliente"
            iconName="User"
          />
          <DgInput
            type="select"
            label="Región"
            onChange={setFilters}
            options={[
              { value: "all", label: "Todos" },
              ...parseCat(catRegions, "name", "idCatRegion"),
            ]}
            name="idCatRegion"
            value={filters.idCatRegion}
            placeholder="Seleccionar región"
            iconName="Map"
          />
        </div>
        <div className="grid grid-cols-8 gap-4">
          <Button
            disabled={!servicesToInvoice.length}
            onClick={() => {
              const services = servicesToInvoice;
              services.length > 0
                ? navigate("/facturar", { state: services })
                : navigate("/facturar");
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Facturar / remisionar"}>
              <Files
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
          <Button
            onClick={() => {
              setGeneralInvoiceModal((closed) => !closed);
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Factura general"}>
              <div>
                <TbFileInvoice
                  size={30}
                  className="m-auto action-table w-full"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Tooltip>
          </Button>
          <Button
            disabled={getServiceToExternalInvoice().length != 1}
            onClick={() => {
              setInvoiceWithOtherData((closed) => !closed);
              setSingleData(getServiceToExternalInvoice()[0]);
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Facturar con otros datos fiscales"}>
              <div>
                <TbFileDiff
                  size={30}
                  className="m-auto action-table w-full"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Tooltip>
          </Button>
          <Button
            disabled={
              servicesToAdvance.length > 1 || servicesToAdvance.length === 0
            }
            onClick={() => {
              navigate("/adelantar-factura", {
                state: servicesToAdvance,
              });
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title="Generar servicio">
              <FileInput
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>

          <Button
            onClick={() => {
              setModalOpenEvents(true);
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Facturar evento"}>
              <div>
                <BsCalendar
                  size={30}
                  className="m-auto action-table w-full"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Tooltip>
          </Button>
          <Button
            disabled={!getSelectedServicesToEmail()}
            onClick={() => {
              setModalSendEmail(true);
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Enviar correo"}>
              <MailPlus
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>

          <Button
            onClick={() => {
              setModalDeposit((modalDeposit) => !modalDeposit);
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Registrar depósito"}>
              <DollarSign
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
          <Button
            onClick={() => {
              setInvoicingHistoryModal((closed) => !closed);
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Historial de facturación"}>
              <FileClock
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
        </div>
      </div>
      <br />
      <DgTable
        data={dataCopy}
        columnsDef={getColumnsInvoices(funcs, { allSelected })}
        className="invoicing"
      />
      <ModalPaymentStatus
        isOpen={modalOpenPayment}
        toggle={() => {
          setModalOpenPayment(!modalOpenPayment);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalDocumentDetails
        isOpen={modalOpenDocs}
        toggle={() => {
          setModalOpenDocs(!modalOpenDocs);
        }}
        data={singleData}
      />
      <ModalSubclients
        open={modalOpenSubclients}
        toggle={() => setModalOpenSubclients((currentState) => !currentState)}
        idService={singleData.idService}
        fetchInvoicingTableData={() => {
          fetchData(filters);
        }}
      />
      <ModalServiceDetails
        isOpen={modalOpenServ}
        toggle={() => {
          setModalOpenServ(!modalOpenServ);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={singleData}
      />
      <ModalEvents
        clients={clients}
        open={modalOpenEvents}
        toggle={setModalOpenEvents}
        fetchData={() => fetchData(filters)}
      />

      <ModalCancel
        isOpen={cancelRemissionModal}
        toggle={() => {
          setCancelRemissionModal((closed) => !closed);
        }}
        onConfirm={(epBody) => {
          handleCancelCfdi(
            singleData,
            {
              epBody,
              endPoint: "/invoice/remission/detailService/",
              cfdiId: "idDetailService",
              setLoading: setLoading,
            },
            () => {
              fetchData(filters);
              setCancelRemissionModal(false);
            },
            {
              text: "Cancelar remisión",
              onSuccessMessage: "Remisión cancelada con éxito!",
            }
          );
        }}
        data={singleData}
        showForm={false}
        showReasons={false}
        loading={loading}
      />
      <ModalCancel
        isOpen={modalOpenCancel}
        toggle={() => {
          setModalOpenCancel(!modalOpenCancel);
        }}
        onConfirm={(epBody) => {
          handleCancelCfdi(
            singleData,
            {
              epBody,
              endPoint: "/invoice/detailService/",
              cfdiId: "idDetailService",
              setLoading: setLoading,
            },
            () => {
              fetchData(filters);
              setModalOpenCancel(false);
            }
          );
        }}
        data={singleData}
        filterCancelMethodBy={"applyInvoice"}
        loading={loading}
      />
      <ModalServiceDeposits
        toggle={() => setModalDeposit((modalDeposit) => !modalDeposit)}
        open={modalDeposit}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalRe
        isOpen={modalOpenRe}
        toggle={() => {
          setModalOpenRe(!modalOpenRe);
        }}
        fetchData={() => {
          fetchData(filters);
        }}
        data={singleData}
      />
      <ModalFilterClients
        isOpen={modalOpenFilterClients}
        toggle={() => {
          setModalOpenFilterClients(!modalOpenFilterClients);
        }}
        data={clients}
        setFilters={setFilters}
        setClients={setClients}
      />
      <ModalRemissionInvoice
        isOpen={modalOpenRemissionInvoice}
        toggle={() => {
          setModalOpenRemissionInvoice(!modalOpenRemissionInvoice);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalServicePayments
        isOpen={modalOpenServicePayments}
        toggle={() => {
          setModalOpenServicePayments(!modalOpenServicePayments);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalServiceCreditNotes
        isOpen={modalOpenServiceCreditNotes}
        toggle={() => {
          setModalOpenServiceCreditNotes(!modalOpenServiceCreditNotes);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalCreditNote
        isOpen={modalOpenNewCreditNote}
        toggle={() => {
          setModalOpenNewCreditNote(!modalOpenNewCreditNote);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalSendEmail
        toggle={() => setModalSendEmail((modalSendEmail) => !modalSendEmail)}
        isOpen={modalSendEmail}
        endPoint={`/client/mailBill`}
        idService={getSelectedServicesToEmail()?.idDetailService}
        externalEmails={{
          administratorEmail: getSelectedServicesToEmail()?.administratorEmail,
          counterEmail: getSelectedServicesToEmail()?.counterEmail,
        }}
        cfdiType={getSelectedServicesToEmail()?.cfdiType}
      />
      <ModalInvoicingHistory
        isOpen={invoicingHistoryModal}
        toggle={() => setInvoicingHistoryModal((prevState) => !prevState)}
      />
      <ModalGeneralInvoice
        isOpen={generalInvoiceModal}
        toggle={() => setGeneralInvoiceModal((prevState) => !prevState)}
      />
      <ModalInvoiceExternalData
        open={invoiceWithOtherData}
        toggle={() => setInvoiceWithOtherData((prevData) => !prevData)}
      />
      <DgLoader open={loading} />
    </Skinpage>
  );
};

export default Invoicing;
