import { GENERAL_LOGIN, GENERAL_LOGOUT } from "../actions";

const INITIAL_STATE = {
  logedIn: false,
  data: {},
};

export const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GENERAL_LOGIN:
      return {
        ...state,
        logedIn: true,
        data: payload,
      };
    case GENERAL_LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
