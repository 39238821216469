import React, { useCallback, useEffect, useState } from "react";
import Skinpage from "../../components/Skinpage";
import Grid from "@mui/material/Grid";
import {
  PlusCircle,
  ClipboardList,
  Paperclip,
  Edit,
  MessageSquare,
  Mail,
  Landmark,
} from "lucide-react";
import iconoDetalle from "../../css/img/IconoDetalleCliente.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NewService from "./client-detail-tabs/NewService";
import ListServices from "./client-detail-tabs/ServicesList";
import Documents from "./client-detail-tabs/Documents";
import DetailClientInfo from "./client-detail-tabs/DetailClientInfo";
import Comments from "./client-detail-tabs/Comments";
import SesTabPanel from "../../components/SesTabPanel";
import ClientSendEmail from "./client-detail-tabs/ClientSendEmail";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../../helpers/api";
import { clientDetailSchema } from "../../helpers/schemas";
import { parseObjectDate } from "../../helpers/utils";
import { Tooltip } from "@mui/material";
import ClientDeposit from "./client-detail-tabs/ClientDeposit";
import Title from "../../components/Title";

const DetailClient = () => {
  const { id: idClient } = useParams();
  const [data, setData] = useState(clientDetailSchema);
  const [loading, setLoading] = useState(true);
  const [loadingComment, setLoadingComment] = useState(true);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchData = useCallback(async () => {
    if (idClient) {
      const res = await Request("/client/" + idClient);
      if (res.ok) {
        setData(res.data);
      }
    } else {
      navigate.goBack();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, loadingComment]);

  useEffect(() => {
    fetchData();
  }, [fetchData, loading]);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <Skinpage>
      <Title>DETALLE DE CLIENTE</Title>
      <Grid container spacing={2} style={{ marginTop: "0.3vw" }}>
        <Grid item xs={6}>
          <div
            style={{
              width: "100%",
              boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              border: "solid 0.5px lightgrey",
              borderRadius: "1vw",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: 20,
                backgroundColor: "#2A2F99",
                borderTopLeftRadius: "1vw",
                borderBottomLeftRadius: "1vw",
              }}
            />
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                height: "fit-content",
                marginTop: "0.01rem",
                marginLeft: "0.01rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1vw",
                }}
              >
                {/* <Skeleton variant="circular" width={80} height={80} /> */}
                <img src={iconoDetalle} style={{ width: "7rem" }} />
              </div>

              <div
                style={{
                  color: "#2A2F99",
                  padding: "1rem",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                  alignSelf: "center",
                }}
              >
                {data.name || " - "}
              </div>

              <div style={{ padding: "1rem 1rem 0rem 1rem" }}>
                <div style={{ fontWeight: 500, marginBottom: "20px" }}>
                  {data.rfc ? `RFC: ${data.rfc}` : ""}
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "0.9rem",
                  }}
                >
                  {`${data.street ? `Calle: ${data.street}` + "," : ""} ${
                    data.outdoorNumber ? data.outdoorNumber + "," : ""
                  } ${data.internalNumber ? `${data.internalNumber},` : ""} ${
                    data.suburb ? `Colonia: ${data.suburb},` : ""
                  } ${
                    data.postalCode ? `Código postal: ${data.postalCode}, ` : ""
                  } ${
                    data.municipality ? `Municipio: ${data.municipality}, ` : ""
                  } ${data.state ? `Estado: ${data.state}` : ""}`}
                </div>
              </div>
              <div style={{ padding: 16 }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="Secciones de detalle de cliente"
                >
                  <Tab
                    className="tabsDetalleC"
                    label={
                      <Tooltip title="Alta de servicio">
                        <PlusCircle
                          color="black"
                          size={28}
                          className="m-auto"
                        />
                      </Tooltip>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tabsDetalleC"
                    label={
                      <Tooltip title="Lista de servicios">
                        <ClipboardList
                          color="black"
                          size={28}
                          className="m-auto"
                        />
                      </Tooltip>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="tabsDetalleC"
                    label={
                      <Tooltip title="Documentos de cliente">
                        <Paperclip color="black" size={28} className="m-auto" />
                      </Tooltip>
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="tabsDetalleC"
                    label={
                      <Tooltip title="Editar datos de cliente">
                        <Edit color="black" size={28} className="m-auto" />
                      </Tooltip>
                    }
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="tabsDetalleC"
                    label={
                      <Tooltip title="Comentarios">
                        <MessageSquare
                          color="black"
                          size={28}
                          className="m-auto"
                        />
                      </Tooltip>
                    }
                    {...a11yProps(4)}
                  />
                  <Tab
                    style={{ display: "none" }}
                    className="tabsDetalleC hidden"
                    label={
                      <Tooltip title="Envio de correo">
                        <Mail color="black" size={28} className="m-auto" />
                      </Tooltip>
                    }
                    {...a11yProps(5)}
                  />
                  <Tab
                    className="tabsDetalleC"
                    onClick={() => {}}
                    label={
                      <Tooltip title="Registrar pago">
                        <Landmark color="black" size={28} className="m-auto" />
                      </Tooltip>
                    }
                  />
                </Tabs>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <Grid item xs={6} style={{ padding: "1rem" }}>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
              }}
            >
              Régimen fiscal
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {/* Régimen de Actividades */}
              {data.regime?.name || " - "}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {/* Empresariales y Profesionales */}
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
                marginTop: "1.5rem",
              }}
            >
              Precio de venta por concepto
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                fontStyle: "italic",
              }}
            >
              No especificado
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
                marginTop: "1.5rem",
              }}
            >
              Fecha de alta o contratación
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {parseObjectDate(new Date(data.createdAt), 3)}
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
                marginTop: "1.5rem",
              }}
            >
              Saldo
            </div>
            <div
              style={{
                fontWeight: 600,
                marginBottom: "0.5rem",
                color: data.balance > 0 ? "red" : "green",
              }}
            >
              $ {data.balance < 0 ? data.balance * -1 : data.balance}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ padding: "1rem", borderLeft: "solid 2px lightgrey" }}
          >
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              DATOS DEL ADMINISTRADOR
            </div>
            <div
              style={{
                fontWeight: "bolder",
                marginBottom: "0.5rem",
                color: "#2A2F99",
              }}
            >
              {data.administratorName}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {data.administratorEmail}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {data.administratorPhone}
            </div>
            <div
              style={{
                fontWeight: "bolder",
                marginBottom: "0.5rem",
                marginTop: "1.5rem",
              }}
            >
              DATOS DEL CONTADOR
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
              }}
            >
              {data.counterName}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {data.counterEmail}
            </div>
          </Grid>
        </Grid>
      </Grid>

      <SesTabPanel value={activeTab} index={0}>
        <NewService
          onSuccessNavigation={() => navigate("/facturacion")}
          setLoading={setLoading}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={1}>
        <ListServices
          clientServices={data.serviceTemplates || []}
          clickNewService={() => {
            setActiveTab(0);
          }}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={2}>
        <Documents
          setLoading={setLoading}
          clientData={data}
          setClientAllData={setData}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={3}>
        <DetailClientInfo
          clientData={data}
          loading={loading}
          setLoading={setLoading}
          setClientAllData={setData}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={4}>
        <Comments
          clientData={data}
          setClientAllData={setData}
          setLoading={setLoadingComment}
          loading={loadingComment}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={5}>
        <ClientSendEmail clientData={data} />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={6}>
        <ClientDeposit clientData={data} fetchData={fetchData} />
      </SesTabPanel>
    </Skinpage>
  );
};

export default DetailClient;
