import React from "react";

function DgContainer({ children, className, style }) {
  return (
    <div
      style={style}
      className={`flex flex-col h-fit min-w-[40%] w-fit bg-[#e6e6fa] rounded-2xl items-center gap-1  ${className}`}
    >
      {children}
    </div>
  );
}

export default DgContainer;
