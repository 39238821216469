import React, { useState } from "react";
import { useEffect } from "react";

function useFilterItem(itemList, filteringString) {
  const [filteredList, setFilteredList] = useState(itemList);

  useEffect(() => {
    if (filteringString) {
      setFilteredList(
        itemList.filter((item) =>
          JSON.stringify(item)
            .toUpperCase()
            .includes(filteringString.toUpperCase())
        )
      );
    } else setFilteredList(itemList);
  }, [filteringString, itemList]);
  return [filteredList];
}

export default useFilterItem;
