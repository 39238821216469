import { Request } from "./api";

export const uploadImage = async (idUser, img) => {
  const formData = new FormData();
  formData.append("file", img);
  return await Request(
    `/client/${idUser}/profileImage`,
    formData,
    "PATCH",
    true,
    true
  );
};
