import React from "react";
import DgModal from "../../../components/DgModal";
import ClientSendEmail from "../../cashing-crm/client-detail-tabs/ClientSendEmail";
import useGetScreenWidth from "../../../helpers/useGetScreenWidth";

function ModalSendEmail({
  isOpen,
  toggle,
  endPoint,
  isService = true,
  idService,
  cfdiType,
  internalFolio,
}) {
  const [width] = useGetScreenWidth();
  return (
    <DgModal
      className={`overflow-y-auto overflow-x-hidden`}
      style={{
        height: width <= 1000 ? "100%" : "95vh",
        width: width <= 1000 ? "100%" : "95vw",
      }}
      open={isOpen}
      toggle={toggle}
      withClose={false}
    >
      <ClientSendEmail
        idService={idService}
        isService={isService}
        titleStyle={{ textAlign: "center", paddingTop: 10, marginBottom: 0 }}
        withClose={true}
        toggle={toggle}
        endPoint={endPoint}
        cfdiType={cfdiType}
        internalFolio={internalFolio}
      />
    </DgModal>
  );
}

export default ModalSendEmail;
