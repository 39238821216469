import React, { useEffect, useState } from "react";
import ContactAddressContainer from "./ContactAddressContainer";
import { isValidEmail } from "../helpers/utils";

function ContactsInput({ name, onChange = () => {}, value }) {
  const [contactListState, setContactListState] = useState([]);
  const [contactInput, setContactInput] = useState("");
  const handleContactsCapture = (e) => {
    if (e.key === " " && isValidEmail(contactInput)) {
      setContactListState((prevContactList) => [
        ...prevContactList,
        contactInput,
      ]);
      setContactInput("");
    }
  };
  const handleDelete = (email) => {
    setContactListState((contactListState) =>
      contactListState.filter((contact) => contact !== email)
    );
  };

  useEffect(() => {
    if (value) {
      setContactListState(value);
    }
  }, [value?.length]);

  useEffect(() => {
    onChange({ target: { name, value: contactListState } });
  }, [contactListState]);

  return (
    <div style={{ paddingTop: "0.5rem" }}>
      <div
        style={{
          boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
          borderRadius: 10,
          backgroundColor: "#ffffff",
          border: "none",
          padding: "16px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
            flex: 1,
            paddingLeft: "3.5rem",
          }}
        >
          {[...contactListState].map((contact) => {
            return (
              <ContactAddressContainer
                deleteAction={() => handleDelete(contact)}
              >
                {contact}
              </ContactAddressContainer>
            );
          })}
          <input
            onChange={(e) => {
              setContactInput(e.target.value.trim());
            }}
            value={contactInput}
            style={{ outline: "none", flex: 1 }}
            onKeyDownCapture={handleContactsCapture}
            onBlur={handleContactsCapture}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default ContactsInput;
