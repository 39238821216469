import React, { useState } from "react";
import DocumentCard from "../../../components/DocumentCard";
import "../../../App.css";
import ModalDocumentPersonDetail from "../modals/ModalDocumentPersonDetail";
import Swal from "sweetalert2";
import { Request } from "../../../helpers/api";

const PersonDocuments = ({
  data: personData,
  setIsLoading,
}) => {
  let documents = personData.employeeDocuments || [];
  const [singleData, setSingleData] = useState({});
  const [modalDocumentPersonDetail, setModalDocumentPersonDetail] =
    useState(false);

  const docHandlers = {
    handleDetail: (doc) => {
      setSingleData(doc);
      setModalDocumentPersonDetail(true);
    },
    handleDelete: (doc, ix) => {
      const { idEmployeeDocument } = doc;
      Swal.fire({
        title: "¿Confirma la siguiente operación?",
        text: "Se eliminará el documento, esta acción es irreversible.",
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request(
            "/employee/document/" + idEmployeeDocument,
            {},
            "PATCH"
          );
          if (res.ok) {
            setIsLoading((isLoading) => !isLoading);
            Swal.fire("Éxito", "Documento cancelado con éxito.", "success");
          }
        }
      });
    },
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-x-5 gap-y-0 mt-4">
        <div>
          <div
            className="ml-2 text-sesBlue"
            style={{ fontWeight: 500, fontSize: "1.1rem" }}
          >
            DOCUMENTOS DE EMPLEADO
          </div>
          {/* <DgInputFile
            type="text"
            placeholder="Buscar archivo"
            name="searchFile"
            onChange={onDataChange}
            iconName={"Search"}
          />*/}
          {documents.length ? (
            documents.map((doc, ix) => (
              <DocumentCard
                document={{ ...doc, name: doc.employeeDocumentType }}
                handlers={docHandlers}
                ix={ix}
              />
            ))
          ) : (
            <i>No hay documentos para mostrar</i>
          )}
        </div>
      </div>
      <ModalDocumentPersonDetail
        isOpen={modalDocumentPersonDetail}
        data={singleData}
        toggle={() => {
          setModalDocumentPersonDetail(!modalDocumentPersonDetail);
        }}
      />
    </>
  );
};

export default PersonDocuments;
