import React, { useCallback, useEffect, useState } from "react";
import DgInput from "../../../components/DgInput";
import { Request } from "../../../helpers/api";
import ClientCard from "../../../components/ClientCard";
import Button from "../../../components/Button";
import useGetScreenWidth from "../../../helpers/useGetScreenWidth";
import Swal from "sweetalert2";
import { downloadBlobFile, parseCat } from "../../../helpers/utils";
import DgLoader from "../../../components/DgLoader";
import { useSelector } from "react-redux";

const Collection = () => {
  const [loading, setLoading] = useState(false);
  const [screenWidth] = useGetScreenWidth();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [clientFilterValue, setClientFilterValue] = useState("");
  const [region, setRegion] = useState("");

  const { catRegions } = useSelector((state) => state.catalogues);

  const fetchData = useCallback(async () => {
    const { ok, data } = await Request("/client");
    if (ok) {
      const parsedClients = data.map((c) => ({ ...c, selected: false }));
      setAllData(parsedClients);
      setData(parsedClients);
    }
  }, []);

  const handleTapCard = (ix) => () => {
    const client = { ...data[ix], selected: !data[ix].selected };
    const newClients = [...data];
    newClients[ix] = client;
    setAllData((prevData) =>
      prevData.map((card) => ({
        ...card,
        selected:
          card.idClient == client.idClient ? client.selected : card.selected,
      }))
    );
    setData(newClients);
  };
  const handleSubmit = async () => {
    const clientsSelected = data
      .filter((c) => c.selected)
      .map((filteredClients) => filteredClients.idClient)
      .join(",");
    setLoading(true);
    const res = await Request(
      `/report/paymentPortfolio?idClients=${
        clientsSelected ? clientsSelected : ""
      }&idCatRegion=${region}`,
      null,
      "GET",
      true,
      false,
      true
    );
    setLoading(false);
    if (res) {
      downloadBlobFile(
        res,
        `Estado de Cuenta - ${new Date().toLocaleDateString("es-MX")}.xlsx`
      );
    }
  };

  useState(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (clientFilterValue) {
      const filterValues = clientFilterValue
        .split(",")
        .map((inputValue) => inputValue.trim().toUpperCase());
      setData(
        allData.filter(({ name, tradeName }) => {
          return filterValues.some(
            (filterValue) =>
              name?.toUpperCase().includes(filterValue) ||
              tradeName?.toUpperCase().includes(filterValue)
          );
        })
      );
    } else {
      setData(allData);
    }
  }, [allData, clientFilterValue]);
  return (
    <div className="w-full">
      <div className="flex gap-2 my-2">
        <DgInput
          label="Buscar Cliente"
          placeholder="Buscar ..."
          iconName="Search"
          name="search"
          onChange={({ target: { value } }) => {
            setClientFilterValue(value);
          }}
          value={clientFilterValue}
          containerStyle={{ flex: 1, margin: 0 }}
        />
        <DgInput
          label="Región"
          type="select"
          iconName="Map"
          name="idCatRegion"
          onChange={({ target: { value } }) => {
            setRegion(value);
          }}
          options={[
            { label: "Seleccione una región..", value: "" },
            ...parseCat(catRegions, "name", "idCatRegion"),
          ]}
          value={region}
          containerStyle={{ width: "30%", margin: 0 }}
        />
        <Button
          containerStyle={{ width: "20%", margin: 0 }}
          style={{ marginTop: "50px" }}
          onClick={handleSubmit}
        >
          Generar reporte de cobranza
        </Button>
      </div>
      <div className="flex flex-wrap gap-3">
        {data.map((c, ix) => (
          <ClientCard
            style={{ width: "32%", minWidth: screenWidth < 920 ? "100%" : 460 }}
            client={{ ...c, ix }}
            selectable={true}
            onTap={handleTapCard}
            md={6}
          />
        ))}
      </div>
      <DgLoader open={loading} />
    </div>
  );
};

Collection.propTypes = {};

export default Collection;
