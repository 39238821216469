import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FileInput } from "lucide-react";

const ModalDetails = ({ isOpen, toggle, data = {}, style }) => {
  const {
    urlInvoice,
    folio,
    urlRemission,
    invoiceFolioSes,
    invoice,
    document = "invoice",
    urlPaymentComplement,
    dataInvoiceService,
    isInvoice = document === "invoice",
    title = isInvoice
      ? `Factura: ${
          folio ||
          invoiceFolioSes ||
          invoice ||
          `${dataInvoiceService?.prefix}${dataInvoiceService?.internalFolio}`
        }`
      : `Complemento de Pago`,
  } = data;
  return (
    <Modal
      style={style}
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      zIndex={9999}
    >
      <ModalHeader toggle={toggle}>
        <FileInput color={"#565656"} size={40} className="inline-block" />{" "}
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="grid grid-cols-1">
          <embed
            className="w-full"
            height={700}
            src={isInvoice ? urlInvoice || urlRemission : urlPaymentComplement}
            type="application/pdf"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

ModalDetails.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  invoice: PropTypes.object,
};

export default ModalDetails;
