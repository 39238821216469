import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import { vacationsSchema } from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import { Edit } from "lucide-react";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import { noop, parseObjectDate, sanitizeDate } from "../../../helpers/utils";
import { TODAY } from "../../../helpers/consts";

const ModalVacations = ({ isOpen, toggle = noop, data: personalData }) => {
  const [vacationsData, setVacationsData] = useState({});
  const [data, onDataChange, clearData] = useForm({
    ...vacationsSchema,
  });

  const { name, employeeKey, idEmployee } = personalData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (idEmployee) {
      const { startDate, endDate, returnDate } = data;
      const res = await Request(
        `/employee/${idEmployee}/vacation`,
        {
          ...data,
          startDate: parseObjectDate(sanitizeDate(startDate)),
          endDate: parseObjectDate(sanitizeDate(endDate)),
          returnDate: parseObjectDate(sanitizeDate(returnDate)),
        },
        "POST"
      );
      if (res.ok) {
        Swal.fire("Éxito", "Vacaciones solicitadas con éxito.", "success").then(
          () => {
            clearData();
            toggle();
          }
        );
      }
    }
  };
  const fetchVacationsData = async () => {
    if (idEmployee) {
      const res = await Request(`/employee/getInfoVacation/${idEmployee}`);

      setVacationsData({
        ...res.data,
        date: parseObjectDate(new Date(res.data.date), 4),
      });
    }
  };

  useEffect(() => {
    fetchVacationsData();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className="text-center justify-center">
        <div className="text-xl text-sesGray ml-2">REGISTRO DE VACACIONES</div>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody
          style={{ padding: "1.5rem", overflowY: "auto", maxHeight: "520px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
              <div
                style={{
                  marginLeft: "1rem",
                  fontWeight: 600,
                  color: "#2A2F99",
                }}
              >
                {name}
                <br />
                {employeeKey}
              </div>
            </div>
            <div className="justify-between">
              <Edit
                color="#565656"
                size={22}
                style={{ cursor: "pointer" }}
                className="m-auto"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-0">
            <DgInput
              type="text"
              label="Servicio"
              name="serviceName"
              value={vacationsData.serviceName}
              readOnly={true}
              iconName={"ClipboardList"}
              disabled
            />
            <DgInput
              type="date"
              label="Fecha de ingreso"
              name="date"
              value={vacationsData.date}
              readOnly={true}
              iconName={"CalendarDays"}
              disabled
            />
            {/* <DgInput
              type="select"
              label="Periodo"
              options={[
                { label: "2022-2021", value: 1 },
                { label: "2021-2020", value: 2 },
                { label: "2020-2019", value: 3 },
                { label: "2019-2018", value: 3 },
                { label: "2018-2017", value: 3 },
              ]}
              name="period"
              onChange={onDataChange}
              required={true}
              iconName={"ClipboardList"}
            />
            <DgInput
              type="number"
              label="Días a disfrutar"
              step={1}
              min="1"
              name="totalPeriodDays"
              onChange={onDataChange}
              required={true}
              iconName={"FormInput"}
            />*/}

            <DgInput
              type="date"
              label="De"
              name="startDate"
              data={data.startDate}
              onChange={onDataChange}
              required={true}
              iconName={"CalendarDays"}
            />
            <DgInput
              type="date"
              label="Hasta"
              name="endDate"
              data={data.endDate}
              onChange={onDataChange}
              required={true}
              iconName={"CalendarDays"}
            />
            <DgInput
              type="number"
              label="Días solicitados"
              name="daysNumber"
              data={data.daysNumber}
              onChange={onDataChange}
              required={true}
              iconName={"FormInput"}
            />
            <DgInput
              type="date"
              label="Fecha de regreso"
              name="returnDate"
              // min={parseObjectDate(TODAY, 4)}
              value={data.returnDate}
              onChange={onDataChange}
              required={true}
              iconName={"CalendarDays"}
            />
            <DgInput
              type="number"
              label="Saldo de vacaciones"
              value={vacationsData.holidaysNumber || 0}
              required={true}
              iconName={"FormInput"}
              disabled
            />
          </div>
          <DgInput
            type="textarea"
            label="Observaciones"
            placeholder={"..."}
            name="comments"
            value={data.comments}
            onChange={onDataChange}
            required={true}
            iconName={"MessageSquare"}
          />
        </ModalBody>
        <ModalFooter>
          <div className="flex space-between w-full">
            <DgButton
              onClick={toggle}
              color="secondary"
              innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
            >
              Cancelar
            </DgButton>
            <DgButton
              type="submit"
              innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
            >
              Registrar
            </DgButton>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalVacations.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalVacations;
