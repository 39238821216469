import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIceCream } from "@fortawesome/free-solid-svg-icons";

const ButtonIcon = ({
  icon = faIceCream,
  onClick,
  disabled = false,
  className = "",
  style = {},
}) => {
  return (
    <button
      {...{ onClick, style, disabled }}
      className={`buttonIcon rounded-lg py-1 px-2 text-2xl ${className}`}
      type="button"
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.object,
};

export default ButtonIcon;
