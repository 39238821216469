import { XCircle } from "lucide-react";
import React from "react";
import { AiOutlineFileDone } from "react-icons/ai";

function FileUploadedIcon({ label, fileRemover, required }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 7,
      }}
    >
      <div style={{ position: "relative" }}>
        {!required && (
          <XCircle
            onClick={() => {
              fileRemover();
            }}
            size={20}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            color="white"
            fill="red"
          />
        )}
        <AiOutlineFileDone color="#2a2f99" size={40} />
      </div>
      <p style={{ fontWeight: 600, color: "#2a2f99", fontSize: 13 }}>
        {label.length > 15 ? label.slice(0, 15).trim() + ".." : label}
      </p>
    </div>
  );
}

export default FileUploadedIcon;
