import React, { useEffect, useState } from "react";
import DgForm from "../../components/DgForm";
import Skinpage from "../../components/Skinpage";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import { accountSchema, clientSchema } from "../../helpers/schemas";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientInputs } from "../../helpers/formUtils";
import { formatPhone, parseCat } from "../../helpers/utils";
import { Minus, Plus, Save } from "lucide-react";
import DgInput from "../../components/DgInput";
import FormTitle from "../../components/FormTitle";
import "./ClientInsert.css";
import useGetZipCode from "../../helpers/useGetZipCode";
import { handlePhoneChange } from "../../helpers/utils";

const NewClient = () => {
  // Hooks and vars
  const navigate = useNavigate();
  const {
    CatCfdi,
    CatRegime,
    CatPaymentWay,
    bank: CatBanks,
  } = useSelector((s) => s.catalogues);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientSchema,
  });
  const [handleZipChange, suburbs] = useGetZipCode(
    data.postalCode,
    data,
    setAllData
  );

  const handleNestedChange = (ix, key) => (e) => {
    const { value, name } = e.target;
    const arr = data[key];
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: key, value: arr } });
  };

  const manageAccounts = (willDelete, ix, key, schema) => () => {
    const newAccounts = [...data[key]];
    if (willDelete) {
      newAccounts.splice(ix, 1);
    } else {
      newAccounts.push({ ...schema });
    }
    setAllData({ ...data, [key]: newAccounts });
  };

  // Requests
  const submitData = async (e) => {
    e.preventDefault();
    const res = await Request("/client", { ...data }, "POST");
    if (res.ok) {
      Swal.fire("Éxito", "Registro exitoso", "success").then(() => {
        navigate(`/detalle-cliente/${res.dataRes.idClient}`);
      });
    }
  };

  useEffect(() => {
    handleZipChange();
  }, [data.postalCode]);
  return (
    <Skinpage pageTitle={"Alta de Cliente"}>
      <DgForm
        data={data}
        onChange={onDataChange}
        onSubmit={submitData}
        groups={clientInputs(
          data,
          { handlePhoneChange, onDataChange },
          { CatRegime, CatCfdi, CatPaymentWay, suburbs: suburbs[0] }
        )}
        zipError={data.clientType === "NONFISCAL" ? false : data.error}
      >
        <div className="grid grid-cols-2 gap-4 items-baseline">
          <div>
            <FormTitle title="Cuentas bancarias" />
            <table className="ses-client-accounts-table">
              <thead>
                <tr>
                  <th width="40%">Banco</th>
                  <th width="50%">Número de cuenta</th>
                  <th width="10%">
                    <Plus
                      onClick={manageAccounts(
                        false,
                        null,
                        "clientAccounts",
                        accountSchema
                      )}
                      color="black"
                      size={28}
                      className="m-auto"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.clientAccounts?.map((a, ix) => (
                  <tr>
                    <td>
                      <DgInput
                        {...{
                          type: "select",
                          iconName: "Landmark",
                          required: true,
                          options: [
                            { label: "Elige un banco", value: "" },
                            ...parseCat(CatBanks, "name", "idCatBank"),
                          ],
                          name: "idCatBank",
                          value: a.idCatBank,
                          onChange: handleNestedChange(ix, "clientAccounts"),
                        }}
                      />
                    </td>
                    <td>
                      <DgInput
                        {...{
                          type: "text",
                          minLength: 10,
                          maxLength: 25,
                          iconName: "DollarSign",
                          required: true,
                          name: "account",
                          value: a.account,
                          onChange: handleNestedChange(ix, "clientAccounts"),
                        }}
                      />
                    </td>
                    <td>
                      <Minus
                        onClick={manageAccounts(true, ix, "clientAccounts")}
                        color="black"
                        size={28}
                        className="m-auto"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="flex items-end gap-2.5">
              <FormTitle title="Correos electrónicos" />
              <Plus
                onClick={manageAccounts(false, null, "clientContacts", {
                  email: "",
                })}
                className="cursor-pointer"
                color="black"
                size={28}
              />
            </div>
            {data.clientContacts?.map((contactData, ix) => (
              <div className="flex items-end gap-4">
                <DgInput
                  containerClassName="mt-3"
                  label="E-Mail"
                  iconName={"MailPlus"}
                  name={"email"}
                  onChange={handleNestedChange(ix, "clientContacts")}
                  value={contactData.email}
                />
                <Minus
                  onClick={manageAccounts(true, ix, "clientContacts")}
                  color="black"
                  className="mb-[30px] cursor-pointer"
                  size={28}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div />
          <div />
          <Button type="submit">Continuar</Button>
        </div>
      </DgForm>
    </Skinpage>
  );
};

export default NewClient;
