import React from "react";
import DgModal from "../../../components/DgModal";
import DgTable from "../../../components/DgTable";
import { getColumnsServicesPayedWithCertainDeposit } from "../../../helpers/datatableHelpers";
import Button from "../../../components/Button";
import Swal from "sweetalert2";

function CancelCashinModal({
  data,
  open,
  toggle,
  title = "CANCELAR DEPÓSITO",
  confirmAction,
}) {

  const funcs = {
    cancelPaymentComplement: (row) => {
      
    },
  };

  return (
    <DgModal open={open} toggle={toggle} title={title}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: 20,
          gap: 20,
          width: "100%",
        }}
      >
        <DgTable
          data={data}
          columnsDef={getColumnsServicesPayedWithCertainDeposit(funcs)}
        />
        <div style={{ display: "flex", padding: "0px 20px", gap: 20 }}>
          <Button onClick={confirmAction} containerStyle={{ margin: 0 }}>
            Confirmar
          </Button>
          <Button onClick={toggle} containerStyle={{ margin: 0 }}>
            Cancelar
          </Button>
        </div>
      </div>
    </DgModal>
  );
}

export default CancelCashinModal;
