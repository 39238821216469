import "../../../App.css";
import React, { useState, useEffect } from "react";
import { Request } from "../../../helpers/api";
import { monthsEs } from "../../../helpers/enums";
import { getOperatingYearsCat, parseObjectDate } from "../../../helpers/utils";
import DgLoader from "../../../components/DgLoader";
import ListItem from "../../../components/ListItem";
import DgModal from "../../../components/DgModal";
import DgInput from "../../../components/DgInput";
import Swal from "sweetalert2";

const PersonCalendar = ({
  data: allData,
  isLoading: holidaysLoading,
  setIsLoading: setHolidaysLoading,
}) => {
  const [openHolidaysDetails, setOpenHolidaysDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [holidaysSingleData, setHolidaysSingleData] = useState({});
  const [filters, setFilters] = useState({ year: "", month: "" });
  const [employeeVacations, setEmployeeVacations] = useState(
    allData.employeeVacations
  );
  const handleFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const filteredEmployeeVacations = allData.employeeVacations.filter(
      (vacation, index) => {
        return (
          (!filters.year || filters.year === vacation.endDate.split("-")[0]) &&
          (!filters.month || vacation.endDate.split("-")[1] === filters.month)
        );
      }
    );
    setEmployeeVacations(filteredEmployeeVacations);
  }, [filters.year, filters.month, allData.employeeVacations]);

  const deleteHolidaysRequest = async (id) => {
    setIsLoading(true);
    const res = await Request(`/employee/vacation/${id}`, {}, "PATCH");
    setIsLoading(false);
    if (res.ok) {
      Swal.fire("Éxito", "Solicitud eliminada con éxito.", "success");
      setHolidaysLoading(!holidaysLoading);
    } else {
      Swal.fire(
        "Error",
        res.error || "No se ha podido eliminar la solicitud.",
        "error"
      );
    }
  };
  return (
    <div className="grid grid-cols-2 gap-x-5 gap-y-0 mt-4 mb-4">
      <DgModal
        open={openHolidaysDetails}
        setModalState={setOpenHolidaysDetails}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <h1 style={{ color: "rgb(42, 47, 153)", fontWeight: "700" }}>
            Solicitud de {holidaysSingleData.daysNumber} días
          </h1>
          <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
            <p>
              {parseObjectDate(new Date(holidaysSingleData.startDate), 1, true)}
            </p>
            -
            <p>
              {parseObjectDate(new Date(holidaysSingleData.endDate), 1, true)}
            </p>
          </div>
          <p>Comentarios: {holidaysSingleData.comments}</p>
          <p>Prima vacacional: {holidaysSingleData.amountVacation}</p>
        </div>
      </DgModal>
      <div>
        <div
          className="ml-2 text-sesBlue"
          style={{ fontWeight: 500, fontSize: "1.1rem" }}
        >
          HISTORIAL DE VACACIONES
        </div>
        <div className="personIncidencesFilters grid gap-2">
          {/* <DgInput
                        type="select"
                        label="Tipo de incidencia"
                        name="incidencePeriod"
                        options={[
                            { label: "Primera quincena", value: 1 },
                            { label: "Segunda quincena", value: 2 },
                        ]}
                        onChange={onDataChange}
                        iconName={"CalendarDays"}
                    /> */}
          <DgInput
            type="select"
            label="Año"
            name="year"
            onChange={handleFilters}
            options={[{ label: "Todos", value: "" }, ...getOperatingYearsCat()]}
            className="pl-5"
          />
          <DgInput
            type="select"
            label="Mes"
            name="month"
            onChange={handleFilters}
            options={[{ label: "Todos", value: "" }, ...monthsEs]}
            className="pl-5"
          />
        </div>
        {employeeVacations?.map((item, index) => {
          return (
            <ListItem
              title={`Solicitud de ${item.daysNumber} días`}
              description={item.comments}
              setSingleData={setHolidaysSingleData}
              singleData={item}
              setOpenModal={setOpenHolidaysDetails}
              deleteItem={() => {
                deleteHolidaysRequest(item.idEmployeeVacation);
              }}
            />
          );
        })}

        <div className="relative top-6">
          <span className="relative text-sesBlue font-semibold">
            Saldo de vacaciones
          </span>
          <br />
          <span className="relative text-sesGray">
            Restan {allData.holidaysNumber || 0} días de vacaciones en{" "}
            {getOperatingYearsCat()[getOperatingYearsCat().length - 1].value}
          </span>
          <br />
        </div>
      </div>
      <DgLoader open={isLoading} />
    </div>
  );
};

export default PersonCalendar;
