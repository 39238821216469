import React from "react";
import DgModal from "./DgModal";
import DgContainer from "./DgContainer";
import Title from "./Title";
import DgLoader from "./DgLoader";

function ContactsConfirmationModal({
  open,
  toggle,
  lists = [],
  withSend,
  withClose,
  isLoading,
}) {
  return (
    <DgModal
      withSend={withSend}
      withClose={withClose}
      open={open}
      toggle={toggle}
      className={`gap-3`}
    >
      <h1 className="text-sesBlue font-bold text-3xl">Receptores</h1>
      <div className="flex gap-3 p-3">
        {lists?.map(({ label, contacts }) =>
          contacts.length ? (
            <div
              className={`flex flex-col items-center bg-white rounded-lg p-3 shadow-md transition hover:shadow-lg`}
            >
              <Title>{label}</Title>
              {contacts?.map((contact) => (
                <p>{contact}</p>
              ))}
            </div>
          ) : null
        )}
      </div>
      <DgLoader open={isLoading} />
    </DgModal>
  );
}

export default ContactsConfirmationModal;
