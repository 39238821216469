import React from 'react'

function NoDataComponent() {
  return (
    <p style={{ fontStyle: "italic", fontSize: "1.2rem" }}>
    No hay registros para mostrar
  </p>
  )
}

export default NoDataComponent