import React, { useEffect, useState } from "react";
import DgForm from "../../components/DgForm";
import Button from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import { useSelector } from "react-redux";
import { TODAY } from "../../helpers/consts";
import { addressInputs, invoiceInputs } from "../../helpers/formUtils";
import { getGeolocationData } from "../../helpers/utils";
import { clientSchema, serviceSchema } from "../../helpers/schemas";
import { handlePhoneChange } from "../../helpers/utils";

const InvoiceGeneral = () => {
  const [data, onDataChange, setAllData] = useForm({
    ...serviceSchema,
    ...clientSchema,
  });
  const catalogues = useSelector((s) => s.catalogues);
  const [suburbs, setSuburbs] = useState([]);

  const handleZipChange = async (value) => {
    const {
      localities = [],
      state = "",
      municipality = "",
    } = await getGeolocationData(value, data.country);
    setSuburbs(localities);
    setAllData({
      ...data,
      state,
      municipality,
      suburb: localities.length === 1 ? localities[0].value : data.suburb,
    });
  };

  const formGroups = [
    {
      title: "DATOS DE FACTURA",
      groupClassNameGrid: "grid-cols-2",
      fields: [
        {
          label: "Nombre de factura",
          placeholder: "Factura general",
          type: "text",
          name: "name",
          value: data.name,
          minLength: "5",
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Éste nombre de factura no es válido",
        },
        {
          label: "Razón social",
          placeholder: "Factura general",
          type: "text",
          name: "name",
          value: data.name,
          minLength: "5",
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Éste nombre de factura no es válido",
        },
        {
          label: "RFC a facturar",
          placeholder: "Factura general",
          type: "text",
          name: "name",
          value: data.name,
          minLength: "5",
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Éste nombre de factura no es válido",
        },
        {
          label: "Fecha de factura",
          placeholder: "Factura general",
          type: "date",
          name: "name",
          max: TODAY,
          value: data.name,
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Fecha inválida",
        },
        {
          label: "Importe de factura",
          placeholder: "1500",
          type: "number",
          name: "amount",
          value: data.amount,
          min: 10,
          step: 0.01,
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Importe inválido",
        },
        {
          label: "Teléfono",
          placeholder: "Factura general",
          type: "tel",
          name: "phone",
          value: data.phone,
          onInputChange: (e) => {
            handlePhoneChange(e, onDataChange);
          },
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Éste nombre de factura no es válido",
        },
        {
          label: "Correo de factura",
          placeholder: "Factura general",
          type: "email",
          name: "email",
          value: data.email,
          minLength: "5",
          required: true,
          iconName: "ClipboardList",
          errorMessage: "Éste nombre de factura no es válido",
        },
      ],
    },
    {
      title: "DIRECCIÓN FISCAL DE LA FACTURA",
      groupClassNameGrid: "grid-cols-2",
      fields: [...addressInputs(data, suburbs)],
    },
    {
      title: "CATÁLOGOS DE FACTURACIÓN",
      groupClassNameGrid: "grid-cols-2",
      fields: invoiceInputs(data, onDataChange, catalogues),
    },
  ];
  const onSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    handleZipChange(data.postalCode);
  }, [data.postalCode]);
  return (
    <DgForm
      data={data}
      onChange={onDataChange}
      onSubmit={onSubmit}
      groups={formGroups}
      topComponent={<div></div>}
    >
      <div className="grid grid-cols-2">
        <div />
        <div className="grid grid-cols-2 gap-4">
          <Button>Exportar</Button>
          <Button>Facturar</Button>
        </div>
      </div>
    </DgForm>
  );
};

InvoiceGeneral.propTypes = {};

export default InvoiceGeneral;
