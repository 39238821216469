import React, { useEffect, useState } from "react";
import DgModal from "../../../components/DgModal";
import DgInput from "../../../components/DgInput";
import { useSelector } from "react-redux";
import { parseCat } from "../../../helpers/utils";
import DgTable from "../../../components/DgTable";
import { getColumnsGeneralInvoice } from "../../../helpers/datatableHelpers";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import { generalInvoicingServicesSchema } from "../../../helpers/schemas";
import DgLoader from "../../../components/DgLoader";
import { handleInvoice } from "../handlers";
import PostInvoiceModal from "./PostInvoiceModal";

function ModalGeneralInvoice({ isOpen, toggle }) {
  const sesAccounts = useSelector((state) =>
    state.catalogues.SesAccount?.filter(
      (account) => account.accountType != "NONFISCAL"
    )
  );
  const [cfdiList, setCfdiList] = useState([]);
  const [postCfdiModal, setPostCfdiModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generalInvoiceServices, setGeneralInvoiceServices] = useState([]);
  const [sesAccountSelected, setSesAccountSelected] = useState(null);
  const [selectedServices, setSelectedServices] = useState(
    generalInvoicingServicesSchema
  );

  const fetchGeneralInvoiceServices = async (sesAccountId) => {
    if (sesAccountId) {
      const { ok, data, msg } = await Request(
        `/service/generalInvoice/paymentDetailService?idSesAccount=${sesAccountId}`
      );
      if (ok) {
        setGeneralInvoiceServices(data);
      }
    }
  };

  const submitGeneralInvoice = async (compatibleServices) => {
    setLoading(true);
    const { ok, objupdateafacturar, data, error } = await Request(
      "/invoice/generalinvoice",
      compatibleServices,
      "POST"
    );
    setLoading(false);
    if (ok) {
      handleInvoice(objupdateafacturar, () => {
        setSelectedServices(generalInvoicingServicesSchema);
        fetchGeneralInvoiceServices(sesAccountSelected);
        setCfdiList(objupdateafacturar);
        setPostCfdiModal(true);
      });
    }
  };
  const funcs = {
    selectService: (row, checked) => {
      const { idPaymentDetailService } = row;
      const paymentsDetailsServices = [
        ...selectedServices.paymentsDetailsServices,
      ];

      if (checked) {
        setSelectedServices((prevServices) => {
          return {
            ...prevServices,
            paymentsDetailsServices: [
              ...paymentsDetailsServices,
              { idPaymentDetailService },
            ],
          };
        });
      } else {
        setSelectedServices((prevServices) => {
          return {
            ...prevServices,
            paymentsDetailsServices: paymentsDetailsServices.filter(
              (service) =>
                service.idPaymentDetailService != idPaymentDetailService
            ),
          };
        });
      }
    },
  };

  useEffect(() => {
    fetchGeneralInvoiceServices(sesAccountSelected);
  }, [sesAccountSelected]);
  return (
    <DgModal
      title="Factura general"
      toggle={toggle}
      withClose={true}
      withSend={{
        title: "Generar factura general",
        action: () => {
          submitGeneralInvoice(selectedServices);
        },
      }}
      open={isOpen}
      style={{ minWidth: "80%", display: "flex", gap: 20 }}
    >
      <div
        style={{
          display: "flex",
          gap: 20,
          flexWrap: "wrap",
          padding: "0px 20px",
        }}
      >
        <DgInput
          label="Cuenta fiscal de origen"
          iconName={"Landmark"}
          options={[
            { label: "Seleccione una opción", value: "" },
            ...parseCat(sesAccounts, "account", "idSesAccount", false, "bank"),
          ]}
          onChange={({ target: { value } }) => setSesAccountSelected(value)}
          type="select"
          containerStyle={{ margin: 0, width: 500 }}
        />
        <DgInput
          label="Descripción"
          iconName={"File"}
          value={selectedServices.description}
          onChange={({ target: { value } }) =>
            setSelectedServices((prevValue) => {
              return { ...prevValue, description: value };
            })
          }
          type="text"
          containerStyle={{ margin: 0, width: 500 }}
        />
      </div>
      <DgTable
        rootContainerStyle={{
          padding: "0px 20px",
          borderRadius: "20px 20px 0px 0px",
        }}
        data={generalInvoiceServices}
        columnsDef={getColumnsGeneralInvoice(funcs)}
      />

      <PostInvoiceModal
        title="Post factura general"
        data={cfdiList}
        open={postCfdiModal}
        toggle={() => setPostCfdiModal((prevState) => !prevState)}
        withSend={{
          title: "Cerrar",
          action: () => {
            setPostCfdiModal((prevState) => !prevState);
          },
        }}
      />
      <DgLoader open={loading} />
    </DgModal>
  );
}

export default ModalGeneralInvoice;
