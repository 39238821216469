import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FileInput } from "lucide-react";
import { noop } from "../../../helpers/utils";
import Button from "../../../components/Button";
import "./ModalFilterClients.css";
import DgInput from "../../../components/DgInput";

const ModalFilterClients = ({
  isOpen,
  toggle,
  data = [],
  setClients = noop,
  setFilters = noop,
}) => {
  const [dataCopy, setDataCopy] = useState([]);
  const [clientFilters, setClientFilters] = useState("");
  const handleClientSelection = (ix) => () => {
    const newC = [...dataCopy];
    const client = newC[ix];
    client.selected = !client.selected;
    setDataCopy(newC);
  };
  const handleFilter = () => {
    const selected = dataCopy.filter((c) => c.selected).map((c) => c.idClient);
    setClients(dataCopy);
    setFilters({ target: { value: selected.join(","), name: "clients" } });
    toggle();
  };
  const handleSelectAll = () => {
    const newC = [...dataCopy].map((c) => ({ ...c, selected: false }));
    setDataCopy(newC);
  };

  useEffect(() => {
    if (clientFilters) {
      setDataCopy(
        data.filter((client) => {
          return (
            client.name?.includes(clientFilters.toUpperCase()) ||
            client.tradeName?.includes(clientFilters.toUpperCase())
          );
        })
      );
    } else setDataCopy(data);
  }, [data, clientFilters]);

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader>
        <FileInput color={"#565656"} size={50} className="inline-block" />
        Seleccionar Clientes
      </ModalHeader>
      <ModalBody>
        <DgInput
          onChange={(e) => setClientFilters(e.target.value)}
          containerStyle={{ margin: 0 }}
          label="Filtrar por nombre, o nombre comercial"
          placeholder={"Nombre de cliente.."}
          iconName={"Users"}
          value={clientFilters}
        />
        <div className="grid grid-cols-2">
          {dataCopy.map((cc, ix) => (
            <div
              className={
                "client-row " + (cc.selected ? "client-row-selected" : "")
              }
              onClick={handleClientSelection(ix)}
            >
              <span>{cc.name}</span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full grid grid-cols-3 gap-4">
          <Button onClick={toggle} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSelectAll} innerClassName="secondaryButton">
            Limpiar Seleccion
          </Button>
          <Button onClick={handleFilter}>Filtrar</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalFilterClients.propTypes = {};

export default ModalFilterClients;
