import React, {useState } from "react";
import logo from "../css/img/LogoSES.svg";
import {
  DollarSign,
  PersonStanding,
  ShieldAlert,
  Power,
  Banknote,
} from "lucide-react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { endSession } from "../helpers/storageHandler";
import { useSelector } from "react-redux";

const Menu = ({ userPermissions }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [subItems, setSubItems] = useState([]);
  const {
    auth: {
      data: { idCatRole },
    },
  } = useSelector((s) => s);
 
  const handleClick = (ix) => (event) => {
    setSubItems(menuItems[ix].subItems);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const menuItems = [
    {
      name: "Cobranza & CRM",
      icon: (
        <DollarSign
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      enabled:
      userPermissions.includes(1) ||
      userPermissions.includes(2) ||
      userPermissions.includes(3) ||
      userPermissions.includes(4),
      subItems: [
        {
          name: "Alta de cliente",
          to: "/inicio",
          enabled: userPermissions.includes(1),
        },
        {
          name: "Seguimiento de Clientes",
          to: "/clientes",
          enabled: userPermissions.includes(2),
        },
        {
          name: "Reporte de cobranza",
          to: "/reportes-cobranza",
          enabled: userPermissions.includes(3),
        },
        {
          name: "Analíticos de cobranza",
          to: "/analiticos-cobranza",
          enabled: userPermissions.includes(4),
        },
      ],
    },
    {
      name: "Facturación",
      icon: (
        <Banknote
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      enabled: userPermissions.includes(5),
      subItems: [
        {
          name: "Facturación",
          to: "/facturacion",
          enabled: userPermissions.includes(5),
        },
      ],
    },
    {
      name: "RH y Nómina",
      icon: (
        <PersonStanding
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      enabled:
      userPermissions.includes(6) ||
      userPermissions.includes(7) ||
      userPermissions.includes(8) ||
      userPermissions.includes(9) ||
      userPermissions.includes(10) ||
      userPermissions.includes(11),
      subItems: [
        {
          name: "Alta de personal",
          to: "/nueva-persona",
          enabled: userPermissions.includes(6),
        },
        {
          name: "Lista de personal",
          to: "/lista-personal",
          enabled: userPermissions.includes(7),
        },
        {
          name: "Cálculo de nómina",
          to: "/calculo-nomina",
          enabled: userPermissions.includes(8),
        },
        {
          name: "Historial de servicios",
          to: "/historico-servicios",
          enabled: userPermissions.includes(7),
        },
      ],
    },
    {
      name: "Administración",
      icon: (
        <ShieldAlert
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      enabled: idCatRole === 1,
      subItems: [
        {
          name: "Crear Usuario",
          to: "/nuevo-usuario",
          enabled: idCatRole === 1,
        },
        {
          name: "Lista de Usuarios",
          to: "/lista-usuarios",
          enabled: idCatRole === 1,
        },
      ],
    },
  ];
  return (
    <>
      <div className="sideBar">
        <img src={logo} className="w-2/3 mx-auto my-8" />
        <div className="mt-2 menuTop">
          {menuItems.map(
            (it, ix) =>
              it.enabled && (
                <>
                  <div
                    className="relative float-left my-4 w-11/12 h-8 menuItem"
                    onClick={handleClick(ix)}
                  >
                    {it.icon}
                    <span className="text-sesBlue relative float-left cursor-pointer mt-0 font-semibold">
                      {it.name}
                    </span>
                  </div>
                </>
              )
          )}
          {subItems.length > 0 && (
            <Popover
              id={1}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.5rem",
                }}
              >
                {subItems.map(
                  (sIt) =>
                    sIt.enabled && (
                      <Button
                        className="itemSubmenu"
                        onClick={() => navigate(sIt.to)}
                      >
                        {sIt.name}
                      </Button>
                    )
                )}
              </div>
            </Popover>
          )}
        </div>

        <div className="relative top-16">
          <div
            className="relative float-left my-4 w-11/12 h-8"
            onClick={endSession}
          >
            <Power
              color="#2A2F99"
              size={22}
              className="ml-4 mr-2 relative float-left"
            />
            <span className="text-sesBlue relative float-left cursor-pointer mt-0 font-semibold">
              Cerrar sesión
            </span>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Menu;
