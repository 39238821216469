import { useForm as useReactHookForm } from "react-hook-form";

export const useFormWithValidations = (data) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState,
    watch,
    getValues,
    reset,
  } = useReactHookForm({ defaultValues: data ? data : {} });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setValue(name, inputValue, { shouldValidate: true });
  };

  const formProps = [
    register,
    handleSubmit,
    setValue,
    formState,
    watch,
    reset,
    getValues,
  ];

  return [handleInputChange, formProps];
};
