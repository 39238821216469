import React, { useEffect, useState } from "react";
import { getGeolocationData } from "./utils";
import statesMunicipalities from "./statesMunicipalities.json";

function useGetZipCode(cp, data, setData, suffix = "", defaultData) {
  const [suburbs, setSuburbs] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [inputType, setInputType] = useState("");
  const handleZipChange = async (nested) => {
    const country = data["country" + suffix];
    const index = nested?.index;
    const property = nested?.property;
    const {
      localities = [],
      error = false,
      state = "",
      municipality = "",
    } = nested
      ? await getGeolocationData(nested.cp, country, defaultData)
      : await getGeolocationData(cp, country, defaultData);

    setMunicipalities(() => {
      const newMunicipalities = state
        ? statesMunicipalities[state]?.map((municipality) => {
            return { label: municipality, value: municipality };
          })
        : [];
      return newMunicipalities;
    });

    setSuburbs((suburbs) => {
      const newSuburbs = suburbs;
      if (nested) {
        newSuburbs[nested.index + 1] = localities;
      } else {
        newSuburbs[0] = localities;
      }
      return newSuburbs;
    });
    setData((oldData) => {
      const newData = { ...oldData };
      if (nested) {
        const nestedArray = oldData[property];
        const itemToModify = {
          ...nestedArray[index],
          suburb: nestedArray[index].suburb,
          state: state,
          municipality: municipality
            ? municipality
            : nestedArray[index].municipality,
          error: error,
        };
        nestedArray[index] = itemToModify;
        newData[property] = nestedArray;
        return newData;
      }
      newData[`state${suffix}`] = state ? state : data[`state${suffix}`];
      newData[`municipality${suffix}`] = municipality
        ? municipality
        : data[`municipality${suffix}`];
      newData[`suburb${suffix}`] =
        localities.length === 1 ? localities[0].value : data[`suburb${suffix}`];
      newData.error = error;
      return newData;
    });
    setInputType("");
  };
  return [handleZipChange, suburbs, inputType, setInputType, municipalities];
}

export default useGetZipCode;
