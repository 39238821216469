import React, { useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Request } from "../../../helpers/api";
import { enumFileTypes } from "../../../helpers/enums";
import iconoSubirArchivo from "../../../css/img/IconoSubirArchivo.svg";
import Button from "../../../components/Button";
import Swal from "sweetalert2";
import DgLoader from "../../../components/DgLoader";
import DocumentFolder from "../../../components/DocumentFolder";
import DocumentsList from "./DocumentsList";
import DgInput from "../../../components/DgInput";
import { useSelector } from "react-redux";
import { parseCat } from "../../../helpers/utils";
import { useForm } from "../../../helpers/useForm";
import useModify from "../../../helpers/useModify";
import { editFolderSchema } from "../../../schemas/documentsSchemas";
import Title from "../../../components/Title";

const Documents = ({ clientData, setClientAllData, setLoading }) => {
  const { idClient } = clientData;
  const [documents, setDocuments] = useState([]);
  const [folderTypes, setFolderTypes] = useState([]);
  const [documentsListOpen, setDocumentsListOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const fetchDocuments = async (folderId) => {
    const res = await Request(
      `/client/${idClient}/document?idCatClientDocumentType=${folderId}`
    );
    if (res.ok) {
      setDocuments(res.data);
    }
  };

  const handleUpload = async () => {
    const newFile = new File([data.file], data.fileName, {
      type: data.file.type,
    });

    const formData = new FormData();
    for (const key in data) {
      key === "file"
        ? formData.append(key, newFile)
        : formData.append(key, data[key]);
    }

    setIsUploading(true);
    const res = await Request(
      "/client/" + idClient + "/document",
      formData,
      "POST",
      true,
      true
    );
    setIsUploading(false);
    if (res.ok) {
      Swal.fire("Éxito", "Documento cargado con éxito.", "success").then(() => {
        reset();
        setLoading((loading) => !loading);
        fetchFolders();
      });
    }
  };
  const handleDeactivate = async (folderId, folderName) => {
    Swal.fire({
      title: "Confirma la siguiente operación?",
      text: `Desactivar carpeta ${folderName}`,
      icon: "question",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setIsUploading(true);
        const res = await Request(
          `/client/catClientDocumentType/${folderId}`,
          {
            isActive: false,
          },
          "PATCH"
        );
        setLoading((prevState) => !prevState);
        setIsUploading(false);
        Swal.fire(
          res.ok ? "Éxito" : "Error",
          res.ok
            ? "Carpeta desactivada con éxito."
            : "Error al desactivar carpeta",
          res.ok ? "success" : "error"
        ).then(() => {
          fetchFolders();
        });
      }
    });
  };

  const fetchFolders = async () => {
    const res = await Request(`/client/catClientDocumentType/`);
    if (res.ok) {
      setFolderTypes(res.data);
    }
  };

  const [renderEditModal, toggle, handleEditFolder] = useModify(
    "Editar carpeta",
    editFolderSchema(),
    fetchFolders,
    setLoading
  );

  const [data, handleInputChange, reset, setAllData] = useForm({
    folderName: "",
    fileName: "",
    file: null,
  });

  useEffect(() => {
    data.file && setAllData({ ...data, fileName: data.file.name });
  }, [data.file]);

  useEffect(() => {
    fetchFolders();
  }, [idClient]);

  return (
    <>
      <Title className={"py-3"}> Gestionar Documentos del Cliente</Title>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 32,
        }}
      >
        <div
          style={{
            width: "49.5%",
            minHeight: "15rem",
            height: "auto",
            boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
            border: "solid 0.5px lightgrey",
            borderRadius: "1vw",
            padding: "1vw 2vw",
          }}
        >
          <FileUploader
            classes="block cursor-pointer"
            style={{ display: "block" }}
            handleChange={(e) => {
              handleInputChange({ target: { name: "file", value: e } });
            }}
            name="file"
            types={enumFileTypes}
          >
            <div
              style={{
                color: "black",
                fontWeight: "bolder",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              {data.file
                ? data.fileName
                : "No hay datos para mostrar, por favor cargue un archivo"}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem",
              }}
            >
              <img src={iconoSubirArchivo} style={{ width: "7rem" }} />
            </div>
            <div style={{ textAlign: "center" }}>Arrastre su archivo</div>
            <div
              style={{
                textAlign: "center",
                fontSize: "0.8rem",
                opacity: 0.7,
              }}
            >
              o elija desde su despositivo
            </div>
          </FileUploader>
          <br />
          <DgInput
            label="Carpeta destino"
            placeholder={"Eliga una carpeta de la lista o cree una nueva.."}
            type="dataList"
            onChange={handleInputChange}
            name="folderName"
            options={parseCat(folderTypes, "name", "name")}
          />
          <DgInput
            disabled={!data.file}
            iconName={"File"}
            label="Nombre de archivo"
            placeholder={"Elija un nombre para su archivo.."}
            type="text"
            onChange={handleInputChange}
            value={data.fileName}
            name="fileName"
          />
          <Button onClick={handleUpload}>Cargar</Button>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              marginTop: "2rem",
              color: "#2A2F99",
              fontWeight: 600,
              margin: "1rem 0rem",
            }}
          >
            LISTA DE CARPETAS
          </div>
          {folderTypes?.map((folder) => (
            <DocumentFolder
              folder={folder}
              toggle={() => {
                fetchDocuments(folder.idCatClientDocumentType);
                setDocumentsListOpen((currentState) => !currentState);
              }}
              erase={() => {
                handleDeactivate(folder.idCatClientDocumentType, folder.name);
              }}
              edit={() => {
                toggle({
                  request: (requestObj) =>
                    Request(
                      `/client/catClientDocumentType/${folder.idCatClientDocumentType}`,
                      requestObj,
                      "PUT"
                    ),
                  name: `la carpeta "${folder.name}"`,
                });
              }}
            />
          ))}
        </div>
      </div>
      <DocumentsList
        setOpen={setDocumentsListOpen}
        open={documentsListOpen}
        setClientAllData={setClientAllData}
        clientData={clientData}
        documents={documents}
        setDocuments={setDocuments}
        fetch={(folderId) => {
          fetchDocuments(folderId);
        }}
      />
      {renderEditModal()}
      <DgLoader open={isUploading} />
    </>
  );
};

export default Documents;
