import React, { useState } from "react";
import PropTypes from "prop-types";
import iconoPerfil from "../../css/img/IconoPerfil.svg";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DgButton from "../../components/Button";
import DgTable from "../../components/DgTable";
import DgTableExpandable from "../../components/DgTableExpandable";
import DgForm from "../../components/DgForm";
import Button from "../../components/Button";
import { getColumnsEditUser } from "../../helpers/datatableHelpers";
import { editUserInputs } from "../../helpers/formUtils";
import { userSchema } from "../../helpers/schemas";
import { useForm } from "../../helpers/useForm";

const ModalPermissions = ({ isOpen, toggle = {} }) => {
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...userSchema,
  });

  const [dataPermission, setDataPermission] = useState([
    {
      idFunction: "Cobranza y CRM",
      watchFunction: "",
      createFunction: "",
      editFunction: "",
      deleteFunction: "",
      expandDisabled: true,
      subModules: [
        {
          idFunction: "Cobranza 1",
          watchFunction: "",
          createFunction: "",
          editFunction: "",
          deleteFunction: "",
        },
      ],
    },
    {
      idFunction: "Facturación",
      watchFunction: "",
      createFunction: "",
      editFunction: "",
      deleteFunction: "",
      expandDisabled: true,
      subModules: [
        {
          idFunction: "Facturación",
          watchFunction: "",
          createFunction: "",
          editFunction: "",
          deleteFunction: "",
        },
      ],
    },
    {
      idFunction: "RH y Nómina",
      watchFunction: "",
      createFunction: "",
      editFunction: "",
      deleteFunction: "",
      expandDisabled: true,
      subModules: [
        {
          idFunction: "RH y nómina 1",
          watchFunction: "",
          createFunction: "",
          editFunction: "",
          deleteFunction: "",
        },
      ],
    },
    {
      idFunction: "Administrador",
      watchFunction: "",
      createFunction: "",
      editFunction: "",
      deleteFunction: "",
      expandDisabled: false,
      subModules: [
        {
          idFunction: "Usuarios",
          watchFunction: "",
          createFunction: "",
          editFunction: "",
          deleteFunction: "",
        },
        {
          idFunction: "Permisos",
          watchFunction: "",
          createFunction: "",
          editFunction: "",
          deleteFunction: "",
        },
        {
          idFunction: "Contraseña",
          watchFunction: "",
          createFunction: "",
          editFunction: "",
          deleteFunction: "",
        },
      ],
    },
  ]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} className="text-center  justify-center">
        <div className="text-xl text-sesGray ml-2">GESTIÓN DE PERMISOS</div>
      </ModalHeader>
      <ModalBody style={{ padding: "1.5rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={iconoPerfil} style={{ width: "3.5rem" }} />
          <div
            style={{
              marginLeft: "1rem",
              fontWeight: 600,
              color: "#2A2F99",
            }}
          >
            Tony Stark
            <br />
            RH y Nómina
          </div>
        </div>

        <DgForm
          data={data}
          onChange={onDataChange}
          groups={editUserInputs(data)}
          colsNumber={1}
          className="position-relative my-2"
        >
          <div className="absolute w-1/2 -top-2 right-2">
            <h2 className="text-sesBlue font-semibold text-lg mt-2">
              PERMISOS
            </h2>
          </div>
          <DgTable
            columnsDef={getColumnsEditUser()}
            data={dataPermission}
            expandableRows={true}
            expandableRowsComponent={DgTableExpandable}
            className="containerTableMidEdit"
            pagination={false}
          ></DgTable>
        </DgForm>
      </ModalBody>

      <ModalFooter>
        <div className="flex space-between w-full mb-4">
          <DgButton
            onClick={toggle}
            type="submit"
            color="secondary"
            innerClassName="px-4 font-regular w-3/4 ml-2"
          >
            Cancelar
          </DgButton>
          <DgButton
            onClick={toggle}
            innerClassName="px-4 font-regular w-3/4 relative float-right"
          >
            Guardar cambios
          </DgButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalPermissions.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalPermissions;
