import React from "react";
import Button from "./Button";
import {
  BsFileArrowDown,
  BsFilePdf,
  BsFiletypeXml,
  BsMailbox,
} from "react-icons/bs";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import ModalDetails from "../pages/invoicing/modals/ModalDetails";
import { handleDownloadClick } from "../helpers/utils";
import PostInvoiceCardTitle from "./PostInvoiceCardTitle";
import PostPaymentComplementCardTitle from "./PostPaymentComplementCardTitle";
import ModalSendEmail from "../pages/invoicing/modals/ModalSendEmail";
import PostGeneralInvoiceTitle from "./PostGeneralInvoiceTitle";

function PostInvoiceCard({ data }) {
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const { dataInvoiceService } = data;
  const downloadPDF = (pdfUrl) => {
    handleDownloadClick(pdfUrl);
  };
  const downloadXML = (xmlUrl) => {
    handleDownloadClick(xmlUrl);
  };

  return (
    <>
      <div className="flex w-11/12 gap-4 justify-evenly p-4 bg-white shadow-md hover:shadow-xl transition-all flex-wrap rounded-xl">
        <div className="w-full">
          {dataInvoiceService.cfdiType === "generalInvoice" ? (
            <PostGeneralInvoiceTitle data={dataInvoiceService} />
          ) : data.generatedPaymentComplement ? (
            <PostPaymentComplementCardTitle data={dataInvoiceService} />
          ) : (
            <PostInvoiceCardTitle data={dataInvoiceService} />
          )}
        </div>
        <Tooltip title="Visualizar factura (PDF)">
          <div>
            <Button
              className="w-fit shadow-md hover:shadow-xl transition-all"
              onClick={() => {
                setModalOpenInvoice((prevState) => !prevState);
              }}
            >
              <BsFilePdf size={50} />
            </Button>
          </div>
        </Tooltip>
        <Tooltip title="Descargar factura (PDF)">
          <div>
            <Button
              onClick={() => {
                downloadPDF(data.urlPdf);
              }}
              className="w-fit"
            >
              <BsFileArrowDown size={50} />
            </Button>
          </div>
        </Tooltip>
        <Tooltip
          title={data.urlXml ? "Descargar factura (XML)" : "No es posible"}
        >
          <div>
            <Button
              disabled={!data.urlXml}
              onClick={() => {
                downloadXML(data.urlXml);
              }}
              className="w-fit"
            >
              <BsFiletypeXml size={50} />
            </Button>
          </div>
        </Tooltip>
        <Tooltip title="Enviar CFDIs">
          <div>
            <Button
              onClick={() => {
                setSendEmailOpen((prevState) => !prevState);
              }}
              className="w-fit"
            >
              <BsMailbox size={50} />
            </Button>
          </div>
        </Tooltip>
      </div>
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={{ ...data, urlInvoice: data.urlPdf }}
      />
      <ModalSendEmail
        idService={dataInvoiceService?.detailService?.idDetailService}
        toggle={() => setSendEmailOpen((prevState) => !prevState)}
        isOpen={sendEmailOpen}
        endPoint={`/client/mailBill`}
        cfdiType={dataInvoiceService?.cfdiType}
        internalFolio={dataInvoiceService?.internalFolio}
       
      />
    </>
  );
}

export default PostInvoiceCard;
