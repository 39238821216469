import React, { useEffect, useState } from "react";
import { Eye } from "lucide-react";
import { commentSchema } from "../../../helpers/schemas";
import Swal from "sweetalert2";
import { Request } from "../../../helpers/api";
import { useParams } from "react-router-dom";
import DgForm from "../../../components/DgForm";
import Button from "../../../components/Button";
import { useForm } from "../../../helpers/useForm";
import { parseObjectDate } from "../../../helpers/utils";
import DgModal from "../../../components/DgModal";
import ListItem from "../../../components/ListItem";
import Title from "../../../components/Title";

const Comments = ({
  clientData = {},
  setClientAllData,
  setLoading,
  loading,
}) => {
  const { id: idClient } = useParams();
  const [modalState, setModalState] = useState(false);
  const [clientComment, setClientComment] = useState("");
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...commentSchema,
  });
  useEffect(() => {
    setAllData(clientData);
  }, []);
  const getFields = () => [
    {
      fields: [
        {
          label: "Título",
          placeholder: "Título",
          type: "text",
          name: "title",
          // value: data.title,
          onInputChange: onDataChange,
          minLength: "1",
          required: true,
          iconName: "FormInput",
          errorMessage: "Este título no es válido",
        },
        {
          label: "Comentarios",
          placeholder: "Escriba aqui...",
          type: "textarea",
          name: "description",
          // value: data.description,
          onInputChange: onDataChange,
          required: true,
          iconName: "MessageCircle",
          errorMessage: "Un comentario es requerido.",
        },
      ],
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.description?.length > 250)
      return Swal.fire(
        "Error",
        "El comentario debe tener un máximo de 250 caracteres.",
        "error"
      );

    const res = await Request(
      `/client${"/" + idClient}/comment`,
      { ...data, idClient },
      "POST"
    );
    setClientAllData({ ...clientData, ...res.data });
    if (res.ok) {
      Swal.fire("Éxito", "Comentario añadido con éxito.", "success").then(
        () => {
          setLoading(!loading);
        }
      );
    }
  };
  return (
    <>
      <DgModal
        open={modalState}
        toggle={() => setModalState((currentState) => !currentState)}
      >
        <h1
          style={{
            color: "rgb(42, 47, 153)",
            fontWeight: "700",
          }}
        >
          {clientComment.title}
        </h1>
        <h2>
          {`${clientComment.userName} - ${parseObjectDate(
            new Date(clientComment.createdAt)
          )}`}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "fit-content",
            padding: "0% 2% 0% 2%",
          }}
        >
          {clientComment?.description && clientComment.description}
        </div>
      </DgModal>

      <Title className={"pt-3"}> Comentarios</Title>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "45%" }}>
          <DgForm
            data={data}
            classNameGrid="grid grid-cols-1"
            onDataChange={onDataChange}
            onSubmit={handleSubmit}
            groups={getFields()}
          >
            <Button type="submit">Añadir Comentario</Button>
          </DgForm>
        </div>
        <div style={{ width: "5%" }}></div>
        <div style={{ width: "50%" }}>
          <div
            style={{
              marginTop: "2rem",
              color: "#2A2F99",
              fontWeight: 600,
              margin: "1rem 0rem",
            }}
          >
            LISTA DE COMENTARIOS
          </div>
          {clientData.comments?.map((sc) => {
            return (
              <ListItem
                setOpenModal={setModalState}
                setSingleData={setClientComment}
                singleData={sc}
                title={sc.title}
                description={`${sc.userName} - ${parseObjectDate(
                  new Date(sc.createdAt)
                )}`}
              >
                {sc.description}
              </ListItem>
            );
          })}
          {clientData.comments?.length === 0 && <div>Sin Comentarios</div>}
        </div>
      </div>
    </>
  );
};

export default Comments;
