import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Request } from "../../../helpers/api";
import { getColumnsCreditNotes } from "../../../helpers/datatableHelpers";
import DgButton from "../../../components/Button";
import Swal from "sweetalert2";
import InvoiceDetails from "./ModalDetails";
import DgTable from "../../../components/DgTable";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalCancel from "./ModalCancel";
import { handleCancelCfdi } from "../handlers";
import { handleDownloadClick } from "../../../helpers/utils";
import DgLoader from "../../../components/DgLoader";

const ModalServiceCreditNotes = ({
  isOpen,
  toggle,
  data: serviceData,
  fetchData: fetchInvData,
  zIndex,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalOpenCancel, setModalOpenCancel] = useState(false);
  const [payments, setPayments] = useState([]);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [singleData, setSingleData] = useState({});
  const fetchData = useCallback(async (idDetailService) => {
    const { ok, data } = await Request(
      `/service/detailservice/${idDetailService}/creditNotes`
    );
    if (ok) {
      setPayments(data);
    }
  }, []);
  const dtFuncs = {
    paymentDocument: (row) => () => {
      setSingleData({
        ...row,
        urlPaymentComplement: row.urlInvoice,
        document: "payment-complement",
      });
      setModalOpenInvoice(true);
    },
    cancelCreditNote: (row) => () => {
      setSingleData(row);
      setModalOpenCancel((prevData) => !prevData);
    },
    downloadXML: (xmlUrl) => {
      handleDownloadClick(xmlUrl, setLoading);
    },
    downloadPDF: (pdfUrl) => {
      handleDownloadClick(pdfUrl, setLoading);
    },
  };
  useEffect(() => {
    if (serviceData.idDetailService && isOpen) {
      fetchData(serviceData.idDetailService);
    }
  }, [serviceData]);

  return (
    <>
      <Modal
        zIndex={zIndex}
        toggle={toggle}
        backdrop={true}
        size="lg"
        isOpen={isOpen}
      >
        <ModalHeader>
          <div
            style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
          >
            Notas de crédito
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "3rem" }}>
          <DgTable
            columnsDef={getColumnsCreditNotes(dtFuncs)}
            data={payments}
          />
          <div className="w-full grid grid-cols-2 gap-4">
            <div />
            <DgButton color="secondary" onClick={toggle}>
              Cerrar
            </DgButton>
          </div>
          <DgLoader open={loading} />
        </ModalBody>
      </Modal>
      <InvoiceDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={singleData}
      />
      <ModalCancel
        isOpen={modalOpenCancel}
        toggle={() => {
          setModalOpenCancel((prevData) => !prevData);
        }}
        showForm={false}
        data={singleData}
        onConfirm={(epBody) => {
          handleCancelCfdi(
            singleData,
            {
              endPoint: "/invoice/creditNote/serviceDetailCfdi/",
              cfdiId: "idServiceDetailCfdi",
              epBody,
              setLoading: setLoading,
            },
            () => {
              fetchData(serviceData.idDetailService);
              fetchInvData();
            },
            {
              text: "Cancelar nota de crédito",
              onSuccessMessage: "Nota cancelada con éxito!",
            }
          );
        }}
        filterCancelMethodBy={"applyCreditNote"}
        loading={loading}
      />
    </>
  );
};
export default ModalServiceCreditNotes;
