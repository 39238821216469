import { TODAY } from "./consts";
import { parseCat, parseObjectDate } from "./utils";

export const accountSchema = {
  idCatBank: "",
  account: "",
  idClientAccount: "",
};
export const clientSchema = {
  companyName: "",
  rfc: "",
  phone: "",
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  postalCode: "",
  municipality: "",
  state: "",
  country: "MX",
  idCatCfdiUsage: "",
  idCatPaymentMethod: "",
  idCatRegime: "",
  clientType: "NONFISCAL",
  counterName: "",
  counterEmail: "",
  administratorName: "",
  administratorEmail: "",
  administratorPhone: "",
  paymentMethod: "",
  clientAccounts: [], // TODO: REVIEW KEY
  clientContacts: [],
};
export const clientDetailSchema = {
  ...clientSchema,
  services: [],
  documents: [],
  comments: [],
  regime: [],
};
export const serviceSchema = {
  idClient: "",
  idCatRegion: "",
  idCatSubregion: "",
  streetService: "",
  internalNumberService: "",
  outdoorNumberService: "",
  suburbService: "",
  postalCodeService: "",
  name: "",
  municipalityService: "",
  stateService: "",
  countryService: "MX",
  idFiscalPeriod: "",
  paymentPeriod: "",
  invoicePeriod: "",
  isRecurring: false,
  clientTypeService: "FISCAL",
  serviceConcepts: [],
  detailService: [],
  distributeAmounts: false,
  editTemplate: true,
};
export const conceptSchema = {
  idService: "",
  idCatServiceConcept: "",
  quantity: "",
  description: "",
  price: "",
  absenceDiscount: 0,
};
export const subClientSchema = {
  nameDetailService: "",
  tradeNameDetailService: "",
  rfc: "",
  postalCode: "",
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  municipality: "",
  state: "",
  country: "Mexico",
  amount: "",
  idCatCfdi: "",
  idCatRegime: "",
  idCatPaymentWay: "",
  paymentMethod: "PUE",
  applyClientFiscalData: false,
  clientTypeDetailService: "FISCAL",
};

export const commentSchema = {
  idComment: 0,
  idClient: 0,
  idUser: "",
  title: "",
  description: "",
};
export const invoiceSchema = {
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  postalCode: "",
  name: "",
  rfc: "",
  municipality: "",
  state: "",
  country: "MX",
  amount: "",
  idCatCfdiUsage: "",
  idCatPaymentMethod: "",
  idCatRegime: "",
};
export const invoicePaymentSchema = {
  comments: "",
  paymentDate: "",
  amount: "",
};
export const invoiceCreditNoteSchema = {
  description: "",
  amount: "",
};
export const userSchema = {
  name: "",
  idCatRole: "",
  email: "",
  password: "",
  passwordConfirm: "",
  permissions: [],
};
export const invoicePaymentComplementSchema = {
  idClientAccount: "",
  idSesAccount: "",
};

export const personSchema = {
  // First toggle
  personNames: "",
  paternalLastName: "",
  maternalLastName: "",
  employeeKey: "",
  birthDate: "",
  age: "100",
  gender: "",
  phone: "",
  secondaryPhone: "",
  hasAbsorptionDate: "",
  absorptionDate: "",
  // Second toggle
  birthPlace: "",
  weight: "",
  height: "",
  idCatEducationalLevel: "",
  idCatMaritalStatus: "",
  antidopingDate: "",
  // Third toggle
  street: "",
  postalCode: "",
  outdoorNumber: "",
  internalNumber: "",
  suburb: "",
  municipality: "",
  state: "",
  country: "MX",
  // Fourth toggle
  rfc: "",
  curp: "",
  imssNumber: "",
  imssComments: "",
  hasInfonavit: "",
  givesAlimony: "",
  militarService: "", // TODO: MISSING BE
  votingLisenseNumber: "",
  idCatProofResidence: "",
  idCatBank: "",
  cardNumber: "",
  interbankKey: "",
  file: "", // TODO: MISSING BE
  emergencyContacts: [],
  beneficiaries: [],
};
export const emergencyContactSchema = {
  // First toggle
  fullName: "",
  relationship: "",
  phone: "",
};
export const beneficiariesSchema = {
  // First toggle
  fullName: "",
  phone: "",
  address: "",
  percentage: "",
};
export const incidenceSchema = {
  idCatEmployeeIncidence: "",
  dateIncidence: parseObjectDate(TODAY, 4),
  commentIncidence: "",
  idFiscalPeriod: "",
};

export const compensationSchema = {
  employeeCompensationType: "",
  employeeCompensationDate: parseObjectDate(TODAY, 4),
  employeeCompensationAmount: "",
  commentEmployeeCompensation: "",
};

export const doubletSchema = {
  idService: "",
  doubletDate: "",
  turn: "",
  idCatReasonPaymentDoubleWork: "",
  commentDoublet: "",
};

export const vacationsSchema = {
  idEmployee: "",
  idEmployeeEntry: "",
  daysNumber: "",
  startDate: "",
  endDate: "",
  returnDate: "",
  comments: "",
};

export const egressSchema = {
  idCatReasonEmployeeInactive: "",
  commentSuspension: "",
  suspensionDate: "",
};

export const reingressSchema = {
  idCatReasonEmployeeActive: "",
  commentEmployeeEntry: "",
  dateEntry: "",
};

export const statusPaymentDoubleWorkSchema = {
  idCatStatusPaymentDoubleWork: "2",
  commentStatusPaymentDoubleWork: "",
};

export const statusPaymentRollSchema = {
  idCatStatusPaymentPayroll: "2",
  idCatBank: "",
  commentStatusPaymentPayroll: "",
};

export const depositSchema = {
  paymentDate: "",
  transmitterBankName: "",
  reciperBankName: "",
  amount: "",
  payedBills: "",
  positiveAmount: "",
};
export const servicesPayedWithCertainDepositSchema = {
  serviceName: "",
  amount: "",
  paymentComplement: false,
};

export const clientDepositsAndServicesSchema = {
  services: [],
  bankAccounts: [],
  deposits: [],
  servicesToPay: {
    detailPayments: [],
    bankDeposits: [],
    generatePaymentComplement: false,
  },
};

export const invoicingHistoryFiltersSchema = (filteringData, handlers) => {
  const { onChange } = handlers;
  return [
    {
      label: "Cliente",
      name: "client",
      placeholder: "Nombre de cliente..",
      onChange: onChange,
      type: "dataList",
      iconName: "User",
      options: parseCat(filteringData.clients, "name", "idClient"),
      containerStyle: { width: "20%", margin: "8px 0px" },
    },
    {
      label: "Folio",
      name: "internalFolio",
      placeholder: "A000000013",
      type: "text",
      iconName: "Filter",
      onChange: onChange,
      containerStyle: { width: "20%" },
    },
    {
      label: "Monto",
      name: "amount",
      placeholder: "520",
      type: "text",
      iconName: "DollarSign",
      onChange: onChange,
      containerStyle: { width: "20%" },
    },
    {
      label: "Fecha inicial",
      name: "initialDate",
      placeholder: "Fecha inicial..",
      onChange,
      type: "date",
      iconName: "Clock",
      containerStyle: { width: "18%" },
    },
    {
      label: "Fecha final",
      name: "endDate",
      placeholder: "Fecha final..",
      onChange,
      type: "date",
      iconName: "Clock",
      containerStyle: { width: "18%" },
    },
    {
      label: "Tipo de CFDI",
      name: "catCfdiType",
      placeholder: "CFDI...",
      onChange: onChange,
      type: "select",
      options: [
        { label: "Todos", value: "" },
        ...parseCat(filteringData.cfdiTypes, "name", "idCatCfdiType"),
      ],
      iconName: "File",
      containerStyle: { width: "16%" },
    },
    {
      label: "Status de CFDI",
      name: "isActive",
      placeholder: "Status de CFDI...",
      onChange: onChange,
      options: [
        { label: "Todos", value: "" },
        { label: "Activo", value: "active" },
        { label: "Inactivo", value: "inactive" },
        { label: "Pendiente de cancelación SAT", value: "pending" },
        { label: "Cancelado SAT", value: "canceled" },
      ],
      type: "select",
      iconName: "FileClock",
      containerStyle: { width: "16%" },
    },
  ];
};

export const generalInvoicingServicesSchema = {
  description: "",
  paymentsDetailsServices: [],
};

export const sendEmailInputs = (data, handlers) => {
  const { onChange } = handlers;
  const {
    emailAccountConfigs,
    clientContacts,
    cc,
    subject,
    idEmailAccountConfig,
  } = data;
  return [
    {
      type: "select",
      name: "idEmailAccountConfig",
      label: "Emisor",
      placeholder: "Ingrese el emisor..",
      required: true,
      iconName: "User",
      onChange,
      value: idEmailAccountConfig,
      options: [
        { label: "Elige un emisor..", value: "" },
        ...parseCat(emailAccountConfigs, "user", "idEmailAccountConfig"),
      ],
      containerClassName: "m-0",
    },
    {
      type: "contactList",
      name: "clientContacts",
      label: "Destinatarios",
      placeholder: "Ingrese los destinatarios separados por una coma..",
      required: true,
      iconName: "Users",
      onChange,
      value: clientContacts,
      containerClassName: "m-0",
    },
    {
      type: "contactList",
      name: "cc",
      label: "CC",
      placeholder:
        "Ingrese los destinatarios con copia separados por una coma..",
      required: true,
      iconName: "Mails",
      onChange,
      value: cc,
      containerClassName: "m-0",
    },
    {
      type: "Text",
      maxLength: "75",
      name: "subject",
      label: "Asunto del correo",
      placeholder: "Recordatorio de cobranza..",
      required: true,
      iconName: "Info",
      onChange,
      value: subject,
      containerClassName: "m-0",
    },
  ];
};
