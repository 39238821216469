import React, { useEffect, useState } from "react";
import { Bell, User, ArrowBigRight } from "lucide-react";
import { useSelector } from "react-redux";
import { Request } from "../helpers/api";
import DgContainer from "./DgContainer";
import useGetScreenWidth from "../helpers/useGetScreenWidth";

const TopBar = ({ pageTitle = "" }) => {
  const { urlImage, username, role } = useSelector((s) => s.auth.data);
  const [initialFolios, setInitialFolios] = useState("");
  const [folios, setFolios] = useState("");
  const [screenWidth, handleScreenWidth] = useGetScreenWidth();

  const fetchFolios = async () => {
    const folios = await Request("/invoice/accountInfo");
    if (!initialFolios) {
      setFolios(folios?.result?.CurrentFolios);
    } else setInitialFolios(folios?.result?.CurrentFolios);
  };

  useEffect(() => {
    handleScreenWidth();
    if (!initialFolios) {
      fetchFolios();
    }
    const interval = setInterval(() => {
      fetchFolios();
    }, 120 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{ flexDirection: screenWidth < 800 && "column" }}
      className="topBar w-full bg-sesInputBG z-10"
    >
      <div className="pageTitleContainer">
        <div className="pageTitle">{pageTitle}</div>
        <DgContainer className="foliosContainer">
          <Bell />
          <p>Folios - {folios}</p>
        </DgContainer>
      </div>

      <div
        className="w-1/4 relative float-right right-0"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {/* <button className="bg-sesBlue w-10 h-10 border-none relative float-left mx-2 rounded-full">
          <Bell color="white" size={28} className="m-auto" />
        </button> */}
        <div className="bg-sesBlue w-10 h-10 border-none relative float-left mx-2 rounded-full">
          {urlImage ? (
            <img style={{ aspectRatio: 1, borderRadius: 100 }} src={urlImage} />
          ) : (
            <User
              style={{ cursor: "initial" }}
              color="white"
              size={28}
              className="m-auto"
            />
          )}
        </div>
        <div
          className="relative float-left"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3 className="cssUserName text-base text-sesGray font-semibold">
              {username}
            </h3>
            {/* <Settings color="#565656" size={20} /> */}
          </div>
          <p className="cssUserRole font-regular text-sm text-sesGray">
            {role || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
