import React from "react";
import DgModal from "../../../components/DgModal";
import { registerDepositSchema } from "../../../helpers/formUtils";
import DgInput from "../../../components/DgInput";
import DgTable from "../../../components/DgTable";
import {
  getColumnsPositiveDeposits,
  getColumnsServicesToPay,
} from "../../../helpers/datatableHelpers";
import Button from "../../../components/Button";
import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useFormWithValidations } from "../../../helpers/useFormWithValidations";
import { Request } from "../../../helpers/api";
import { useEffect } from "react";
import { parseCat, unformatThousands } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { clientDepositsAndServicesSchema } from "../../../helpers/schemas";
import DgLoader from "../../../components/DgLoader";
import { handleInvoice } from "../handlers";
import PostInvoiceModal from "./PostInvoiceModal";
import useGetScreenWidth from "../../../helpers/useGetScreenWidth";
import CancelCashinModal from "../../cashing-crm/modals/CancelCashinModal";

function ModalServiceDeposits({
  open,
  toggle,
  title = "REGISTRO DE DEPÓSITO",
  fetchData: fetchInvoicingTableData,
}) {
  const [width] = useGetScreenWidth();
  const [showForm, setShowForm] = useState(false);
  const { SesAccount, CatPaymentWay } = useSelector(
    (globalState) => globalState.catalogues
  );
  const [
    handleChange,
    [register, handleSubmit, setValue, { errors }, watch, reset, getValues],
  ] = useFormWithValidations();
  const [cfdiList, setCfdiList] = useState([]);
  const [postCfdiModal, setPostCfdiModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState({
    ...clientDepositsAndServicesSchema,
  });
  const [cancelCashinModalOpen, setCancelCashinModalOpen] = useState(false);
  const [depositDetails, setDepositDetails] = useState({});
  const [singleData, setSingleData] = useState({});

  const submitDeposit = async (clientId, body) => {
    let depositId = null;
    setLoading(true);
    const { ok, error, idBankDeposit } = await Request(
      `/client/${clientId}/bankDeposit`,
      body,
      "POST"
    );
    setLoading(false);
    if (ok && clientId) {
      Swal.fire("Nuevo depósito", "Depósito registrado con éxito", "success");
      depositId = idBankDeposit;
      fetchClientData(clientId);
    } else {
      Swal.fire("Nuevo depósito", error, "error");
    }
    return depositId;
  };

  const applyDeposit = async (clientId, data) => {
    setLoading(true);
    const newData = {
      ...data,
      detailPayments: data.detailPayments.map((el) => ({
        ...el,
        amount: unformatThousands(el.amount),
      })),
    };
    const { generatePaymentComplement: paymentComplement } = data;
    const { ok, dataGeneratePaymentComplement, error } = await Request(
      `/client/${clientId}/payment`,
      newData,
      "POST"
    );
    setLoading(false);
    if (ok) {
      Swal.fire("Depósito", "Depósito aplicado con éxito", "success").then(
        async ({ isDismissed, isConfirmed }) => {
          if (paymentComplement && (isDismissed || isConfirmed)) {
            setLoading(true);

            const {
              ok: paymentComplementOk,
              objupdateafacturar,
              msg,
            } = await Request(
              `/invoice/paymentcomplement`,
              dataGeneratePaymentComplement,
              "POST"
            );
            setLoading(false);
            if (paymentComplementOk) {
              handleInvoice(objupdateafacturar, () => {
                setPostCfdiModalOpen(true);
                setCfdiList(objupdateafacturar);
                reset({clientId})
              });
            } else {
              Swal.fire("Complemento de pago", error, "error");
            }
          }
        }
      );

      setClientData(clientDepositsAndServicesSchema);
      fetchClientData(clientId);
      fetchInvoicingTableData();
    } else {
      Swal.fire("Depósito", error, "error");
    }
  };

  const submitAndApplyDeposit = async (clientId, body) => {
    const idBankDeposit = await submitDeposit(clientId, body);
    const dataToApplyDeposit = {
      ...clientData,
      servicesToPay: {
        ...clientData.servicesToPay,
        bankDeposits: [{ idBankDeposit: idBankDeposit }],
      },
    };
    applyDeposit(clientId, dataToApplyDeposit.servicesToPay);
  };

  const cancelDeposit = async (idBankDeposit) => {
    const { ok } = await Request(
      `/client/bankDeposit/${idBankDeposit}`,
      {},
      "PATCH"
    );
    if (ok) {
      Swal.fire({
        title: "Éxito",
        text: "Deposito cancelado con éxito.",
        icon: "success",
      }).then(({ isConfirmed, isDismissed }) => {
        fetchClientData(getValues().clientId);
        (isConfirmed || isDismissed) &&
          setCancelCashinModalOpen((prevState) => !prevState);
      });
    }
  };

  const fetchClientsList = async () => {
    const { data, ok } = await Request("/client/serviceTemplate/");
    ok && setClients(data);
  };

  const resetClientData = () => {
    setShowForm(false);
    setClientData({ ...clientDepositsAndServicesSchema });
    reset();
  };

  const fetchClientData = async (clientId) => {
    if (clientId) {
      const { data: services, ok: okServices } = await Request(
        `/service/serviceDetail/${clientId}/client?pendingPayable=true&invoiced=true&remissioned=true`
      );
      const { data: bankAccounts, ok: okBankAccounts } = await Request(
        `/client/${clientId}/accounts`
      );
      const { data: deposits, ok: okDeposits } = await Request(
        `/client/${clientId}/bankDeposit?isActive=true`
      );
      if (okServices) {
        setClientData((clientData) => {
          return { ...clientData, services };
        });
      }
      if (okBankAccounts) {
        setClientData((clientData) => {
          return { ...clientData, bankAccounts };
        });
      }
      if (okDeposits) {
        setClientData((clientData) => {
          return { ...clientData, deposits };
        });
      }
    }
  };

  useEffect(() => {
    if (getValues().clientId && typeof getValues().clientId != "string")
      fetchClientData(getValues().clientId);
  }, [getValues().clientId]);

  useEffect(() => {
    resetClientData();
    fetchClientsList();
  }, [open]);

  const eyeIconStyle = {
    display: "flex",
    gap: 5,
    color: "rgb(42, 47, 153)",
    cursor: "pointer",
    fontWeight: 500,
    alignItems: "center",
    padding: 0,
    margin: 0,
  };
  const eyeIcon = showForm ? (
    <div
      onClick={() => setShowForm((prevState) => !prevState)}
      style={eyeIconStyle}
    >
      <BsEyeSlash size={25} />
      Ocultar formulario
    </div>
  ) : (
    <div
      onClick={() => setShowForm((prevState) => !prevState)}
      style={eyeIconStyle}
    >
      <BsEye
        size={25}
        color="rgb(42, 47, 153)"
        onClick={() => setShowForm((prevState) => !prevState)}
      />
      Mostrar formulario
    </div>
  );
  const servicesFuncs = {
    checkAll: () => {},
    onSelectCheck: (row, checked) => {
      const { idDetailService } = row;
      const indexOfServiceToSelect = clientData.services.findIndex(
        ({ idDetailService: idServiceToSelect }) =>
          idServiceToSelect === idDetailService
      );
      const selectedService = {
        ...clientData.services[indexOfServiceToSelect],
        selected: checked,
      };

      const copyOfClientServices = [...clientData.services];
      copyOfClientServices[indexOfServiceToSelect] = selectedService;
      setClientData((previousClientData) => {
        return { ...previousClientData, services: copyOfClientServices };
      });
    },
    handleAmountsChange: ({ idDetailService, selected }, amount, erase) => {
      if (selected && !erase) {
        //Si está seleccionado, voy a tenerlo en cuenta para capturar el monto.
        //Si ya existe un objeto con el mismo id en el array de servicios, entonces sólo modifico su monto.
        //Sino, creo un nuevo objeto desde 0 y lo incluyo al array destructurandolo.
        const servicesCopy = [...clientData.servicesToPay.detailPayments];
        const possibleAlreadyExistentIndex = servicesCopy.findIndex(
          ({ idDetailService: possibleId }) => possibleId === idDetailService
        );
        if (possibleAlreadyExistentIndex >= 0) {
          const serviceToModify = {
            ...servicesCopy[possibleAlreadyExistentIndex],
            amount,
          };
          servicesCopy[possibleAlreadyExistentIndex] = serviceToModify;
          setClientData((prevClientData) => {
            return {
              ...prevClientData,
              servicesToPay: {
                ...prevClientData.servicesToPay,
                detailPayments: servicesCopy,
              },
            };
          });
        } else {
          setClientData((prevClientData) => {
            const serviceToModify = {
              idDetailService,
              amount,
            };
            const modifiedServicesToPay = [
              ...prevClientData.servicesToPay.detailPayments,
              serviceToModify,
            ];
            return {
              ...prevClientData,
              servicesToPay: {
                ...prevClientData.servicesToPay,
                detailPayments: modifiedServicesToPay,
              },
            };
          });
        }
      } else if (erase) {
        setClientData((prevClientData) => {
          const prevServicesToPay = [
            ...prevClientData.servicesToPay.detailPayments,
          ];
          return {
            ...prevClientData,
            servicesToPay: {
              ...prevClientData.servicesToPay,
              detailPayments: prevServicesToPay.filter(
                ({ idDetailService: idServiceToRemove }) =>
                  idServiceToRemove !== idDetailService
              ),
            },
          };
        });
      }
    },
  };
  const depositsFuncs = {
    checkAll: () => {},
    applyDeposit: (row, checked) => {
      const { idBankDeposit, positiveBalance } = row;
      if (checked) {
        setClientData((previousClientData) => {
          const depositToInclude = { idBankDeposit, amount: positiveBalance };
          const modifiedDepositList = [
            ...clientData.servicesToPay.bankDeposits,
            depositToInclude,
          ];
          return {
            ...previousClientData,
            servicesToPay: {
              ...previousClientData.servicesToPay,
              bankDeposits: modifiedDepositList,
            },
          };
        });
      } else {
        setClientData((previousClientData) => {
          const depositsListToModify =
            clientData.servicesToPay.bankDeposits.filter(
              ({ idBankDeposit: idBankToRemove }) =>
                idBankToRemove !== idBankDeposit
            );

          return {
            ...previousClientData,
            servicesToPay: {
              ...previousClientData.servicesToPay,
              bankDeposits: depositsListToModify,
            },
          };
        });
      }
    },
    cancelDeposit: async (row) => {
      const { ok, data } = await Request(
        `/client/bankDeposit/${row.idBankDeposit}/paymentDetailService`
      );
      ok && setDepositDetails(data);
      setSingleData(row);
      setCancelCashinModalOpen((prevState) => !prevState);
    },
  };

  return (
    <DgModal
      style={{
        height: width <= 1200 ? "100%" : "90%",
        width: width <= 1200 ? "100%" : "95%",
        overflow: "auto",
        paddingBottom: 36,
      }}
      title={title}
      open={open}
      toggle={toggle}
    >
      <div className="flex flex-col px-9 w-full">
        {eyeIcon}
        {showForm && (
          <form
            className="flex h-max flex-wrap gap-[2%]"
            onSubmit={handleSubmit((data) => {
              data.applyDeposit
                ? submitAndApplyDeposit(data.clientId, data)
                : submitDeposit(data.clientId, data);

              setShowForm(false);
              setValue("idCatPaymentWay", null);
              setValue("amountDeposit", 0);
              setValue("depositDate", null);
            })}
          >
            {registerDepositSchema(
              getValues(),
              { onDataChange: handleChange, setValue },
              {
                clientBankAccounts: parseCat(
                  clientData.bankAccounts,
                  "account",
                  "idClientAccount",
                  false,
                  "bank"
                ),
                receivingBanks: parseCat(
                  SesAccount,
                  "account",
                  "idSesAccount",
                  false,
                  "bank"
                ),
                paymentWays: parseCat(
                  CatPaymentWay,
                  "name",
                  "idCatPaymentWay",
                  "isDeposit"
                ),
                clients: parseCat(clients, "name", "idClient"),
              }
            ).map((inputSchema) => {
              return (
                <DgInput
                  {...{ ...inputSchema }}
                  {...register(inputSchema.name, inputSchema.validations)}
                  error={errors[inputSchema.name]}
                  errorMessage={errors[inputSchema.name]?.message}
                />
              );
            })}
            <Button
              type="submit"
              onClick={handleSubmit}
              containerStyle={{ width: 500 }}
            >
              {getValues().applyDeposit
                ? "Registrar y aplicar depósito"
                : "Registrar depósito"}
            </Button>
          </form>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: 100,
          }}
        >
          <div>
            <h1 style={{ color: "rgb(42, 47, 153)", paddingBottom: 6 }}>
              Servicios a pagar
            </h1>
            <DgTable
              rootContainerStyle={{ borderRadius: 10 }}
              data={clientData.services}
              columnsDef={getColumnsServicesToPay(servicesFuncs, {
                deposits: clientData.servicesToPay.detailPayments,
              })}
            />
          </div>
          <div>
            <h1 style={{ color: "rgb(42, 47, 153)", paddingBottom: 6 }}>
              Depósitos con saldo a favor
            </h1>
            <DgTable
              rootContainerStyle={{ borderRadius: 10 }}
              data={clientData.deposits}
              columnsDef={getColumnsPositiveDeposits(depositsFuncs)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-5 flex-col p-3">
        <DgInput
          placeholder="Generar complemento de pago"
          type={"checkbox"}
          name={"generatePaymentComplement"}
          required={false}
          containerStyle={{ margin: 0 }}
          value={clientData?.servicesToPay?.generatePaymentComplement}
          onChange={(e) => {
            setClientData((prevClientData) => {
              return {
                ...prevClientData,
                servicesToPay: {
                  ...prevClientData.servicesToPay,
                  generatePaymentComplement: e.target.checked,
                },
              };
            });
          }}
        />
        <Button
          onClick={() => {
            applyDeposit(getValues().clientId, clientData.servicesToPay);
          }}
          containerStyle={{ margin: 0 }}
        >
          Aplicar depósito
        </Button>
      </div>
      <DgLoader open={loading} />
      <PostInvoiceModal
        title="Post complemento de pago"
        data={cfdiList}
        open={postCfdiModal}
        toggle={() => setPostCfdiModalOpen((prevState) => !prevState)}
        withSend={{
          title: "Cerrar",
          action: () => {
            setPostCfdiModalOpen((prevState) => !prevState);
          },
        }}
      />
      <CancelCashinModal
        data={depositDetails}
        open={cancelCashinModalOpen}
        toggle={() =>
          setCancelCashinModalOpen((previousState) => !previousState)
        }
        confirmAction={() => {
          cancelDeposit(singleData.idBankDeposit);
        }}
      />
    </DgModal>
  );
}

export default ModalServiceDeposits;
