import { Tooltip } from "@mui/material";
import { FolderClosed, FolderOpen, Pencil, Slash } from "lucide-react";
import React, { useState } from "react";

const DocumentFolder = ({ folder, erase, toggle = () => {}, edit }) => {
  const [openFolder, setOpenFolder] = useState(false);
  const [folderPressed, setFolderPressed] = useState(false);
  const folderIcon = openFolder ? (
    <FolderOpen color="rgb(42, 47, 153)" size={30} />
  ) : (
    <FolderClosed color="rgb(42, 47, 153)" size={30} />
  );
  return (
    <div style={styles.rootContainer}>
      <div style={styles.nameContainer}>
        <div
          onClick={toggle}
          onMouseOver={() => setOpenFolder(true)}
          onMouseOut={() => setOpenFolder(false)}
          onMouseDown={() => {
            setFolderPressed(true);
          }}
          onMouseUp={() => {
            setFolderPressed(false);
          }}
          style={styles.folderIcon(folderPressed)}
        >
          {folderIcon}
        </div>

        {folder.name}
      </div>
      <div style={styles.actionIcons}>
        {edit && (
          <Tooltip onClick={edit} title="Editar carpeta">
            <Pencil style={{ cursor: "pointer" }} color="rgb(42, 47, 153)" />
          </Tooltip>
        )}
        {erase && (
          <Tooltip onClick={erase} title="Desactivar carpeta">
            <Slash style={{ cursor: "pointer" }} color="rgb(42, 47, 153)" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const styles = {
  rootContainer: {
    display: "flex",
    padding: "20px 40px 20px 20px",
    alignItems: "center",
    justifyContent: "space-between",
    borderLeft: "16px solid rgb(42, 47, 153)",
    background: "lavender",
  },
  nameContainer: {
    display: "flex",
    gap: 16,
    alignItems: "center",
    color: "rgb(42, 47, 153)",
  },
  folderIcon: (pressed) => {
    return { cursor: "pointer", scale: pressed ? "0.9" : "1" };
  },
  actionIcons: { display: "flex", gap: 10 },
};

export default DocumentFolder;
