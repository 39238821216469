import React from "react";
import PropTypes from "prop-types";
import { Printer, Edit, Eye, Trash2 } from "lucide-react";

const DocumentCard = ({ document = {}, handlers = {}, ix, icon }) => {
  const deleteIconVisible =
    document.deleteIconVisible !== undefined
      ? document.deleteIconVisible
      : true;
  const { name, description } = document;
  const { handleDelete, handleDetail } = handlers;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "lavender",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "0.5rem",
      }}
    >
      <div
        style={{
          height: "4.5rem",
          width: "1rem",
          backgroundColor: "#2A2F99",
        }}
      />
      {icon && icon}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          padding: "0.6rem 0rem",
          width: "25rem",
        }}
      >
        <div style={{ color: "#2A2F99" }}>{name}</div>
        <div style={{ color: "grey", fontStyle: "italic" }}>{description}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0rem 1rem",
        }}
      >
        {/*<Printer/>
        <Edit 
          size="20" 
          cursor="pointer"
        />*/}
        &nbsp;
        {handleDetail ? (
          <Eye
            size="22"
            cursor="pointer"
            onClick={() => {
              handleDetail(document);
            }}
          />
        ) : null}
        &nbsp;
        {deleteIconVisible && handleDelete && (
          <Trash2
            size="20"
            cursor="pointer"
            onClick={() => {
              handleDelete(document, ix);
            }}
          />
        )}
      </div>
    </div>
  );
};

DocumentCard.propTypes = {
  document: PropTypes.object,
  handlers: PropTypes.objectOf(PropTypes.func),
};

export default DocumentCard;
