import React, { useState } from "react";
import DgModal from "../components/DgModal";
import Swal from "sweetalert2";
import { useFormWithValidations } from "./useFormWithValidations";
import DgForm from "../components/DgForm";
import DgInput from "../components/DgInput";

const useModify = (title, schema, postRequestFetch, handleLoader) => {
  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [
    handleInputChange,
    [register, handleFormSubmit, setValue, { errors }, watch, reset, getValues],
  ] = useFormWithValidations();

  const handleSubmit = async () => {
    Swal.fire({
      title: "Edición",
      text: `Confirma la edición ${
        requestData.name ? `de ${requestData.name}` : ""
      }?`,
      icon: "question",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        handleLoader(true);
        const res = await requestData.request(getValues());
        handleLoader(false);
        Swal.fire(
          res.ok ? "Éxito" : "Error",
          res.ok ? "Edición realizada con éxito." : "Error al editar.",
          res.ok ? "success" : "error"
        ).then(() => {
          postRequestFetch && postRequestFetch();
          reset();
          toggle({});
        });
      }
    });
  };

  const toggle = (requestData) => {
    setOpen((actualState) => !actualState);
    setRequestData(requestData);
  };

  const renderEditModal = () => {
    return (
      <DgModal
        withClose={true}
        withSend={{
          title: "Enviar",
          action: handleFormSubmit(({}) => handleSubmit()),
        }}
        toggle={() => setOpen((currentState) => !currentState)}
        title={title}
      >
        <div style={{ width: "100%", padding: "0px 20px" }}>
          {schema.map((schemaProps) => (
            <>
              <DgInput
                {...schemaProps}
                {...register(schemaProps.name, schemaProps.validations)}
                onChange={handleInputChange}
              />
              <span style={{ padding: 8, color: "red" }}>
                {errors[schemaProps.name] && errors[schemaProps.name].message}
              </span>
            </>
          ))}
        </div>
      </DgModal>
    );
  };

  return [renderEditModal, toggle, handleFormSubmit];
};

export default useModify;
