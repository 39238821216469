import React from "react";
import PropTypes from "prop-types";

const FormTitle = ({ title, style, className }) => {
  return (
    <h2
      style={style}
      className={`text-sesBlue font-semibold text-lg mt-8 ${className}`}
    >
      {title}
    </h2>
  );
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormTitle;
