import React, { useEffect, useState } from "react";
import { Request } from "../../helpers/api";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { parseCat, parseInvoiceErrors } from "../../helpers/utils";
import ClientCard from "../../components/ClientCard";
import Skinpage from "../../components/Skinpage";
import DgInput from "../../components/DgInput";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import ModalDetails from "./modals/ModalDetails";
import Loader from "../../components/Loader";
import { enumPaymentMethods } from "../../helpers/enums";

const InvoiceAnticipate = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);
  const [idFiscalPeriod, setIdFiscalPeriod] = useState("");
  const { state: services = [] } = useLocation();
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);

  const fetchData = useCallback(async (ix, ac) => {
    const newAc = [...ac];
    const s = services[ix];
    if (s) {
      let service = {};
      const res = await Request("/service/" + s.idService);
      if (res.ok) {

        const resST = await Request("/service/servicetemplate/" + res.data.idServiceTemplate);
        if (resST.ok) {
          const { data } = resST;
          const { serviceConcepts } = data;
          service = {
            ...data,
            idService: s.idService,
            serviceConcepts: serviceConcepts.map((sc) => ({
              ...sc,
              absenceDiscount: sc.absenceDiscount || 0,
            })),
          };
        }

        const resC = await Request("/client/" + s.idClient);
        if (resC.ok) {
          service.client = resC.data;
          service.paymentMethod = resC.data.paymentMethod;
          newAc.push(service);
          const newIx = ix + 1;
          if (newIx < services.length) {
            fetchData(newIx, newAc);
          } else {
            setData(newAc);
          }
        }
      }
    }
  }, []);
  const fetchFiscalPeriods = async (isMonthly) => {
    const { ok, data: fiscalData } = await Request(
      `/user/fiscalPeriods?limit=6&all=false&allPast=false&pastLimit=5`
    );
    if (ok) {
      isMonthly
        ? setFiscalPeriodList(
          fiscalData.filter((fiscalPeriod) => fiscalPeriod.montlhyName)
        )
        : setFiscalPeriodList(fiscalData);
    }
  };
  const onNestedChange = (sIx, ix) => (e) => {
    const { value, name } = e.target;
    const newData = [...data];
    const service = newData[sIx];
    const item = service.serviceConcepts[ix];
    item[name] = value;
    newData[sIx] = service;
    setData(newData);
  };
  const onChange = (ix) => (e) => {
    const newData = [...data];
    const service = newData[ix];
    service[e.target.name] = e.target.value;
    setData(newData);
  };
  const createSelector = (c, ix) => {
    return (
      <DgInput
        type="select"
        name="paymentMethod"
        value={c.paymentMethod}
        disabled={true}
        onChange={onChange(ix)}
        options={
          c.clientTypeService === "FISCAL"
            ? [
              { value: "", label: "Selecciona un elemento" },
              ...enumPaymentMethods,
            ]
            : [{ value: "PUE", label: "PUE" }]
        }
        iconName="FormInput"
        containerClassName={"invoicingDgInput"}
      />
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { ok, error } = await Request(
      "/invoice/advanceinvoice",
      {
        idFiscalPeriod,
        invoices: data.map(({ idService, serviceConcepts }) => ({
          idService,
          serviceConcepts,
        })),
      },
      "POST"
    );
    setIsLoading(false);

    Swal.fire({
      title: ok ? "Éxito" : "Error",
      html: ok ? "Servicios generados con éxito" : error,
      icon: ok ? "success" : "error",
    }).then(() => {
      ok && navigate("/facturacion");
    });
  };

  useEffect(() => {
    fetchData(0, []);
  }, [fetchData]);

  useEffect(() => {
    fetchFiscalPeriods(data[0]?.invoicePeriod === "MONTHLY");
  }, [data.length]);

  return (
    <Skinpage pageTitle={""}>
      <div className="relative top-8">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="grid">
            <div className="pl-8">
              <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
                PERIODO
              </h2>
              <DgInput
                {...{
                  type: "select",
                  options: [
                    { value: "", label: "Seleccione un periodo" },
                    ...parseCat(
                      fiscalPeriodList,
                      data[0]?.invoicePeriod === "MONTHLY"
                        ? "montlhyName"
                        : "name",
                      "idFiscalPeriod"
                    ),
                  ],
                  onChange: (e) => {
                    setIdFiscalPeriod(e.target.value);
                  },
                  name: "idFiscalPeriod",
                  placeholder: "Seleccionar periodo",
                  iconName: "Calendar",
                  containerClassName:
                    "w-2/5 mx-4 relative float-left pr-12 mb-8 mt-0",
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 pl-8">
            <h2 className="text-sesBlue font-bold text-xl mb-4">
              GENERAR NUEVO SERVICIO
            </h2>
          </div>

          <div className="grid grid-cols-2 gridInvoiceAnticipate">
            <div className="pl-8">
              <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
                INFORMACIÓN DE PLANTILLA
              </h2>
            </div>
            <div className="mx-4">
              <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
                CONCEPTOS
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-2 gridInvoiceAnticipate">
            {data.map((s, sIx) => (
              <>
                <ClientCard
                  md="12"
                  client={s.client}
                  service={s}
                  bottomComponent={createSelector(s, sIx)}
                />
                <div>
                  {s.serviceConcepts?.map((sc, ix) => (
                    <div className="grid grid-cols-4 gap-x-4 gap-y-px mx-4 relative -top-2">
                      <DgInput
                        {...{
                          label: "Concepto de factura",
                          placeholder: "Concepto de factura",
                          type: "select",
                          options: [
                            { value: "", label: "Elige un concepto" },
                            ...parseCat(
                              catConcepts,
                              "name",
                              "idCatServiceConcept"
                            ),
                          ],
                          name: "idCatServiceConcept",
                          required: true,
                          iconName: "Files",
                          onInputChange: onNestedChange(sIx, ix),
                          value: sc.idCatServiceConcept,
                          disabled: true,
                          errorMessage: "Éste concepto de factura no es válido",
                        }}
                      />
                      <DgInput
                        {...{
                          label: "Cantidad",
                          placeholder: "2",
                          minLength: "1",
                          maxLength: "2",
                          type: "number",
                          required: true,
                          iconName: "Hash",
                          name: "quantity",
                          value: sc.quantity,
                          onInputChange: onNestedChange(sIx, ix),
                          disabled: true,
                          errorMessage:
                            "Indique una cantidad en el rango 1 a 99",
                        }}
                      />
                      <DgInput
                        {...{
                          label: "Precio base",
                          type: "money",
                          required: true,
                          name: "price",
                          value: sc.price,
                          step: 0.01,
                          min: "0.01",
                          onInputChange: onNestedChange(sIx, ix),
                          iconName: "DollarSign",
                          disabled: true,
                          containerClassName: "relative",
                        }}
                      />
                      <DgInput
                        {...{
                          label: "Observaciones",
                          type: "text",
                          disabled: false,
                          name: "description",
                          required: false,
                          onInputChange: onNestedChange(sIx, ix),
                          value: sc.description,
                          disabled: true,
                          iconName: "FormInput",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>

          <div className="grid grid-cols-4 gap-4 mx-8 gridInvoiceAnticipateButton">
            <div />
            <div />
            <div />
            <Button
              disabled={isLoading || completed}
              type="submit"
              innerClassName="relative right-16"
            >
              Generar servicio
            </Button>
          </div>
        </form>
      </div>
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
          navigate("/facturacion");
        }}
        data={{ ...singleData, urlInvoice: singleData.urlPdf }}
      />
      <Loader isLoading={isLoading} />
    </Skinpage>
  );
};

InvoiceAnticipate.propTypes = {};

export default InvoiceAnticipate;
