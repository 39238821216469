import React, { useCallback, useEffect, useState } from "react";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import DgTable from "../../components/DgTable";
import { getColumnsDoubletView } from "../../helpers/datatableHelpers";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../helpers/useForm";
import ModalIncidenceLog from "./modals/ModalIncidenceLog";
import ModalPaysheetStatus from "./modals/ModalPaysheetStatus";
import { TODAY } from "../../helpers/consts";
import { Request } from "../../helpers/api";
import { getOperatingYearsCat, parseCat } from "../../helpers/utils";
import { monthsEs } from "../../helpers/enums";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const DoubletView = ({ doublesPermission }) => {
  const navigate = useNavigate();
  const { catRegions } = useSelector((s) => s.catalogues);
  const [filters, setFilters] = useForm({
    idCatRegion: "",
    month: (TODAY.getMonth() + 1 + "").padStart(2, "0"),
    year: TODAY.getFullYear(),
  });

  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const [modalIncidenceLog, setModalIncidenceLog] = useState(false);
  const [modalPaysheetStatus, setModalPaysheetStatus] = useState(false);
  const {
    catalogues: { CatZone },
  } = useSelector((state) => state);
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request("/payroll/doublework?" + filterStr);
    if (res.ok) {
      setData(res.data);
      setAllData(res.data);
    }
  }, []);
  const filterMonths = () => {
    const currYear = TODAY.getFullYear();
    const currMonth = TODAY.getMonth();
    return monthsEs.filter(({ value }) =>
      parseInt(filters.year) === currYear
        ? parseInt(value) - 1 <= currMonth
        : true
    );
  };
  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields =
      field === "all"
        ? ["employeeName", "employeeKey", "serviceName"]
        : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const funcs = {
    modalPaysheetStatus: (row) => {
      setSingleData(row);
      setModalPaysheetStatus(true);
    },
    openPersonDetail: (row) => {
      navigate("/detalle-empleado/" + row.idEmployee);
    },
    modalIncidenceLog: (row) => {
      setSingleData(row);
      setModalIncidenceLog(true);
    },
  };

  useEffect(() => {
    const { year, month } = filters;
    // if (year === "all" && (month !== "all")) {
    //   setAllFilters({ ...filters, month: "all", idFiscalPeriod: "all" });
    // }
    if (!doublesPermission) {
      Swal.fire({
        title: "Error",
        text: "Acceso denegado.",
        icon: "error",
      }).then(() => {
        navigate("/calculo-nomina");
      });
    } else {
      fetchData(filters);
    }
  }, []);
  useEffect(() => {
    const filteredData = allData.filter(
      (service) => !filters.zone || filters.zone === service.zone
    );
    setData(filteredData);
  }, [filters]);
  return (
    <Skinpage pageTitle={"VISTA DE DOBLETES"}>
      <div className="grid grid-cols-5 gap-3" style={{ alignItems: "end" }}>
        <DgInput
          type="select"
          label="Año de consulta"
          onChange={setFilters}
          options={[
            //{ value: "all", label: "Todos" },
            ...getOperatingYearsCat(),
          ]}
          name="year"
          value={filters.year}
          placeholder="Seleccionar año"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Mes de consulta"
          onChange={setFilters}
          options={[
            //{ value: "all", label: "Todos" },
            ...filterMonths(),
          ]}
          name="month"
          value={filters.month}
          placeholder="Seleccionar mes"
          iconName="Calendar"
        />
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar persona"
          iconName="Search"
        />
        <div />
        {/* <DgInput
          type="select"
          label="Filtrar por zona"
          options={[
            {
              label: "Todas",
              value: "",
            },
            ...parseCat(CatZone, "name", "idCatZone"),
          ]}
          onChange={setFilters}
          name="idCatRegion"
          value={filters.idCatRegion}
          iconName="CreditCard"
        /> */}
        <Button
          style={{ margin: "0.5rem" }}
          onClick={() => {
            navigate("/calculo-nomina");
          }}
        >
          Ver cálculo de nómina
        </Button>
      </div>
      <div className="mt-8">
        <DgTable
          data={data}
          columnsDef={getColumnsDoubletView(funcs)}
          className="tableHeaderPersonal"
        />
      </div>
      <ModalIncidenceLog
        isOpen={modalIncidenceLog}
        data={singleData}
        toggle={() => {
          setModalIncidenceLog(!modalIncidenceLog);
        }}
        fetchData={fetchData}
      />

      <ModalPaysheetStatus
        isOpen={modalPaysheetStatus}
        data={singleData}
        toggle={() => {
          setModalPaysheetStatus(!modalPaysheetStatus);
        }}
        fetchData={fetchData}
      />
    </Skinpage>
  );
};

export default DoubletView;
