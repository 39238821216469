import React from "react";
import PropTypes from "prop-types";
import DgInput from "./DgInput";
import FormTitle from "./FormTitle";

const DgForm = ({
  children = [],
  topComponent = null,
  data = {},
  onChange = () => {},
  groups = [],
  colsNumber = 2,
  className = "",
  //Variable para indicar el número de columnas en un formulario
  classNameGrid = colsNumber === 2
    ? "grid grid-cols-2 gap-x-5 gap-y-2"
    : "oneColForm",
  groupStyles = {},
  onSubmit = () => {},

}) => {
  return (
    <div className="mx-auto w-full">
      <form onSubmit={onSubmit} className={`${className}`}>
        {topComponent}
        <div>
          {groups.map((g, index) => {
            return (
              <>
                {g.withAdd && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <FormTitle title={g.title} />
                        {g.titleRightElement && g.titleRightElement}
                      </div>
                      <div
                        style={{
                          color: "#2A2F99",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {g.addElement}
                      </div>
                    </div>
                  </>
                )}
                {!g.withAdd && g.title && (
                  <div style={{ display: "flex", gap: 10 }}>
                    <FormTitle title={g.title} />
                    {g.titleRightElement && g.titleRightElement}
                  </div>
                )}
                <div
                  className={`${classNameGrid} ${g.groupClassNameGrid || ""}`}
                  style={groupStyles}
                >
                  {g.fields.map((fx) => {
                    return (
                      <>
                        {fx.element ? (
                          fx.element
                        ) : (
                          <DgInput
                            {...fx}
                            value={fx.value || data[fx.name]}
                            onChange={onChange}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>

        {children}
      </form>
    </div>
  );
};

DgForm.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  data: PropTypes.object,
  onChange: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.objectOf({
      title: PropTypes.string,
      classNameGrid: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.objectOf(DgInput.propTypes)),
      groupStyles: PropTypes.object,
      groupClassNameGrid: PropTypes.string,
    })
  ),
};

export default DgForm;
