import { GENERAL_PERMISSIONS } from "../actions";

const INITIAL_STATE = [];

export const permissionsReducer = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case GENERAL_PERMISSIONS:
      return payload
    default:
      return state;
  }
};
