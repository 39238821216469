import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Skinpage from "../../components/Skinpage";
import classnames from "classnames";
import AccountState from "./client-reports-tabs/AccountState";
import "./CasingReports.css";
import Collection from "./client-reports-tabs/Collection";
import ClientsPortfolioModal from "./modals/RelatedPaymentsModal";

const CashingReports = () => {
  const [portfolioModal, setPortfolioModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { name: "Estado de cuenta", component: <AccountState /> },
    { name: "Cartera", component: <Collection /> },
  ];
  return (
    <Skinpage
      pageTitle={
        !activeTab ? "Reporte de estado de cuenta" : "Reporte de Cobranza"
      }
    >
      <Nav tabs>
        {tabs.map((t, ix) => (
          <NavItem>
            <NavLink
              className={classnames(
                {
                  active: activeTab === ix,
                },
                `text-md font-semibold text-sesBlue sesTab ${
                  activeTab === ix ? "activeSesTab" : ""
                }`
              )}
              onClick={() => {
                setActiveTab(ix);
              }}
            >
              {t.name}
            </NavLink>
          </NavItem>
        ))}
        <div
          onClick={() => {
            setPortfolioModal((prevState) => !prevState);
          }}
          className="flex items-center bg-sesBlue cursor-pointer rounded-t-lg active:scale-95 transition-all px-3 ml-1"
        >
          <div className={`text-md font-semibold text-white`}>
            Relación de pagos
          </div>
        </div>
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabs.map((t, ix) => (
          <TabPane tabId={ix}>{t.component}</TabPane>
        ))}
      </TabContent>
      <ClientsPortfolioModal
        toggle={() => {
          setPortfolioModal((prevState) => !prevState);
        }}
        open={portfolioModal}
      />
    </Skinpage>
  );
};

CashingReports.propTypes = {};

export default CashingReports;
