export const editFolderSchema = () => [
  {
    label: "Nuevo nombre de carpeta",
    name: "folderName",
    placeholder: "Nombre de carpeta..",
    type: "text",
    style: { width: "100%" },
    required: true,
    validations: {
      required: `El nombre es requerido.`,
      minLength: { value: 3, message: "La longitud mínima es de 3" },
      maxLength: { value: 40, message: "La longitud máxima es de 40" },
    },
    iconName: "Folder",
  },
];
