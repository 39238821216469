// data provides access to your row data

import { getColumnsNewUserSubmodule } from "../helpers/datatableHelpers"
import DgTable from "./DgTable"

const DgTableExpandable = ({ data }) => {

    return ( <DgTable
        style={{marginLeft: "100px"}}
        columnsDef={getColumnsNewUserSubmodule()}
        data={data.subModules}
        pagination={false}
        expandableRows={false}
        noTableHead={true}
    ></DgTable>
    )
}

export default DgTableExpandable