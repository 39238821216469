import React, { useEffect, useState } from "react";
import DgForm from "../../../components/DgForm";
import Button from "../../../components/Button";
import Swal from "sweetalert2";
import { useForm } from "../../../helpers/useForm";
import { Request } from "../../../helpers/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientInputs } from "../../../helpers/formUtils";
import { lowerCaseExceptFirst, parseCat } from "../../../helpers/utils";
import { accountSchema, clientSchema } from "../../../helpers/schemas";
import FormTitle from "../../../components/FormTitle";
import { Minus, Plus } from "lucide-react";
import DgInput from "../../../components/DgInput";
import useGetZipCode from "../../../helpers/useGetZipCode";
import { handlePhoneChange } from "../../../helpers/utils";
import DgLoader from "../../../components/DgLoader";
import Title from "../../../components/Title";

const DetailClientInfo = ({
  clientData,
  setClientAllData,
  loading,
  setLoading,
}) => {
  const { id: idClient } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { CatCfdi, CatRegime, CatPaymentWay, catBank } = useSelector(
    (s) => s.catalogues
  );
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientData,
  });

  const [handleZipChange, suburbs, inputType, setInputType] = useGetZipCode(
    data.postalCode,
    data,
    setAllData
  );
  const editClient = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(`/client/${idClient}`, data, "PUT");
    setIsLoading(false);
    if (res.ok) {
      Swal.fire({
        title: "Éxito",
        text: "Cliente actualizado con éxito.",
        icon: "success",
      }).then(() => {
        setLoading(!loading);
        setClientAllData({ ...clientData, ...res.data });
      });
    }
  };

  const handleNestedChange = (ix, key) => (e) => {
    const { value, name } = e.target;
    const arr = data[key];
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: key, value: arr } });
  };

  const manageAccounts = (willDelete, ix, key, schema) => () => {
    const newAccounts = [...data[key]];
    if (willDelete) {
      newAccounts.splice(ix, 1);
    } else {
      newAccounts.push({ ...schema });
    }
    setAllData({ ...data, [key]: newAccounts });
  };

  useEffect(() => {
    handleZipChange();
  }, [data?.postalCode]);

  return (
    <>
      <Title className={"pt-3"}> Editar Datos de Cliente</Title>
      <DgForm
        data={data}
        zipError={data.error}
        classNameGrid="grid grid-cols-2 gap-4"
        onChange={(e) => {
          onDataChange(e);
          setInputType(e.target.name);
        }}
        onSubmit={editClient}
        groups={clientInputs(
          data,
          { handlePhoneChange, onDataChange },
          { CatRegime, CatCfdi, CatPaymentWay, suburbs: suburbs[0] }
        )}
      >
        <div className="grid grid-cols-2">
          <div>
            <FormTitle title="Cuentas bancarias" />
            <table className="ses-client-accounts-table">
              <thead>
                <tr>
                  <th width="40%">Banco</th>
                  <th width="50%">Número de cuenta</th>
                  <th width="10%">
                    <Plus
                      onClick={manageAccounts(false,null,"clientAccounts",clientSchema)}
                      color="black"
                      size={28}
                      className="m-auto"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.clientAccounts?.map((a, ix) => (
                  <tr>
                    <td>
                      <DgInput
                        {...{
                          type: "select",
                          iconName: "Landmark",
                          required: true,
                          options: [
                            { label: "Elige un banco", value: "" },
                            ...parseCat(catBank, "name", "idCatBank"),
                          ],
                          name: "idCatBank",
                          value: a.idCatBank,
                          onChange: handleNestedChange(ix,"clientAccounts"),
                        }}
                      />
                    </td>
                    <td>
                      <DgInput
                        {...{
                          type: "text",
                          minLength: 10,
                          maxLength: 25,
                          iconName: "DollarSign",
                          required: true,
                          name: "account",
                          value: a.account,
                          onChange: handleNestedChange(ix, "clientAccounts"),
                        }}
                      />
                    </td>
                    <td>
                      <Minus
                        onClick={manageAccounts(true, ix,"clientAccounts")}
                        color="black"
                        size={28}
                        className="m-auto"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="flex items-end gap-2.5">
              <FormTitle title="Correos electrónicos" />
              <Plus
                onClick={manageAccounts(false, null, "clientContacts", {
                  email: "",
                })}
                className="cursor-pointer"
                color="black"
                size={28}
              />
            </div>
            {data.clientContacts?.map((contactData, ix) => (
              <div className="flex items-end gap-4">
                <DgInput
                  containerClassName="mt-3"
                  label="E-Mail"
                  iconName={"MailPlus"}
                  name={"email"}
                  onChange={handleNestedChange(ix, "clientContacts")}
                  value={contactData.email}
                />
                <Minus
                  onClick={manageAccounts(true, ix, "clientContacts")}
                  color="black"
                  className="mb-[30px] cursor-pointer"
                  size={28}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            Actualizar
          </Button>
        </div>
      </DgForm>
      <DgLoader open={isLoading} />
    </>
  );
};

export default DetailClientInfo;
