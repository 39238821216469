import React from "react";
import FormTitle from "./FormTitle";

function PostGeneralInvoiceTitle({ data }) {
  const name = data.baseGeneralInvoice || "";
  const type = data.invoice.PaymentMethod.slice(0, 3) || "";
  const folio = `${data.prefix}${data.internalFolio}`;
  return (
    <FormTitle
      className={"text-center p-0 m-0"}
      title={`${name} - ${type} - ${folio}`}
    />
  );
}

export default PostGeneralInvoiceTitle;
