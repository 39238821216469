import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "../../helpers/useForm";
import { userSchema } from "../../helpers/schemas";
import { userInputs } from "../../helpers/formUtils";
import { getColumnsNewUser } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import DgForm from "../../components/DgForm";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import DgTable from "../../components/DgTable";
import Swal from "sweetalert2";
import { uploadImage } from "../../helpers/commonFetchs";

const NewUser = () => {
  const [disabledSwitchs, setDisabledSwitchs] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [permData, setPermData] = useState([]);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...userSchema,
  });
  const {
    catalogues,
    auth: {
      data: { idCatRole },
    },
  } = useSelector((s) => s);
  const { CatAction, RoleActionsPermissions } = catalogues;
  const navigate = useNavigate();
  const onPasswordConfirmChange = (e) => {
    const { value } = e.target;
    if (value !== data.password) {
      e.target.setCustomValidity("Las contraseñas no coinciden");
      e.target.reportValidity();
    } else {
      e.target.setCustomValidity("");
      e.target.reportValidity();
    }
    onDataChange(e);
  };
  const onSelectFile = (e) => {
    const { files } = e.target;
    setSelectedFile(!files || files.length === 0 ? null : files[0]);
  };
  const onRoleChange = (e) => {
    const { value } = e.target;
    const rolePermissions = RoleActionsPermissions.filter(
      (rap) => rap.idCatRole == value && rap.visibility === 1
    ).map((rap) => rap.idCatAction);
    const newPermData = [
      ...permData.map((pd) => ({
        ...pd,
        visibility: rolePermissions.includes(pd.idCatAction),
      })),
    ];
    setPermData(newPermData);
    setDisabledSwitchs(true);
    onDataChange(e);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request(
      `/user`,
      {
        ...data,
        permissions: permData.map(({ idCatAction, visibility }) => ({
          idCatAction,
          visibility,
        })),
      },
      "POST",
      true,
      false
    );
    if (res.ok) {
      const resImg = await uploadImage(res.idUser, selectedFile);
      if (resImg.ok) {
        Swal.fire({
          title: "Éxito",
          text: "Registro exitoso",
          icon: "success",
        }).then(() => {
          setAllData({ ...userSchema });
          navigate("/lista-usuarios");
        });
      } else {
        Swal.fire({
          itle: "Éxito",
          text: "Registro parcial, no se ha cargado imagen.",
          icon: "success",
        }).then(() => {
          setAllData({ ...userSchema });
          navigate("/lista-usuarios");
        });
      }
    }
  };
  const handleActiveChange = (row) => (e) => {
    const newPermData = [...permData];
    const permIx = newPermData.findIndex(
      (p) => p.idCatAction == row.idCatAction
    );
    const perm = newPermData[permIx];
    perm.visibility = e.target.checked;
    newPermData[permIx] = perm;
    setPermData(newPermData);
  };
  useEffect(() => {
    if (CatAction) {
      setPermData([...CatAction].map((a) => ({ ...a, visibility: false })));
    }
  }, [CatAction]);
  return (
    <Skinpage pageTitle={"NUEVO USUARIO"}>
      <DgForm
        data={data}
        onChange={onDataChange}
        groups={userInputs(
          { ...data, selectedFile },
          { onPasswordConfirmChange, onSelectFile, onRoleChange },
          catalogues
        )}
        colsNumber={1}
        className="w-full grid grid-cols-2 gap-4"
        onSubmit={handleSubmit}
      >
        <div className="w-full">
          <h2 className="text-sesBlue font-semibold text-lg mt-4">PERMISOS</h2>
          <p>
            Seleccione los permisos y funcionalidades a los que el usuario
            tendrá acceso.
          </p>
          <br />
          <DgTable
            columnsDef={getColumnsNewUser({
              handleActiveChange,
              disabledSwitchs,
            })}
            data={permData}
            className="containerTableMid mt-16"
            pagination={false}
          />
        </div>
        <div className="col-span-2 grid grid-cols-3">
          <div />
          <div />
          <Button type="submit" className="mt-6">
            Crear usuario
          </Button>
        </div>
      </DgForm>
    </Skinpage>
  );
};

export default NewUser;
