import React, { useState } from "react";
import DgModal from "../../../components/DgModal";
import DgForm from "../../../components/DgForm";
import {
  conceptsSchema,
  subclientBillingSchema,
} from "../../../helpers/formUtils";
import { useForm } from "../../../helpers/useForm";
import { useSelector } from "react-redux";
import { conceptSchema } from "../../../helpers/schemas";
import { handleNestedChange, handleNestedItems } from "../../../helpers/utils";
import useGetZipCode from "../../../helpers/useGetZipCode";
import { PlusSquare } from "lucide-react";

function ModalInvoiceExternalData({ open, toggle }) {
  const [formData, onDataChange, reset, setAllData] = useForm({
    serviceConcepts: [{ ...conceptSchema }],
  });
  const { CatRegime, CatCfdi, CatPaymentWay, serviceConcepts } = useSelector(
    (s) => s.catalogues
  );
  const [handleZipChange, suburbs] = useGetZipCode(
    formData.postalCode,
    formData,
    setAllData
  );
  const getConceptFields = () => {
    let fields = [];
    formData.serviceConcepts.forEach((nestedData, nestedIndex) => {
      const onNestedChange = (event) => {
        handleNestedChange(event, nestedIndex, "serviceConcepts", formData, {
          onDataChange,
          handleZipChange,
        });
      };

      fields = fields.concat(
        conceptsSchema(
          formData,
          nestedData,
          nestedIndex,
          { onNestedChange, onDataChange },
          { catConcepts: serviceConcepts }
        )
      );
    });
    return fields;
  };
  return (
    <DgModal
      className={"max-w-[90vw] max-h-[90vh] overflow-scroll"}
      open={open}
      toggle={toggle}
      withSend={{ title: "Facturar", action: () => {} }}
      withClose={{
        title: "Cerrar",
        action: () => {
          toggle();
        },
      }}
    >
      <DgForm
        className="px-4"
        data={formData}
        groups={[
          {
            title: "Conceptos",
            fields: getConceptFields(),
            groupClassNameGrid: "grid grid-cols-[auto_auto_auto_auto_auto] ",
            withAdd: true,
            addElement: (
              <div
                onClick={() => {
                  handleNestedItems(
                    formData,
                    conceptSchema,
                    { onDataChange },
                    "serviceConcepts"
                  );
                }}
              >
                <PlusSquare
                  fill="#2A2F99"
                  color="white"
                  size={35}
                  style={{ display: "inline" }}
                />
                Agregar otro concepto
              </div>
            ),
          },
          {
            title: "Datos de facturación",

            fields: subclientBillingSchema(
              formData,
              { onDataChange },
              { CatRegime, CatCfdi, CatPaymentWay, suburbs: [] }
            ),
          },
        ]}
      />
    </DgModal>
  );
}

export default ModalInvoiceExternalData;
