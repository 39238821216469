import React, { useEffect } from "react";
import DgTable from "../../../components/DgTable";
import { getColumnsSubclients } from "../../../helpers/datatableHelpers";
import { useState } from "react";
import DgModal from "../../../components/DgModal";
import { Request } from "../../../helpers/api";
import { useForm } from "../../../helpers/useForm";
import DgForm from "../../../components/DgForm";
import { subclientBillingSchema } from "../../../helpers/formUtils";
import { useSelector } from "react-redux";
import useGetZipCode from "../../../helpers/useGetZipCode";
import Swal from "sweetalert2";
import { handleDownloadClick } from "../../../helpers/utils";
import ModalDetails from "./ModalDetails";
import { useNavigate } from "react-router-dom";
import DgLoader from "../../../components/DgLoader";
import ModalServicePayments from "./ModalServicePayments";
import ModalCancel from "./ModalCancel";
import { handleCancelCfdi, handleInvoice } from "../handlers";
import ModalCreditNote from "./ModalCreditNote";
import ModalServiceCreditNotes from "./ModalServiceCreditNotes";
import ModalReSubclients from "./ModalReSubclients";
import ModalSendEmail from "./ModalSendEmail";

function ModalSubclients({ open, toggle, idService, fetchInvoicingTableData }) {
  const [singleData, setSingleData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalSendEmailOpen, setModalSendEmailOpen] = useState(false);
  const [modalOpenRebillSubclient, setModalOpenRebillSubclient] =
    useState(false);
  const [cancelRemissionModal, setCancelRemissionModal] = useState(false);
  const [modalOpenNewCreditNote, setModalOpenNewCreditNote] = useState(false);
  const [modalOpenCreditNotesLists, setModalOpenCreditNotesLists] =
    useState(false);
  const [modalOpenServicePayments, setModalOpenServicePayments] =
    useState(false);
  const [modalOpenCancel, setModalOpenCancel] = useState(false);
  const [billingModal, setBillingModalOpen] = useState(false);
  const [formData, handleChange, reset, setAllValues] = useForm({});
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { CatRegime, CatCfdi, CatPaymentWay } = useSelector(
    (s) => s.catalogues
  );
  const [handleZipChange, suburbs] = useGetZipCode(
    formData.postalCode,
    formData,
    setAllValues
  );
  const navigate = useNavigate();
  const fetchData = async () => {
    if (idService && open) {
      const res = await Request(
        `/service/serviceDetail?idService=${idService}`
      );
      if (res.ok) {
        setData(res.data);
      }
    }
  };

  const billSubclient = async (subclientData) => {
    setLoading(true);
    const { objupdateafacturar, ok, error } = await Request(
      `/invoice/detailService`,
      subclientData,
      "POST"
    );
    if (ok) {
      handleInvoice(
        objupdateafacturar,
        () => {
          setCompleted(true);
          setAllValues({});
          setBillingModalOpen(false);
          fetchData();
        },
        formData.nameDetailService
      );
    } else Swal.fire("Facturar parcialidad", error, "error");
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [open]);

  useEffect(() => {
    handleZipChange();
  }, [formData.postalCode]);

  const funcs = {
    onSelectCheck: (row) => () => {
      const { idService, idClient } = row;
      let ix;
      data.find(({ idService: idS, idClient: idC }, i) => {
        ix = i;
        return idS === idService && idC === idClient;
      });
      const newData = [...data];
      const client = newData[ix];
      client.selected = !client.selected;
      newData[ix] = client;
      setData([...newData]);
    },
    bill: (row) => () => {
      setAllValues(row);
      setBillingModalOpen(true);
    },
    invoiceDetails: (row) => () => {
      setAllValues(row);
      setModalOpenInvoice(true);
    },
    details:
      ({ idClient }) =>
      () => {
        navigate("/detalle-cliente/" + idClient);
      },
    registerCreditNote: (row) => {
      setSingleData(row);
      setModalOpenNewCreditNote((prevData) => !prevData);
    },
    getCreditNotes: (row) => {
      setSingleData(row);
      setModalOpenCreditNotesLists((prevData) => !prevData);
    },
    listPayments: (row) => {
      setSingleData(row);
      setModalOpenServicePayments((prevData) => !prevData);
    },
    cancelInvoice: (row) => {
      setSingleData(row);
      setModalOpenCancel(true);
    },
    cancelRemission: (row) => {
      setSingleData(row);
      setCancelRemissionModal((closed) => !closed);
    },
    rebill: (row) => {
      setModalOpenRebillSubclient(true);
      setSingleData(row);
    },
    sendEmail: (row) => {
      setModalSendEmailOpen(true);
      setSingleData(row);
    },
    downloadXML: (row) => {
      handleDownloadClick(row.urlXml, setLoading);
    },
    downloadPDF: (row) => {
      const url = row.urlInvoice || row.urlRemission;
      handleDownloadClick(url, setLoading);
    },
  };
  return (
    <DgModal
      style={{
        flex: 1,
        margin: "0vh 2vw",
        maxHeight: "95%",
        overflow: "auto",
      }}
      withClose={true}
      toggle={toggle}
      open={open}
      title="Detalle de parcialidades"
    >
      <DgTable
        data={data}
        columnsDef={getColumnsSubclients(funcs, { allSelected: false })}
        className="invoicing"
      />
      <DgModal
        open={billingModal}
        toggle={() => setBillingModalOpen((currentState) => !currentState)}
        withClose={true}
        withSend={{
          action: () => {
            billSubclient(formData);
          },
          title: "Facturar",
        }}
        style={{ height: "90vh", overflow: "auto" }}
        title={"Facturar parcialidad"}
      >
        <div style={{ width: "90%", marginTop: 20 }}>
          <DgForm
            data={formData}
            groups={[
              {
                
                fields: subclientBillingSchema(
                  formData,
                  { onDataChange: handleChange },
                  { CatRegime, CatCfdi, CatPaymentWay, suburbs: [] }
                ),
              },
            ]}
          />
        </div>
        <DgLoader open={loading} />
      </DgModal>
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={formData}
      />
      <ModalServicePayments
        isOpen={modalOpenServicePayments}
        toggle={() => {
          setModalOpenServicePayments(!modalOpenServicePayments);
        }}
        data={singleData}
        fetchData={() => {
          fetchData();
          fetchInvoicingTableData();
        }}
      />
      <ModalCancel
        isOpen={cancelRemissionModal}
        toggle={() => {
          setCancelRemissionModal((closed) => !closed);
        }}
        onConfirm={(epBody) => {
          handleCancelCfdi(
            singleData,
            {
              epBody,
              endPoint: "/invoice/remission/detailService/",
              cfdiId: "idDetailService",
              setLoading: setLoading,
            },
            () => {
              fetchData();
              fetchInvoicingTableData();
              setCancelRemissionModal((closed) => !closed);
            },
            {
              text: "Cancelar remisión",
              onSuccessMessage: "Remisión cancelada con éxito!",
            }
          );
        }}
        data={singleData}
        showForm={false}
        showReasons={false}
        loading={loading}
      />
      <ModalCancel
        showForm={false}
        isOpen={modalOpenCancel}
        toggle={() => {
          setModalOpenCancel((prevState) => !prevState);
        }}
        data={singleData}
        onConfirm={(epBody) => {
          handleCancelCfdi(
            singleData,
            {
              epBody,
              endPoint: "/invoice/detailService/",
              cfdiId: "idDetailService",
              setLoading: setLoading,
            },
            () => {
              fetchData();
              fetchInvoicingTableData();
            },
            {
              text: "Cancelación de parcialidad",
              onSuccessMessage: "Parcialidad cancelada con éxito.",
            }
          );
        }}
        filterCancelMethodBy={"applyInvoice"}
        loading={loading}
      />
      <ModalCreditNote
        zIndex={9999}
        isOpen={modalOpenNewCreditNote}
        toggle={() => {
          setModalOpenNewCreditNote(!modalOpenNewCreditNote);
        }}
        data={singleData}
        fetchData={() => {
          fetchData();
        }}
      />
      <ModalServiceCreditNotes
        zIndex={9999}
        isOpen={modalOpenCreditNotesLists}
        toggle={() => {
          setModalOpenCreditNotesLists((prevData) => !prevData);
        }}
        data={singleData}
        fetchData={() => {
          fetchData();
        }}
      />
      <ModalReSubclients
        zIndex={9999}
        isOpen={modalOpenRebillSubclient}
        toggle={() => {
          setModalOpenRebillSubclient((prevData) => !prevData);
        }}
        data={singleData}
        fetchData={() => {
          fetchData();
        }}
      />
      <ModalSendEmail
        isService={false}
        isSubclient={true}
        isOpen={modalSendEmailOpen}
        endPoint={`/client/${singleData.idDetailService}/mailBill`}
        toggle={() => setModalSendEmailOpen((prevState) => !prevState)}
        singleData={singleData}
      />
      <DgLoader open={loading} />
    </DgModal>
  );
}

export default ModalSubclients;
